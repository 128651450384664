import React from 'react'

import _ from 'lodash'
import api from 'services/api'
import qs from 'query-string'

import moment from 'services/moment'
import { Importer, Exporter } from 'services/transform'

import Pager from 'components/utils/Pager'
import DataSet from 'components/utils/DataSet'

import { Container, Search } from './utils.js'

class VoterListContainer extends React.Component {
  constructor(props) {
    super(props)

    this.abortController = new AbortController()

    this.state = {
      loading: true, error: false, message: '',
      selecteds: [],
      count: 0, items: [], keywords: '', page: 1, limit: 30
    }
    this.initialize = this.initialize.bind(this)
    this.getItems = this.getItems.bind(this)
  }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  async getItems(props = {}) {
    try {
      const { page, limit, keywords } = this.state
      const surveyId = this.props.surveyId
      const query = (props.query && _.isObject(props.query))
        ? { page, limit, keywords, ...props.query }
        : { page, limit, keywords }
      query.usedCount = true
      const { error, message, count, rows } = await api.get(
        `/surveys/admin2/${surveyId}/logs?${qs.stringify(query)}`,
        { signal: this.abortController.signal })
          .catch((e) => {
            console.log(e.message, e.stack)
            return { error: true, message: 'API 서버에서 데이터를 불러올 수 없었습니다.', count: 0, items: [] }
          })
      return { error, message, count, items: rows }
    } catch(e) {
      console.log(e.message, e.stack)
      return { error: true, message: '데이터 로드 후 문제가 발생했습니다.', count: 0, items: [] }
    }
  }

  async initialize() {
    const { error, message = '', count = 0, items = [] } = await this.getItems()
    this.setState({ loading: false, error, message, count, items })
  }
  
  render() {
    const { initialize, getItems } = this
    const {
      loading, error, message,
      selecteds,
      keywords, page, limit =30, count, items
    } = this.state
    if (loading) { return null }
    if (error) { return <div>{message}</div> }

    const { surveyId, survey = {}, form, parent } = this.props

    // @ 선택기능 제어장치
    const sKey = '_id'
    const isSelectedAll = () => items.length === selecteds.length ? true : false
    const isExistsSelected = (item) => selecteds.find(_item => _item[sKey] === item[sKey]) ? true : false

    // @ 전체 선택
    const checkAll = () => {
      if (!items.length) { return null }
      const prev = selecteds || []
      const pIdxs = prev.map(item => item[sKey]), cIdxs = items.map(item => item[sKey])
      const next = [...prev.filter(item => !cIdxs.includes(item[sKey])), ...items.filter(item => !pIdxs.includes(item[sKey]))]
      return this.setState({ selecteds: next })
    }

    // @ 선택한 한가지 데이터
    const doCheck = (item) => {
      const prev = selecteds, next = []
      if (prev.find(_item => _item[sKey] === item[sKey])) {
        next.push(...prev.filter(_item => _item[sKey] !== item[sKey]))
      } else {
        next.push(...prev, item)
      }
      return this.setState({ selecteds: next })
    }

    // 컬럼 구성
    const columns = []
    columns.push({
      key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
      style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
    })
    columns.push({ key: 'DateColumn', header: '참여일', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
    columns.push({ key: 'UserColumn', header: '계정명', style: { minWidth: '130px', maxWidth: '130px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
    columns.push({ key: 'SelectedsColumn', header: '선택항목', style: { minWidth: '260px', width: '260px', maxWidth: '360px', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
    columns.push({ key: 'WinColumn', header: '선정', style: { minWidth: '100px', maxWidth: '100px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
    columns.push({ key: 'ControlColumn', header: '관리', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })

    const actions = {}

    // @ 전체 데이터 불러오기
    actions.getItemsAll = async (args = {}) => {
      try {
        let total = 0
        const pageCount = (count && limit) ? Math.ceil(count/limit) : 1
        return await Array.from({ length: pageCount })
          .reduce((prev, b, idx) => 
            prev.then(async (s) => {
              const pageNo = idx + 1
              const { count = 0, items = [] } = await getItems({ page: pageNo, ...args })
              if (count) { total = total + count }
              s.push(...items)
              return s
            }),
            Promise.resolve([]))
          .catch((e) => {
            console.log(e.message, e.stack)
            return []
          })
      } catch(e) {
        console.log(e.message, e.stack)
        return []
      }
    }

    // @ 참여자 데이터 엑셀 다운로드 기능
    actions.doDownloadExcel = async (targets = []) => {
      const curAt = new Date(), curMt = moment(curAt)
      if (!window.confirm(`현재 참여자수, 최종확정된 항목 기준으로 정렬하여 다운로드 받습니다. (항목 사용안함 데이터는 받지 않습니다.)`)) { return }
      
      // @ 파서 셋팅
      const parser = { name: '참여자 데이터 다운로드' }

      // @ 파서의 컬럼 셋팅
      const columns = []

      columns.push({
        header: '서베이ID', key: 'surveyId',
        view: (item) => { return survey._id }
      })

      columns.push({
        header: '서베이명칭', key: 'surveyTitle',
        view: (item) => { return survey.title }
      })

      columns.push({
        header: '참여ID', key: 'logId',
        view: (item) => { return item._id }
      })

      columns.push({
        header: '참여일자', key: 'createdAt',
        view: (item) => { return moment(_.get(item, 'createdAt')).format('YYYY-MM-DD') }
      })

      columns.push({
        header: '참여시간', key: 'createdAt',
        view: (item) => { return moment(_.get(item, 'createdAt')).format('HH:mm') }
      })

      columns.push({
        header: '계정명', key: '',
        view: (item) => { _.get(item, 'extras.user.accountId') }
      })

      survey.elements.forEach((element) => {
        columns.push({
          header: `${element.name}`, key: element.identity,
          view: (item) => {
            const identities = _.get(item, `identities`) || []
            return identities.includes(element.identity) ? 'O' : ''
          }
        })
      })
      
      columns.push({
        header: '남긴의견', key: 'message',
        view: (item) => { return _.get(item, 'message') || '' }
      })
      
      columns.push({
        header: '선정여부', key: 'win',
        view: (item) => { return _.get(item, 'win') ? 'O' : 'X' }
      })
      
      columns.push({
        header: '선정날짜', key: 'win',
        view: (item) => {
          return _.get(item, 'winAt') ? moment(_.get(item, 'winAt')).format('YYYY-MM-DD') : ''
        }
      })

      parser.columns = columns

      // @ 순열정렬
      const items = _.sortBy((targets || []), 'createdAt')

      // @ 엑셀 다운로드 진행
      return await new Exporter({ parser, items })
        .download(`${survey.title ? survey.title : '서베이 명칭 없음'} ${parser.name} - ${curMt.format('YYYYMMDDHHmmss')}`)
        .catch(e => { alert(e.message) })
    }

    // @ 참여자 삭제처리
    actions.deleteSurveyLog = async (logId) => {
      if (!window.confirm(`참여내역을 삭제하시겠습니까? 참여기록을 삭제하면, 투표항목에 진행상황/결과기록이 변화 됩니다. (기록번호 : ${logId})`)) { return }
      const result = await api.delete(`/surveys/admin2/${survey._id}/logs/${logId}`)
        .catch((e) => {
          console.log(e.message, e.stack)
          return { error: true, message: '참여내역을 삭제하던 도중 문제가 발생하였습니다.' }
        })
      alert(result.message)
      return await initialize()
    }

    // @ 참여자 우승자 선정기능
    actions.doSetWinner = async (targets = [], status = 'grant') => {
      if (!targets.length) { return alert('대상이 없습니다. 체크 박스를 선택해주세요.') }
      if (!window.confirm('참여자 우승자 선정시 알림 해당 유저 연동이 되어 있다면  알림 메시지가 통보됩니다. 실수 통보시 활동 알림에서 메시지를 삭제해주세요.')) { return }
      const payload = { form: {} }
      payload.form.targets = targets
      payload.form.status = status
      const result = await api.put(`/surveys/admin2/${survey._id}/set-log-winners`, payload)
        .catch((e) => {
          console.log(e.message, e.stack)
          return { error: true, message: '우승자 플래그를 업데이트하던 도중 문제가 발생하였습니다.' }
        })
      alert(result.message)
      return await initialize()
    }

    return (
      <Container>
        <header>
          <div className="lead">
            참여자수 <strong>총 {count}명</strong>
          </div>

          <div className="btns">
            <a
              href="#참여자엑셀다운로드"
              style={{ marginRight: '1rem' }}
              onClick={async (e) => {
                e.stopPropagation()
                e.preventDefault()
                const targets = (selecteds && selecteds.length)
                  ? selecteds
                  : await actions.getItemsAll()
                return await actions.doDownloadExcel(targets)
              }}
            >
              📥 참여자 XLSX
            </a>
            <a
              href="#추첨선정"
              style={{ marginRight: '1rem' }}
              onClick={async (e) => {
                e.stopPropagation()
                e.preventDefault()
                return await actions.doSetWinner(selecteds, 'grant')
              }}
            >
              🏅 추첨선정
            </a>
            <a
              href="#추첨선정"
              style={{ marginRight: '1rem' }}
              onClick={async (e) => {
                e.stopPropagation()
                e.preventDefault()
                return await actions.doSetWinner(selecteds, 'refuse')
              }}
            >
              🪃 추첨취소
            </a>
          </div>
        </header>

        <Search>
          <input
            type="text"
            placeholder="계정명으로 검색"
            defaultValue={keywords}
            onKeyUp={async (e) => {
              if (e.key === 'Enter') {
                await new Promise(r => this.setState({ keywords: e.target.value, page: 1 }, r))
                await this.initialize()
              }
            }}
          />
        </Search>

        <DataSet>
          <DataSet.Header>
            <DataSet.Item>
              {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
            </DataSet.Item>
          </DataSet.Header>
          <DataSet.Body>
            {!items.length
              ? (
                <DataSet.NotFound>
                  <header>😭 아직 참여자가 없습니다.</header>
                  <section>서베이에 참여한 유저가 현재 없습니다.</section>
                </DataSet.NotFound>
              )
              : null}
            {items.map((item, itemIdx) => (
              <DataSet.Item key={item._id}>
                {columns.map((column, columIdx) => {
                  // const columnProps = { column, columIdx, item, getItems }

                  const extras = item.extras || {}

                  const meta = {}
                  meta.userId = item.user || '-'
                  meta.userAccountId = _.get(item, 'extras.user.accountId') || '-'
                  meta.identityCount = _.get(item, 'identities.length') || 0
                  meta.elements = _.get(item, 'extras.elements') || []
                  meta.win = _.get(item, 'win') ? '🏅'  : '-'
                  meta.winMt = item.winAt ? moment(item.winAt) : null
                  meta.message = _.get(item, 'message') || ''

                  meta.createdAt = item.createAt
                  meta.createdMt = moment(item.createdAt)

                  return (
                    <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                      {(key => {
                        switch (key) {
                          case 'checkbox':
                            return (
                              <>
                                <input type="checkbox" checked={isExistsSelected(item)} readOnly onClick={e => [e.stopPropagation(), doCheck(item)]} />
                              </>
                            )
                          case 'DateColumn':
                            return (
                              <div style={{ width: '100%', textAlign: 'center' }}>
                                <strong>{meta.createdMt ? meta.createdMt.format('YYYY.MM.DD') : '0000.00.00'}</strong>
                                <small style={{ marginLeft: '0.35rem' }}>{meta.createdMt ? meta.createdMt.format('HH:mm') : '00:00'}</small>
                              </div>
                            )
                          case 'UserColumn':
                            return (
                              <div style={{ width: '100%', textAlign: 'center' }}>
                                <a
                                  href="#유저정보확인"
                                  onClick={async (e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    if (!meta.userId) { return alert('해당로그에 유저정보가 기재되지 않았습니다. 관리자에게 문의해주세요.') }
                                    return window.open(`/users/${meta.userId}`)
                                  }}
                                >
                                  <strong>{meta.userAccountId}</strong>  
                                </a>
                              </div>
                            )
                          case 'SelectedsColumn':
                            const badgeStyle = {
                              display: 'inline-block', padding: '0.25rem',
                              background: 'white', borderRadius: '0.3618rem', marginRight: '0.5rem'
                            }
                            return (
                              <div style={{ width: '100%', textAlign: 'left', overflow: 'hidden' }}>
                                <details>
                                  <summary style={{ cursor: 'pointer' }}>
                                    <strong style={{ marginRight: '0.35rem' }}>총 {meta.identityCount}개 응답</strong>
                                    <small>({meta.elements.map(elem => elem.name).join(', ')})</small>
                                  </summary>
                                  <div style={{ margin: '0.5rem 0', padding: '1rem', background: '#eaeaea', borderRadius: '0.3618rem' }}>
                                    <div>
                                      {(meta.elements || []).map((elem, eIdx) => {
                                        return (
                                          <span key={`elem-${eIdx}`} style={badgeStyle}>{elem.name}</span>
                                        )
                                      })}
                                    </div>
                                    {meta.message
                                      ? (
                                        <div style={{ paddingTop: '1rem', width: '100%', whiteSpace: 'wrap', lineHeight: '1.618em' }}>
                                          {meta.message}
                                        </div>
                                      ) : null}
                                  </div>
                                </details>
                              </div>
                            )
                          case 'WinColumn':
                            return (
                              <div style={{ width: '100%', textAlign: 'center', overflow: 'hidden' }}>
                                <span title={meta.winMt ? meta.winMt.format('YYYY-MM-DD') : ''}>
                                  {meta.win}
                                </span>
                              </div>
                            )
                          case 'ControlColumn':
                            return (
                              <div>
                                <button
                                  type="button"
                                  className="button"
                                  onClick={async (e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    return await actions.deleteSurveyLog(item._id)
                                  }}
                                >
                                  취소
                                </button>
                              </div>
                            )
                          default:
                        }
                      })(column.key)}
                    </DataSet.Col>
                  )
                })}
              </DataSet.Item>
            ))}
          </DataSet.Body>
        </DataSet>

        <Pager
          total={count}
          page={page}
          limit={limit}
          blockLimit={5}
          setPage={(page) => {
            return this.setState({ page }, () => { initialize() })
          }}
        />
        <div style={{ padding: '1rem 0', textAlign: 'right' }}>
            🎏 우승/추첨선정자 선정 시 <strong style={{ color: 'red', textDecoration: 'underline' }}>실시간 반영되오니 마감 후 진행</strong> 하세요.
        </div>
      </Container>
    ) 
  }
}

export default VoterListContainer
