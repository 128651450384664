import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { Switch, Route, Redirect } from 'react-router-dom'

import qs from 'query-string'
import _ from 'lodash'
import moment from 'services/moment'

import Page from 'components/layouts/Page'
import ActivityCartList from 'components/extras/ActivityCartList'
import ActivitySurveyList from 'components/extras/ActivitySurveyList'
import ActivitySurveyDetail from 'components/extras/ActivitySurveyDetail'
import ActivityNotificationList from 'components/extras/ActivityNotificationList'
import ActivitySolutionOrderList from 'components/extras/ActivitySolutionOrderList'
import ActivityFeedList from 'components/extras/ActivityFeedList'
import ActivityFeedCommentList from 'components/extras/ActivityFeedCommentList'

class ActivityPageContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { admin, history, location, match } = this.props
    const { nav } = match.params || {}

    let breadcrumb = ['홈', '활동'], title = '활동'

    let navs = []
    navs.push({ name: 'carts', text: '장바구니', active: false })
    navs.push({ name: 'surveys', text: '서베이', active: false })
    navs.push({ name: 'notifications', text: '알림', active: false })
    navs.push({ name: 'solutionOrders', text: '작가서비스 대화', active: false })
    navs.push({ name: 'feeds', text: '전체글', active: false })
    navs.push({ name: 'feedComments', text: '전체댓글', active: false })
    navs = navs.map(n => n.name === nav ? { ...n, active: true } : n)

    const currentNav = navs.find(n => n.name === nav) || {}
    if (currentNav.text) {
      breadcrumb.push(currentNav.text)
      title = currentNav.text
    }

    return (
      <Page>
        <Helmet>
          <title>활동({[...breadcrumb].pop()}) - BOOKK</title>
        </Helmet>
        <Page.BreadCrumb path={breadcrumb} />
        <Switch>

        <Route
              path="/activities/:nav/:id"
              exact
              component={(props) => {
                const { location, match } = props
                const { nav, id } = match.params || {}
                const search = location.search ? qs.parse(location.search) : {}

                const cProps = {}
                cProps.surveyId = id

                return <>
                  <Page.Nav>
                    <Page.Nav.Items>
                      {navs.map((item, index) => 
                        <Page.Nav.Item
                          key={`nav_items_${index}`} href={`#${item.name}`} className={item.active ? "active" : null}
                          onClick={e => [e.stopPropagation(), e.preventDefault(), window.location.href = `/activities/${item.name}`]}
                        >
                          <span>{item.text}</span>
                        </Page.Nav.Item>
                      )}
                    </Page.Nav.Items>
                  </Page.Nav>
                  {['surveys'].includes(nav) && id ? <ActivitySurveyDetail {...cProps} /> : null}
                </>
              }}
            />
          <Route
              path="/activities/:nav?"
              exact
              component={(props) => {
                const { location } = props
                const search = location.search ? qs.parse(location.search) : {}

                const cProps = { admin, title: currentNav.text, nav, ...props }

                //  페이지 인자 정리
                cProps.page = !_.isNaN(search.page) && _.isNumber(search.page) ? search.page * 1 : 1
                if (search.limit) {
                  cProps.limit = Number(search.limit)
                  if (!_.isNaN(cProps.limit) && !_.isNumber(cProps.limit)) { delete cProps.limit }
                }
                if (search.blockLimit) {
                  cProps.blockLimit = Number(search.blockLimit)
                  if (!_.isNaN(cProps.blockLimit) && !_.isNumber(cProps.blockLimit)) { delete cProps.blockLimit }
                }
                if (search.sorts) { cProps.sorts = search.sorts.split(',') }
                if (search.startAt) { cProps.startAt = new Date(search.startAt) }
                if (search.endAt) { cProps.endAt = new Date(search.endAt) }

                if (search.keywordsOption) { cProps.keywordsOption = search.keywordsOption }
                if (search.keywords) { cProps.keywords = search.keywords }

                // 필터구문 정리
                const propsKeys = Object.keys(search)
                const filtersKeys = propsKeys.filter(key => key.indexOf('filters_') === 0)
                if (filtersKeys && filtersKeys.length) {
                  cProps.filters = {}
                  filtersKeys.forEach(filtersKey => {
                    const key = filtersKey.replace('filters_', '')
                    cProps.filters[key] = search[filtersKey]
                  })
                }

                return <>
                  <Page.Nav>
                    <Page.Nav.Items>
                      {navs.map((item, index) => 
                        <Page.Nav.Item
                          key={`nav_items_${index}`} href={`#${item.name}`} className={item.active ? "active" : null}
                          onClick={e => [e.stopPropagation(), e.preventDefault(), window.location.href = `/activities/${item.name}`]}
                        >
                          <span>{item.text}</span>
                        </Page.Nav.Item>
                      )}
                    </Page.Nav.Items>
                  </Page.Nav>
                  {['carts'].includes(nav) ? <ActivityCartList {...cProps} /> : null}
                  {['surveys'].includes(nav) ? <ActivitySurveyList {...cProps} /> : null}
                  {['notifications'].includes(nav) ? <ActivityNotificationList {...cProps} /> : null}
                  {['solutionOrders'].includes(nav) ? <ActivitySolutionOrderList {...cProps} /> : null}
                  {['feeds'].includes(nav) ? <ActivityFeedList {...cProps} /> : null}
                  {['feedComments'].includes(nav) ? <ActivityFeedCommentList {...cProps} /> : null}
                </>
              }}
            />

        </Switch>
      </Page>
    )
  }
}

ActivityPageContainer.propTypes = {
  admin: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

ActivityPageContainer.defaultProps = {
  admin: {},
  history: {},
  location: {},
  match: {},
}

export default ActivityPageContainer
