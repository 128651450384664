import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  font-family: ${font('primary')};

  & > div.payType { position: relative; box-sizing: border-box; }
  & > div.subject { position: relative; box-sizing: border-box; padding-bottom: 0.35rem; }
  & > div.bookAuthor { position: relative; box-sizing: border-box; padding-bottom: 0.35rem; }
  & > div.bookPrice { position: relative; box-sizing: border-box; font-size: 0.85em; }

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

// 도서정보를 표현하는 컴포넌트 컬럼, Approval Structure 기반으로 짜여짐
// 과거에 제출했던 정보를 기반으로 하는 문서임
// 표현될 기획 내용 : 저자명. 종이책전자책 구분, 소비자가격, 출판목적
class PayColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = { datas: [] }

    this.initialize = this.initialize.bind(this)
  }

  async initialize() {
    // const output = await api.get(`/approvals/admin2/${this.props.nav}?${qs.stringify(query)}`, { signal: this.abortController.signal })
    //   .then(({ count, rows }) => ({ rows, count, error: false }))
    //   .catch(e => ({ rows: [], count: 0, error: e.message }))
    this.setState({ datas: [] })
  }
  
  render() {
    const { item } = this.props

    const meta = {
      payId: _.get(item, 'id'),
      subject: _.get(item, 'subject') || '결제내역명 없음'
    }
    
    return (
      <Column>
        <div className="subject">
          <a
            href={`/pays/${item.payType}/${meta.payId}`}
            target="_blank"
            style={{ fontWeight: 900, fontSize: '1.3618em', color: '#111' }}
            onClick={(e) => {
              if (!meta.payId) {
                e.stopPropagation()
                e.preventDefault()
                return alert(`아직 상품이 존재하지 않거나, 상품이 만들어지지 않은 문서입니다.`)
              }
              return true
            }}
          >
            {meta.subject}
          </a>
        </div>        
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

PayColumnContainer.propTypes = {
  item: PropTypes.object,
}

PayColumnContainer.defaultProps = {
  item: {}
}

export default PayColumnContainer
