import React from 'react'
import PropTypes from 'prop-types'

import api from 'services/api'
import moment from 'services/moment'
import qs from 'query-string'
import _ from 'lodash'
import { comma } from 'services/utils'

import { Importer, Exporter } from 'services/transform'
import exportDefaultExcelParser from './export.default'

import { Page, DataSet } from './utils'

const List = ({ ...props }) => {
  const {
    quoteName,
    payload = {}, createdAt,
    handle, clearBrowser,
    getSignal
  } = props

  const books = _.get(payload, 'books') || []

  const actions = {}

  actions.getFirstBookProducts = async (keywords) => {
    const query = {}
    query.flags = 'currentUser'
    query.keywords = keywords || '-9999'
    query.keywordsOption = 'bookNo'
    query.statusScope = ['temporary', 'activated', 'deactivated'].join(',')
    query.limit = 1
    query.page = 1
    query.sort = '-createdAt'
    query.usedCount = false
    const endpoint = `/products/admin2/book?${qs.stringify(query)}`
    return await api.get(endpoint, { signal: getSignal() })
      .then((result) => result.rows ? result.rows.pop() : null)
      .catch(e => {
        console.log(e.message)
        return null
      })
  }

  // 도서추가 기능
  actions.addBook = async  () => {
    let value = window.prompt(`도서번호를 입력해주세요. (숫자만 기재 가능)`)
    if (!value) { return alert(`올바르게 기재해주세요.`) }
    if (!_.isInteger(value * 1)) { return alert(`올바르게 기재해주세요.`) }

    const book = await actions.getFirstBookProducts(value)
      .catch(e => {
        console.log(e.message)
        return null
      })
    if (!book) { return alert(`도서가 검색되지 않았습니다.`) }

    const next = { ...payload }

    const existsBookNos = next.books
      .filter(product => _.get(product, 'content.bookNo'))
      .map(product => _.get(product, 'content.bookNo') * 1)

    if (existsBookNos.includes(value * 1)) { return alert(`이미 해당 도서번호가 존재합니다.`) }
    
    next.books = [...next.books, book]
    return await handle({ payload: next })
  }

  // 도서 삭제 기능
  actions.removeBook = async  (idx = -1) => {
    if (idx < 0) { return false }

    const next = { ...payload }
    next.books = [...next.books].filter((book, bIdx) => { return bIdx !== idx })
    return await handle({ payload: next })
  }

  // 엑셀 다운로드 기능
  actions.downloadExcel = async (items = books) => {
    const curAt = new Date(), curMt = moment(curAt)
    const fileName = `도서 견적서 총 ${books.length}종 (${curMt.format('YYYY년 MM월 DD일')})`
    return await new Exporter({ parser: exportDefaultExcelParser, items })
      .download(fileName)
      .catch((e) => { return alert(e.message) })
  }

  // 비우기 기능
  actions.clearBooks = async () => {
    await clearBrowser(quoteName)
  }

  const columns = []
  columns.push({ name: 'BookNoColumn', text: '도서번호', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'center', textAlign: 'center' } })
  columns.push({ name: 'BookTitleColumn', text: '도서명', style: { minWidth: '416px', maxWidth: '416px', justifyContent: 'left', textAlign: 'left' } })
  columns.push({ name: 'BookAuthorColumn', text: '저자명', style: { minWidth: '220px', maxWidth: '220px', justifyContent: 'center', textAlign: 'center' } })
  columns.push({ name: 'SelfPriceColumn', text: '본인구매', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'center', textAlign: 'center' } })
  columns.push({ name: 'FirstPriceColumn', text: '첫구매', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'center', textAlign: 'center' } })
  columns.push({ name: 'BookPriceColumn', text: '정가', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'center', textAlign: 'center' } })
  columns.push({ name: 'RetailAuthorRoyaltyColumn', text: '유통인세', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'center', textAlign: 'center' } })
  columns.push({ name: 'BlankColumn', text: '', style: { flex: '1 1 auto', minWidth: '0px', maxWidth: '100%', justifyContent: 'center', textAlign: 'center' } })
  columns.push({ name: 'ControlColumn', text: '관리', style: { minWidth: '100px', maxWidth: '100px', justifyContent: 'center', textAlign: 'center' } })

  return (
    <div>
      
      <Page>
        <Page.Header>
          <div>견적서 만들기</div>
          <div style={{ fontWeight: 300, padding: '1rem 0' }}>
            <small>총 <strong>{comma(books.length)}종</strong></small>
          </div>
        </Page.Header>

        <Page.Tools>
            <div>
              <Page.Button
                href={`#addBook`}
                style={{ marginRight: '0.368rem' }}
                onClick={async (e) => {
                  e.stopPropagation()
                  e.preventDefault()

                  return await actions.addBook()
                }}
              >
              도서추가
            </Page.Button>
            <Page.Button
              href={`#downloadExcel`}
              style={{ marginRight: '0.368rem' }}
              onClick={async (e) => {
                e.stopPropagation()
                e.preventDefault()

                return await actions.downloadExcel()
              }}
              >
              전체엑셀 📥
            </Page.Button>
            </div>
            <div>
            <Page.Button
              href={`#clearBooks`}
              onClick={async (e) => {
                e.stopPropagation()
                e.preventDefault()

                return await actions.clearBooks(quoteName)
              }}
              >
              모두 비우기 🧹
            </Page.Button>
          </div>
        </Page.Tools>
        
        <DataSet>
          <DataSet.Header>
            <DataSet.Item>
              {columns.map((column, cIdx) => {
                return (
                  <DataSet.Col key={`header_${cIdx}`} style={{ ...column.style }}>
                    {column.text}
                  </DataSet.Col>
                  )
              })}
            </DataSet.Item>
          </DataSet.Header>

          {!books.length
            ? (
              <DataSet.NotFound>
                <header style={{ marginBottom: '0.768rem' }}>
                  <strong>견적서가 비어 있습니다.</strong>
                </header>
                <section>
                  도서추가 버튼을 누르고 도서번호로<br />
                  검색하게 되면 자동으로 담겨집니다.
                </section>
              </DataSet.NotFound>
            )
            : null}

          {books.length
            ? (
              <DataSet.Body>
                {books.map((product, bIdx) => {
                  const book = product.content || {}
                  const othersRetailRoyalty = _.get(product, 'royaltyAmounts.others') || { amount: 0 }

                  const meta = {}

                  meta.bookNo = _.get(book, 'bookNo')
                  meta.bookType = _.get(book, 'type')
                  meta.bookTitle = _.get(book, 'title')
                  meta.bookAuthor = _.get(book, 'author')
                  meta.purposeName = _.get(book, 'purpose.name')
                  meta.bookTextColorName = _.get(book, 'text.color.name')
                  meta.usedDiscount = _.get(book, 'usedDiscount')

                  meta.defaultPrice = _.get(book, 'defaultPrice')
                  meta.basicPrice = _.get(book, 'basicPrice')
                  meta.collectionPrice = _.get(book, 'collectionPrice')

                  // @ 정가 : 소장가 또는 일반판매가
                  meta.bookPrice = ['collection'].includes(meta.purposeName) ? meta.collectionPrice : meta.basicPrice

                  if (['paperBook'].includes(meta.bookType)) {
                    meta.selfPrice = _.get(book, 'usedDiscount')
                      ? (meta.usedDiscount ? meta.defaultPrice : meta.bookPrice)
                      : meta.bookPrice
                    meta.selfPrice = parseInt(meta.selfPrice * (['color'].includes(meta.bookTextColorName) ? 0.85 : 0.65))
                    meta.firstPrice = ['collection'].includes(meta.purposeName)
                      ? 0
                      : parseInt(['color'].includes(meta.bookTextColorName) ? (meta.bookPrice * 0.8) : (meta.bookPrice * 0.6))
                    meta.retailAuthorRoyalty = othersRetailRoyalty.amount || 0
                  } else {
                    meta.selfPrice = parseInt(book.basicPrice * (['color'].includes(meta.bookTextColorName) ? 0.85 : 0.65))
                    meta.firstPrice = parseInt(meta.bookPrice * 0.7)
                    meta.retailAuthorRoyalty = othersRetailRoyalty.amount || 0
                  }

                  return (
                    <DataSet.Item key={`${bIdx}`}>
                      {columns.map((column, cIdx) => {

                        if (['BookNoColumn'].includes(column.name)) {
                          return (
                            <DataSet.Col key={`data_${cIdx}`} style={{ ...column.style }}>
                              <strong>{meta.bookNo || '-'}</strong>
                            </DataSet.Col>
                          )
                        }

                        if (['BookTitleColumn'].includes(column.name)) {
                          return (
                            <DataSet.Col key={`data_${cIdx}`} style={{ ...column.style }}>
                              <strong
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => {
                                  return window.open(`/products/books/${product.id}`)
                                }}
                              >
                                <span style={{ marginRight: '0.35rem' }}>{['paperBook'].includes(meta.bookType) ? `📖` : `📱`}</span>
                                <span>{meta.bookTitle || '도서명'}</span>
                              </strong>
                            </DataSet.Col>
                          )
                        }

                        if (['BookAuthorColumn'].includes(column.name)) {
                          return (
                            <DataSet.Col key={`data_${cIdx}`} style={{ ...column.style }}>
                              <strong>{meta.bookAuthor || '저자명'}</strong>
                            </DataSet.Col>
                          )
                        }

                        if (['SelfPriceColumn'].includes(column.name)) {
                          return (
                            <DataSet.Col key={`data_${cIdx}`} style={{ ...column.style }}>
                              <strong>{comma(meta.selfPrice)}</strong>
                              <small style={{ marginLeft: '0.35rem' }}>원</small>
                            </DataSet.Col>
                          )
                        }

                        if (['FirstPriceColumn'].includes(column.name)) {
                          return (
                            <DataSet.Col key={`data_${cIdx}`} style={{ ...column.style }}>
                              {meta.firstPrice
                                ? (
                                  <>
                                    <strong>{comma(meta.firstPrice)}</strong>
                                    <small style={{ marginLeft: '0.35rem' }}>원</small>
                                  </>
                                )
                                : null}
                            </DataSet.Col>
                          )
                        }

                        if (['BookPriceColumn'].includes(column.name)) {
                          return (
                            <DataSet.Col key={`data_${cIdx}`} style={{ ...column.style }}>
                              <strong>{comma(meta.bookPrice)}</strong>
                              <small style={{ marginLeft: '0.35rem' }}>원</small>
                            </DataSet.Col>
                          )
                        }

                        if (['RetailAuthorRoyaltyColumn'].includes(column.name)) {
                          return (
                            <DataSet.Col key={`data_${cIdx}`} style={{ ...column.style }}>
                              <strong>{comma(meta.retailAuthorRoyalty)}</strong>
                              <small style={{ marginLeft: '0.35rem' }}>원</small>
                            </DataSet.Col>
                          )
                        }

                        if (['ControlColumn'].includes(column.name)) {
                          return (
                            <DataSet.Col key={`data_${cIdx}`} style={{ ...column.style }}>
                              <a
                                href="#removeBook"
                                onClick={async (e) => {
                                  return await actions.removeBook(bIdx)
                                }}
                              >
                                삭제
                              </a>
                            </DataSet.Col>
                          )
                        }

                        return (
                          <DataSet.Col key={`data_${cIdx}`} style={{ ...column.style }}></DataSet.Col>)
                      })}
                    </DataSet.Item>
                  )
                })}
              </DataSet.Body>
            )
            : null}

        </DataSet>
      </Page>
    </div>
  )

}

export default List
