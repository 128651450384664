// 수동모달
import React from 'react'

import api from 'services/api'
import qs from 'query-string'
import _ from 'lodash'
import moment from 'services/moment'

import Modal from 'components/utils/Modal'

import { Form } from './utils'

const { REACT_APP_FILE_URL } = process.env

// @ 요소를 추가하는 기능
class AddElementModalContainer extends React.Component {
  constructor(props) {
    super(props)
    const { survey } = this.props

    this.uploadForm = React.createRef()
    this.abortController = new AbortController()
    this.state = { 
      loading: false, error: false, message: '',
      form: {
        identity: Date.now(), productId: '', userId: '', file: null,
        name: '', description: '', listOrder: 0, status: 'enabled', blocked: false
      }
    }

    this.loadItem = this.loadItem.bind(this)
    this.initialize = this.initialize.bind(this)
    this.uploadFIle = this.uploadFIle.bind(this)
    this.doSave = this.doSave.bind(this)
  }

  componentDidMount() {
    return this.initialize()
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  // @ 엘리먼트 정보 추가적인 부분 가져와야 할 때 쓰기
  async loadItem() {
    return { error: false, message: '정상적으로 불러왔습니다.' }
  }

  // @ 엘리먼트 데이터에 필요한 데이터 불러오는 구간
  async initialize() {
    const { error, message } = await this.loadItem()
      .catch((e) => {
        console.log(e.message, e.stack)
        return { error: true, message: '데이터를 불러오던 도중 문제가 발생했습니다.' }
      })
    await new Promise((r) => this.setState({ loading: false, error, message }, r))
  }

  async uploadFIle(e) {
    const file = (e.target && e.target.files) ? e.target.files[0] : null
    if (!file) { return }

    const { survey } = this.props
    const { form } = this.state

    const fileName = file.name.toLowerCase()
    if (!fileName.endsWith('.jpg') && !fileName.endsWith('.png')) { return alert('JPG 또는 PNG 파일을 업로드해주세요.') }

    // @ FormData에 담아서 전송하기
    const formData = new FormData()
    formData.append('files', file)
    const result = await api.upload(`/surveys/admin/${survey._id}/files`, formData, { base: 'file' })
      .then(async (fb) => ({ error: false, message: '정상적으로 파일업로드가 이루어졌습니다.', ...fb }))
      .catch((e) => { return { error: true, message: '파일업로드에 에러가 발생 하였습니다.' } })
    this.uploadForm.current.reset()
    if (result.error) { return alert(result.message) }
    
    // @ 항목을 상위 컴포넌트에 전달하기
    const next = { ...form }
    next.file = { filename: result.filename, path: result.path }
    this.setState({ form: next })
    // console.log(`업로드 후 전달 받은 데이터`, result)
    // console.log(`정상적으로 파일 업로드를 마쳤음.`, next)
  }

  // @ 항목을 추가해서 상위 컴포넌트의 상태에 전달해주는 기능
  async doSave() {
    const { survey, form: parentForm, parent, onClose } = this.props
    const { form } = this.state

    // @ 상위 요소에 있는 엘리먼트 데이터 불러오기
    const next = parentForm.elements ? [...parentForm.elements] : []
    
    // @ 이전에 동일한 항목이 추가되었는지 체크하기
    const exists = next.find(o => o.identity === form.identity)
    if (exists) { return alert('동일한 항목을 추가할 순 없습니다.') }
    
    // @ 도서상품관리ID 기반의 중복조사
    if (survey.classify === 'book') {
      if (!form.productId) { return alert('상품관리ID를 반드시 지정해야, 도서상품이 항목에 연동 됩니다.') }
      const exists = next.find(o => o.identity !== form.identity && o.productId === form.productId)
      if (exists) { return alert('이미 해당 상품관리ID를 사용중인 다른 요소가 존재 합니다.') }
    }

    // @ 유저관리ID 기반의 중복조사
    if (survey.classify === 'user') {
      if (!form.userId) { return alert('유저관리ID를 반드시 지정해야, 해당 유저가 항목에 연동 됩니다.') }
      const exists = next.find(o => o.identity !== form.identity && o.userId === form.userId)
      if (exists) { return alert('이미 해당 유저관리ID를 사용중인 다른 요소가 존재 합니다.') }
    }
    
    // @ 추가하기 전에 데이터를 현재 폼에 정상적인 데이터가 있는지 확인하기
    const result = await api.post(`/surveys/admin2/${survey._id}/make-survey-elements`, { form })
      .catch((e) => {
        console.log(e.message, e.stack)
        return { error: true, message: '해당 항목을 추가하려하던 도중 문제가 발생하였습니다.' }
      })
    if (result.error) { return alert(result.message) }
    if (!result.element) { return alert('데이터 랩핑이 완료된 요소 정보를 서버에서 받지 못했습니다.') }

    // @ 항목을 상위 컴포넌트에 전달하기
    const element = result.element
    next.push(element)
    const modifiedParentForm = { ...parentForm, elements: next }
    parent.setState({ form: modifiedParentForm })
    alert('해당 항목을 정상적으로 추가하였습니다.')
    
    return onClose()
  }
  
  // 랜더링
  render() {
    const { initialize, uploadFIle, doSave } = this
    const { survey, onClose } = this.props
    const { loading, error, message, form } = this.state
    if (loading) { return null }
    if (error) { return <div>{message}</div> }

    // 모달 프로퍼티 설정 구간, 닫기 버튼에 대한 컴포넌트 라이프 사이클에 대해서만 상위에서 관장한다.
    const modalProps = {}
    modalProps.isOpen = true
    modalProps.onClose = onClose ? onClose : (async () => {})

    const meta = {}
    meta.file = _.get(form, 'file') || null
    
    return (
      <Modal {...modalProps}>
        <Form style={{ maxWidth: '1261px', minWidth: '1261px', width: '1261px' }}>
          <Form.Header>
            <div className="lead">
              서베이<br/>
              새 항목 추가
            </div>
          </Form.Header>
          <Form.Aside>
            <div className="label">대표사진 업로드</div>
            <div className="control">
              <form ref={this.uploadForm}><input type="file" onChange={uploadFIle} /></form>
            </div>
            {(meta.file && meta.file.path)
              ? (<div className="thumbnail"><img src={`${REACT_APP_FILE_URL}${meta.file.path}`} /></div>)
              : null}
          </Form.Aside>
          <Form.Body>
            <div className="label">항목발행코드</div>
            <div className="control">
              <input
                type="text"
                defaultValue={form.identity}
                disabled={true}
              />
            </div>

            <div className="label">명칭</div>
            <div className="control">
              <input
                type="text"
                value={form.name}
                placeholder="요소명 지정"
                onChange={(e) => {
                  const next = { ...form }
                  next.name = e.target.value
                  this.setState({ form: next })
                }}
              />
            </div>
            
            <div className="label">요소 표현우선순위</div>
            <div className="control">
              <input
                type="number"
                value={form.listOrder}
                placeholder="요소의 우선번호"
                onChange={(e) => {
                  const next = { ...form }
                  next.listOrder = Math.abs(parseInt(e.target.value))
                  if (next.listOrder < 0) { next.listOrder = 0 }
                  this.setState({ form: next })
                }}
              />
            </div>
            
            <div className="label">요소 표현여부</div>
            <div className="control">
              <select
                type="number"
                value={form.status}
                placeholder="요소의 우선번호"
                onChange={(e) => {
                  const next = { ...form }
                  next.status = e.target.value
                  if (next.status === 'disabled') { next.blocked = true }
                  this.setState({ form: next })
                }}
              >
                <option value="enabled">🟢 요소 사용자 표현</option>
                <option value="disabled">⚪ 요소 사용자 표현안함</option>
              </select>
            </div>
            
            <div className="label">요소 선택가능 여부</div>
            <div className="control">
              <select
                type="number"
                value={form.blocked ? 'true' : 'false'}
                placeholder="요소의 우선번호"
                onChange={(e) => {
                  const next = { ...form }
                  next.blocked = e.target.value === 'true' ? true : false
                  this.setState({ form: next })
                }}
              >
                <option value="false">🟢 요소 선택가능</option>
                <option value="true">🔴 요소 선택불가</option>
              </select>
            </div>
            
            {['book'].includes(survey.classify)
              ? (
                <>
                  <div className="label">상품관리ID</div>
                  <div className="control">
                    <input
                      type="text"
                      value={form.productId}
                      placeholder="상품ID 추가"
                      onChange={(e) => {
                        const next = { ...form }
                        next.productId = e.target.value
                        this.setState({ form: next })
                      }}
                    />
                  </div>
                </>
              )
              : null}
            
            {['user'].includes(survey.classify)
              ? (
                <>
                  <div className="label">유저관리ID</div>
                  <div className="control">
                    <input
                      type="text"
                      value={form.userId}
                      placeholder="유저관리ID 추가"
                      onChange={(e) => {
                        const next = { ...form }
                        next.userId = e.target.value
                        this.setState({ form: next })
                      }}
                    />
                  </div>
                </>
              )
              : null}
            
            <div className="label">설명문구</div>
            <div className="control">
              <textarea
                value={form.description}
                placeholder="설명 지정"
                onChange={(e) => {
                  const next = { ...form }
                  next.description = e.target.value
                  this.setState({ form: next })
                }}
              />
            </div>
            
            <div className="buttons">
              <a
                href="#닫기"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  return onClose()
                }}
              >
                닫기
              </a>
              <a
                href="#저장"
                className="primary"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  return doSave()
                }}
              >
                저장
              </a>
            </div>
          </Form.Body>
        </Form>
      </Modal>
    )
  }
}

export default AddElementModalContainer