// 수동모달
import React from 'react'

import styled, { css } from 'styled-components'
import { font, palette } from 'styled-theme'

import _ from 'lodash'
import api from 'services/api'
import qs from 'query-string'

import moment from 'services/moment'
import { comma } from 'services/utils'

import { Importer, Exporter } from 'services/transform'

import Modal from 'components/utils/Modal'
import Pager from 'components/utils/Pager'
import DataSet from 'components/utils/DataSet'

const REACT_APP_FILE_URL = process.env.REACT_APP_FILE_URL

const controlStyles = css`
  & div.header {
    position: relative; box-sizing: border-box;
    padding: 1rem;
    & small { color: ${palette('muted', 8)}; }
  }

  & > * { flex: 1 1 100%; }
  & input[type=text],
  & input[type=number],
  & input[type=time],
  & input[type=date] {
    position: relative; box-sizing: border-box;
    font-size: 1em; border-radius: 3px;
    width: 100%; border: 1px solid #e1e2e3; outline: none;
    font-family: ${font('primary')};
    padding: 0.5rem 0.75rem; transition: 0.3s all;
    &:hover { background: #fafafa; border: 1px solid #929394 }
  }
  & select {
    position: relative; box-sizing: border-box;
    font-size: 1em; border-radius: 3px;
    width: 100%; border: 1px solid #e1e2e3; outline: none;
    font-family: ${font('primary')};
    padding: 0.35rem 0.75rem; transition: 0.3s all;
    &:hover { background: #fafafa; border: 1px solid #929394 }
  }
  & textarea {
    position: relative; box-sizing: border-box;
    font-size: 1em; border-radius: 3px;
    font-family: ${font('primary')};
    line-height: 1.55rem; border: 1px solid #e1e2e3;
    width: 100%; border: 1px solid #e1e2e3; outline: none;
    padding: 0.35rem 0.75rem; transition: 0.3s all;
    &:hover { background: #fafafa; border: 1px solid #929394 }
  }
  & button {
    position: relative; box-sizing: border-box;
    font-size: 1em; border-radius: 3px;
    font-family: ${font('primary')};
    white-space: nowrap; line-height: 1.55rem;
    width: max-content; outline: none; font-weight: 700;
    padding: 0.35rem 0.75rem; transition: 0.3s all;
    cursor: pointer;
    &.primary {
      background: #333; border: 1px solid #333; color: white; 
      &:hover { background: #000; border: 1px solid #000; color: white;  }
    }
    background: #fff; border: 1px solid #929292; color: #929292;
    &:hover { background: #fff; border: 1px solid #111; color: #111; }
  }
`

// 폼 사이즈 구성
const Form = styled.div`
  position: relative; box-sizing: border-box;
  transition: all 0.3s; padding: 1rem;
  ${controlStyles}
`

Form.Header = styled.header`
  position: relative; box-sizing: border-box;
  padding: 1rem 0.5rem; font-weight: 100;
  font-size: 1.43em; font-family: ${font('primary')};
  & strong { font-weight: 900; }
`

Form.Body = styled.div`
  position: relative; box-sizing: border-box;
`

// 외부 유통판매상태 관리 모달
class BookRetailStatusExcelModalContainer extends React.Component {
  constructor(props) {
    super(props)

    this.initializedState = {
      loading: true, error: false, message: '',
      form: {
        retail: props.retail || 'bookk',
        method: props.method || 'auto',
        usedForced: 'inherit', expendSave: 'none',
        bookRetailComment: '',
        bookStopRetailComment: ''
      },
      count: 0, items: [], dashboard: { error: false, message: '' },
      page: 1, limit: 5, blockLimit: 5, sorts: ['-createdAt']
    }

    this.state = JSON.parse(JSON.stringify(this.initializedState))

    this.loadResource = this.loadResource.bind(this)
    this.initialize = this.initialize.bind(this)
    this.downloadSample = this.downloadSample.bind(this)
    this.uploadExcelFile = this.uploadExcelFile.bind(this)
    this.getDashboard = this.getDashboard.bind(this)
    this.doExecuted = this.doExecuted.bind(this)

    this.abortController = new AbortController()
  }

  componentDidMount() {
    return this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }

  // 리소스 불러오기 @ 2023.01.30 최종판
  async loadResource(name, method) {
    const that = this
    const { resource = {} } = this.state

    // 리소스를 가져오면 필터의 컨디션에 세팅을 해준다.
    resource[name] = await api.get(`/resources/admin2/${method}`).catch(e => ({}))

    await new Promise(r => that.setState({ resource }, r))
  }
  
  async initialize() {
    await new Promise((r) => this.setState({ loading: true }, r))
    await this.loadResource('retails', 'takeRetails') // 유통사 정보 리소스 불러오기
    const state = JSON.parse(JSON.stringify(this.initializedState))
    await new Promise((r) => this.setState({ ...state, loading: false }, r))
  }

  // 특정 유통사별 샘플 양식을 다운로드 받을 수 있도록 구성
  // 향후 각기 유통사마다 추가적인 업데이트 컬럼이 변화될 수 있음을 수반하는 기능 @2023.03.06
  async downloadSample() {
    const { form, resource = {} } = this.state

    const retails = resource.retails || {}
    const retail = _.get(retails, form.retail) || {} // 추후 유통사에 따른 대응을 할 수 있도록 구성하기
    if (!retail.text) { return alert(`반드시 유통사를 선택해야합니다.`) }

    const method = form.method || 'auto'

    const parser = {}
    parser.name = ['acceptBookRetail'].includes(method)
      ? `${retail.text} 판매 엑셀`
      : (['acceptBookStopRetail'].includes(method) ? `${retail.text} 중지 엑셀` : `${retail.text} 판매&중지 엑셀`)

    parser.columns = []
    parser.columns.push({ key: 'identity', header: 'ISBN', view: (item) => item.identity })
    parser.columns.push({ key: 'status', header: '판매상태', view: (item) => item.status })
    parser.columns.push({ key: 'retailIsbn', header: `${retail.text} ISBN`, view: (item) => item.retailIsbn })

    const items = []
    if (['auto', 'acceptBookRetail'].includes(method)) {
      items.push({
        identity: '0000000000000',
        status: '판매',
        retailIsbn: `유통사별 ISBN 자동업데이트 값`
      })
    }
    if (['auto', 'rejectBookRetail'].includes(method)) {
      items.push({
        identity: '0000000000000',
        status: '판매불가',
        retailIsbn: `유통사별 ISBN 자동업데이트 값`
      })
    }
    if (['auto', 'acceptBookStopRetail'].includes(method)) {
      items.push({
        identity: '0000000000000',
        status: '중지'
      })
    }
    if (['auto', 'rejectBookStopRetail'].includes(method)) {
      items.push({
        identity: '0000000000000',
        status: '중지불가'
      })
    }

    const curAt = new Date(), curMt = moment(curAt)
    return await new Exporter({ parser, items })
      .download(`${parser.name} 예시양식 (${curMt.format('YYYY년 MM월 DD일 기준')})`)
  }

  // 엑셀파일로 올렸던 데이터를 현재 폼에 맞게 대시보드를 짜주는 기능
  async getDashboard(items = this.state.items) {
    const { form } = this.state
    const { retail, method, usedForced, expendSave } = form || {}

    const dashboard = {}
    dashboard.error = false
    dashboard.message = `정상적으로 데이터를 취합하여 불러왔습니다.`

    // @ 페이로드 설정해서 보내기
    const payload = { retail, method, items, usedForced, expendSave }
    const result = await api.put('/approvals/admin2/retailStatusDashboard', payload)
      .catch((e) => ({ error: true, message: `오류가 발생하였습니다. ${e.message}` }))

    return result
  }
  
  // 파싱스타일의 전환이 이뤄지는 구간을 반영
  async uploadExcelFile() {
    const curAt = new Date(), curMt = moment(curAt)

    const { form, resource = {} } = this.state
    
    const method = form.method || 'auto'

    const start = 2
    const parser = {}

    parser.name = `외부유통 판매신청 일괄정보 변경`
    parser.columns = {}

    // B구분자를 활용한 파싱방식 : 판매와 중지 모두 사용하는 경우, 2023년도 기준으로는 Yes24 전체 도서명부 기반으로 구성
    parser.columns.A = { name: 'identity', text: '구분자', parse: (cell) => cell }
    parser.columns.B = { name: 'status', text: '상태구분', parse: (cell) => cell }
    parser.columns.C = { name: 'retailIsbn', text: '유통사 ISBN', parse: (cell) => cell }

    const result = await new Importer({ parser }).open()
      .then(o => o.read(o.importedFile, start))
      .catch(e => ({ items: [] }))

    const items = result.items
    if (!items.length) { return alert(`해당 파일에서 데이터를 추출해내지 못했습니다.`) }

    const dashboard = await this
      .getDashboard(items)
      .catch((e) => { return { error: true, message: e.message } })

    return await new Promise((r) => this.setState({ count: items.length, items, dashboard }, r))
  }


  // 해당 내역 기준으로 처리할 수 있도록 배치하는 과정을 갖는다.
  // 1000건씩 묶어 put로 처리하는 과정을 갖음
  async doExecuted() {
    const { onClose } = this.props
    const { form, dashboard = {} } = this.state

    const method = form.method || 'auto'

    // ISBN 업데이트
    if (['auto', 'acceptBookRetail'].includes(method)) {
      const form = {}
      form.identities = dashboard.identities.filter(identity => identity.retail && identity.retailIsbn && _.get(identity, 'product.productId'))
      const result = await api.put(`/approvals/admin2/updateManyRetailIsbn`, { form })
        .catch(e => ({ error: false, message: `외부유통사 ISBN을 반영하던 도중 문제가 발생하였습니다. ${e.message}` }))
      if (!result || result.error) { return alert(result && result.message ? `${result.message}` : `오류가 발생하였습니다.`) }
    }

    // auto인경우 정리해서 처리해준다.
    let tasks = ['acceptBookRetail', 'rejectBookRetail', 'acceptBookStopRetail', 'rejectBookStopRetail']
    if (method !== 'auto') { tasks = tasks.filter((key) => method === key) }

    const result = await tasks.reduce((prev, key) => 
      prev.then(async (s) => {
        // @ 가져왔던 문서에서 현재 해당되는 task와 같은 문서만 추려서 보내주기
        let approvals = []
        if (key === 'acceptBookRetail') { approvals = dashboard.bookRetailOkApprovals }
        if (key === 'rejectBookRetail') { approvals = dashboard.bookRetailNoApprovals }
        if (key === 'acceptBookStopRetail') { approvals = dashboard.bookStopRetailOkApprovals }
        if (key === 'rejectBookStopRetail') { approvals = dashboard.bookStopRetailNoApprovals }
        if (!approvals.length) { return s }

        const form = {}
        form.approvals = approvals
        form.retail = _.get(this, 'state.form.retail')
        form.method = key
        form.bookRetailComment = _.get(this, 'state.form.bookRetailComment')
        form.bookStopRetailComment = _.get(this, 'state.form.bookStopRetailComment')

        await api.put(`/approvals/admin2/updateManyRetailStatus`, { form })
          .catch(e => ({ error: false, message: `상태를 변경하던 도중 문제가 발생하였습니다. ${e.message}` }))

        return s
      }),
      Promise.resolve([]))
      .then((ret) => { return { error: false, message: '정상적으로 처리하였습니다.' } })
      .catch((e) => { return { error: true, message: e.message } })

    alert(result && result.message ? `${result.message}` : `정상적으로 처리하였습니다.`)

    return onClose()
  }

  // 랜더링
  render() {
    const { onClose } = this.props
    const {
      loading, error, message,
      form, resource,
      stock, product, seller,
      count, items, dashboard,
      page, limit, blockLimit
    } = this.state
    if (loading) { return null }
    if (error) {
      alert(message)
      return null
    }

    // console.log(`resource`, resource)

    const retails = resource.retails || {}

    // 모달 프로퍼티 설정 구간, 닫기 버튼에 대한 컴포넌트 라이프 사이클에 대해서만 상위에서 관장한다.
    const modalProps = {}
    modalProps.isOpen = true
    modalProps.onClose = onClose ? onClose : (async () => {})

    // 재고로그 컬럼부 배치
    const columns = {}
    columns.no = { name: '', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center', textAlign: 'center' } }
    columns.bookNo = { name: '', style: { minWidth: '180px', maxWidth: '180px', justifyContent: 'center', textAlign: 'center' } }
    columns.nextAmount = { name: '', style: { minWidth: '180px', maxWidth: '180px', justifyContent: 'center', textAlign: 'center' } }
    columns.modifiedAmount = { name: '', style: { minWidth: '180px', maxWidth: '180px', justifyContent: 'center', textAlign: 'center' } }
    columns.memo = { name: '', style: { flex: '1 1 auto' } }

    const meta = {}
    meta.bookCount = _.get(dashboard, 'bookCount') || 0
    meta.retailIsbnUpdatedIdentityCount = _.get(dashboard, 'retailIsbnUpdatedIdentityCount') || 0

    meta.bookRetailCount = _.get(dashboard, 'bookRetailCount') || 0
    meta.bookRetailOkCount = _.get(dashboard, 'bookRetailOkCount') || 0
    meta.bookRetailNoCount = _.get(dashboard, 'bookRetailNoCount') || 0

    meta.bookStopRetailCount = _.get(dashboard, 'bookStopRetailCount') || 0
    meta.bookStopRetailOkCount = _.get(dashboard, 'bookStopRetailOkCount') || 0
    meta.bookStopRetailNoCount = _.get(dashboard, 'bookStopRetailNoCount') || 0

    return (
      <Modal {...modalProps}>
        <Form style={{ maxWidth: '461px', minWidth: '461px', width: '461px' }}>
          <Form.Body>

            <Form.Header style={{ padding: '1rem 0', marginBottom: '0.5rem' }}>
              <div><strong>판매상태 일괄관리</strong></div>
            </Form.Header>

            <div style={{ marginBottom: '0.75rem' }}>
              <select
                value={form.retail}
                onChange={(e) => {
                  if (!e.target.value) { return false }
                  const { form } = this.state
                  const next = { ...form }
                  next.retail = e.target.value
                  if (['aladin'].includes(e.target.value)) { next.method = 'auto' }
                  if (!['aladin'].includes(e.target.value)) { next.method = this.props.defaultMethod || this.props.method || 'auto' }
                  this.setState({ form: next, count: 0, items: [], dashboard: { error: false, message: '' } })
                }}
              >
                <option value="">유통사 구분</option>
                {Object
                  .values(retails)
                  .map((retail, rIdx) => {
                    return <option key={rIdx} value={retail.name}>{retail.text}</option>
                  })}
              </select>
            </div>
            <div style={{ marginBottom: '1rem' }}>
              <select
                value={form.method}
                onChange={(e) => {
                  if (!e.target.value) { return false }
                  const { form } = this.state
                  const next = { ...form }
                  next.method = e.target.value
                  this.setState({ form: next, count: 0, items: [], dashboard: { error: false, message: '' } })
                }}
              >
                <option value="">판매상태 처리</option>
                <option value="auto">🚩 B열 ISBN 구분자 활용(판매/판매불가/중지/중지불가)</option>
                <option value="acceptBookRetail">🟢 판매 승인</option>
                <option value="rejectBookRetail">🔴 판매 불가</option>
                <option value="acceptBookStopRetail">🟢 판매중지 승인</option>
                <option value="rejectBookStopRetail">🔴 판매중지 불가</option>
              </select>
            </div>
            <div style={{ marginBottom: '0.75rem' }}>
              <select
                value={form.usedForced}
                onChange={(e) => {
                  const next = { ...form }
                  next.usedForced = e.target.value
                  this.setState({ form: next })
                }}
              >
                <option value="forced">🟢 유통문서 없어도 반영</option>
                <option value="inherit">⚪ 유통문서가 있어야 반영</option>
              </select>
            </div>
            <div style={{ marginBottom: '0.75rem' }}>
              <select
                value={form.expendSave}
                onChange={(e) => {
                  const next = { ...form }
                  next.expendSave = e.target.value
                  this.setState({ form: next })
                }}
              >
                <option value="eletronicBook-retails-only">📱 전자도서 외부유통 전체</option>
                <option value="paperBook-retails-only">📕 종이도서 외부유통 전체</option>
                <option value="all-retails">🟢 외부유통 모두 동일처리</option>
                <option value="none">⚪ 해당유통사만 처리</option>
              </select>
            </div>
            {['auto', 'acceptBookRetail', 'rejectBookRetail'].includes(form.method)
              ? (
                <div>
                  <textarea
                    value={form.bookRetailComment}
                    onChange={e => {
                      const { form }  = this.state
                      const next = { ...form }
                      next.bookRetailComment = e.target.value
                      return this.setState({ form: next })
                    }}
                    placeholder="판매승인/판매불가에 대한 일괄 메시지 전달을 위한 항목입니다.(없다면 기본 시스템 메시지가 전달됩니다.)"
                    style={{ height: '80px' }}
                  />
                </div>
              )
              : null}

            {['auto', 'acceptBookStopRetail', 'rejectBookStopRetail'].includes(form.method)
              ? (
                <div>
                  <textarea
                    value={form.bookStopRetailComment}
                    onChange={e => {
                      const { form }  = this.state
                      const next = { ...form }
                      next.bookStopRetailComment = e.target.value
                      return this.setState({ form: next })
                    }}
                    placeholder="판매중지/판매중지불가에 대한 일괄 메시지 전달을 위한 항목입니다.(없다면 기본 시스템 메시지가 전달됩니다.)"
                    style={{ height: '80px' }}
                  />
                </div>
              )
              : null}

            <div style={{ padding: '1rem 0', textAlign: 'right' }}>
              <a
                href="#downloadExcelSample"
                style={{ textDecoration: 'none', color: '#666' }}
                onClick={async (e) => {
                  return [e.stopPropagation(), e.preventDefault(), this.downloadSample()]
                }}
              >
                📥 양식 다운로드
              </a>
            </div>

            <div style={{ display: 'flex', padding: '1rem 0' }}>
              <div style={{ position: 'relative', boxSizing: 'border-box', flex: '1 1 100%', minWidth: 0, maxWidth: '100%', padding: '0.5rem' }}>
                <div><small style={{ color: '#929292' }}>총 도서종수</small></div>
                <div style={{ textAlign: 'right' }}><strong style={{ fontWeight: '900' }}>{comma(meta.bookCount)}</strong></div>
              </div>
              <div style={{ position: 'relative', boxSizing: 'border-box', flex: '1 1 100%', minWidth: 0, maxWidth: '100%', padding: '0.5rem' }}>
                <div><small style={{ color: '#929292' }}>유통사 ISBN 업데이트</small></div>
                <div style={{ textAlign: 'right' }}><strong style={{ fontWeight: '900' }}>{comma(meta.retailIsbnUpdatedIdentityCount)}</strong></div>
              </div>
            </div>

            <div style={{ display: 'flex', padding: '1rem 0' }}>
              <div style={{ position: 'relative', boxSizing: 'border-box', flex: '1 1 100%', minWidth: 0, maxWidth: '100%', padding: '0.5rem' }}>
                <div><small style={{ color: '#929292' }}>판매관련</small></div>
                <div style={{ textAlign: 'right' }}><strong style={{ fontWeight: '900' }}>{meta.bookRetailCount}</strong></div>
              </div>
              <div style={{ position: 'relative', boxSizing: 'border-box', flex: '1 1 100%', minWidth: 0, maxWidth: '100%', padding: '0.5rem' }}>
                <div><small style={{ color: '#929292' }}>중지관련</small></div>
                <div style={{ textAlign: 'right' }}><strong style={{ fontWeight: '900' }}>{meta.bookStopRetailCount}</strong></div>
              </div>
            </div>

          <div style={{ display: 'flex', padding: '1rem 0' }}>
            <div style={{ position: 'relative', boxSizing: 'border-box', flex: '1 1 100%', minWidth: 0, maxWidth: '100%', padding: '0.5rem' }}>
              <div><small style={{ color: '#929292' }}>판매</small></div>
              <div style={{ textAlign: 'right' }}><strong style={{ fontWeight: '900' }}>{meta.bookRetailOkCount}</strong></div>
            </div>
            <div style={{ position: 'relative', boxSizing: 'border-box', flex: '1 1 100%', minWidth: 0, maxWidth: '100%', padding: '0.5rem' }}>
              <div><small style={{ color: '#929292' }}>중지</small></div>
              <div style={{ textAlign: 'right' }}><strong style={{ fontWeight: '900' }}>{meta.bookStopRetailOkCount}</strong></div>
            </div>
          </div>

          <div style={{ display: 'flex', padding: '1rem 0' }}>
            <div style={{ position: 'relative', boxSizing: 'border-box', flex: '1 1 100%', minWidth: 0, maxWidth: '100%', padding: '0.5rem' }}>
              <div><small style={{ color: '#929292' }}>판매불가</small></div>
              <div style={{ textAlign: 'right' }}><strong style={{ fontWeight: '900' }}>{meta.bookRetailNoCount}</strong></div>
            </div>
            <div style={{ position: 'relative', boxSizing: 'border-box', flex: '1 1 100%', minWidth: 0, maxWidth: '100%', padding: '0.5rem' }}>
              <div><small style={{ color: '#929292' }}>중지불가</small></div>
              <div style={{ textAlign: 'right' }}><strong style={{ fontWeight: '900' }}>{meta.bookStopRetailNoCount}</strong></div>
            </div>
          </div>

            <div style={{ marginBottom: '0.75rem' }}>
              <small>
                ❓ 도서종수로 취합되며, 중복인경우 <strong>한번만 진행</strong>됩니다. 이미 상태가 전환된 경우 반영되지 않지만 <strong>외부유통사 ISBN번호는 덮어쓰기 되며 업데이트</strong> 됩니다.
              </small>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'middle' }}>
              <button
                type="button"
                style={{ width: '48%' }}
                onClick={async (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  return await this.uploadExcelFile()
                }}
              >
                파일선택
              </button>
              <button
                type="button"
                className="primary"
                style={{ width: '48%' }}
                onClick={async (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  return await this.doExecuted()
                }}
              >
                최종반영
              </button>
            </div>

          </Form.Body>
        </Form>
      </Modal>
    )
  }
}

export default BookRetailStatusExcelModalContainer