import React from 'react'

import styled, { css } from 'styled-components'
import { font, palette } from 'styled-theme'

import _ from 'lodash'
import api from 'services/api'
import qs from 'query-string'

import moment from 'services/moment'
import { comma } from 'services/utils'

import { Importer, Exporter } from 'services/transform'

import Modal from 'components/utils/Modal'

const controlStyles = css`
  position: relative; box-sizing: border-box;
  padding: 0.5rem 0.5rem;

  & div.header {
    position: relative; box-sizing: border-box;
    padding: 1rem;
    & small { color: ${palette('muted', 8)}; }
  }

  & > * { flex: 1 1 100%; }
  & input[type=text],
  & input[type=number],
  & input[type=time],
  & input[type=date] {
    position: relative; box-sizing: border-box;
    font-size: 1em; border-radius: 3px;
    width: 100%; border: 1px solid #e1e2e3; outline: none;
    font-family: ${font('primary')};
    padding: 0.5rem 0.75rem; transition: 0.3s all;
    &:hover { background: #fafafa; border: 1px solid #929394 }
  }
  & select {
    position: relative; box-sizing: border-box;
    font-size: 1em; border-radius: 3px;
    width: 100%; border: 1px solid #e1e2e3; outline: none;
    font-family: ${font('primary')};
    padding: 0.35rem 0.75rem; transition: 0.3s all;
    &:hover { background: #fafafa; border: 1px solid #929394 }
  }
  & textarea {
    position: relative; box-sizing: border-box;
    font-size: 1em; border-radius: 3px;
    font-family: ${font('primary')};
    line-height: 1.55rem; border: 1px solid #e1e2e3;
    width: 100%; border: 1px solid #e1e2e3; outline: none;
    padding: 0.35rem 0.75rem; transition: 0.3s all;
    &:hover { background: #fafafa; border: 1px solid #929394 }
  }
  & button {
    position: relative; box-sizing: border-box;
    font-size: 1.16em; border-radius: 3px;
    font-family: ${font('primary')}; font-weight: 700;
    white-space: nowrap;
    line-height: 1.55rem;
    width: max-content; outline: none;
    padding: 0.76rem 0.75rem; transition: 0.3s all;
    background: white; border: 1px solid #c1c2c3; color: #363636;
    &:hover { background: #f1f1f1; border: 1px solid #c1c2c3; }
    cursor: pointer;
    &.primary {
      background: #0FCBEB; border: 1px solid #0FCBEB; color: white;
      &:hover { background: #25E5EB; border: 1px solid #25E5EB; }
    }
    &.important {
      background: #000; border: 1px solid #333; color: white;
      &:hover { background: #333; border: 1px solid #000; }
    }
  }
`

// 폼 사이즈 구성
const Form = styled.article`
  position: relative; box-sizing: border-box;
  display: flex;
  transition: all 0.3s; padding: 0.5rem 0.5rem;
  ${controlStyles}
`

Form.Header = styled.header`
  position: relative; box-sizing: border-box;
  font-size: 1.43em; font-family: ${font('primary')};
  & strong { font-weight: 900; }
`

Form.Body = styled.main`
  position: relative; box-sizing: border-box;
  padding: 1rem 0.5rem;
  flex: 1 1 100%;
`

Form.Aside = styled.aside`
  position: relative; box-sizing: border-box;
  padding: 1rem 0.5rem;
  max-width: 360px; min-width: 360px; width: 360px;
`

Form.Lead = styled.header`
  position: relative; box-sizing: border-box;
`

Form.Progress = styled.div`
  position: relative; box-sizing: border-box;
`

Form.Buttons = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0;
`

Form.Progress = styled.div`
  position: relative; box-sizing: border-box;
  height: 15px; border-radius: 3px;
  background: #f1f1f1;
  margin-bottom: 1rem;
`

Form.ProgressBar = styled.div`
  position: relative; box-sizing: border-box;
  background: #111;
  height: 15px; border-radius: 3px;
`

class ProductRetailManageModalContainer extends React.Component {
  constructor(props) {
    super(props)

    this.initializedState = {
      loading: true, pending: false, error: false, message: '',
      progress: { rate: 0, current: 0, total: 0 },
      form: { step: 'upload', identity: 'isbn', retails: [], status: 'activated', approvalOption: 'strict' },
      count: 0, items: []
    }

    this.state = JSON.parse(JSON.stringify(this.initializedState))

    this.initialize = this.initialize.bind(this)
    this.doUploadFile = this.doUploadFile.bind(this)
    this.doAction = this.doAction.bind(this)
    this.doExecuteCommand = this.doExecuteCommand.bind(this)
    this.abortController = new AbortController()
  }

  componentDidMount() {
    return this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }
  
  async initialize() {
    await new Promise((r) => this.setState({ loading: false }, r))
  }

  // @ 파일 업로드 입력
  async doUploadFile() {
    const that = this

    // @ 파서정의 : 파서명, 컬럼설정
    const parser = { name: `ISBN 또는 도서번호 기반 처리 작업` }
    parser.columns = {}
    parser.columns['A'] = { name: 'identity', text: '구분자', parse: (cell) => { return cell ? `${cell}`.trim() : `` } }
    parser.columns['B'] = { name: 'message', text: '메시지', parse: (cell) => { return cell ? `${cell}`.trim() : `` } }
    
    const output = await new Importer({ parser }).open()
      .then(o => o.read(o.importedFile, 2))
      .catch(e => ({ items: [] }))
    if (!output.items || !output.items.length) { return alert(`해당 파일에서 데이터를 추출해내지 못했습니다.`) }

    // @ 불러온 데이터에서 빈값, 중복 정리
    const targets = _.uniqBy(output.items.filter(o => o.identity), 'identity')
    if (!targets.length) { return alert(`중복계정명, 빈값 제거후 체크한 결과 문제가 발생하였습니다.`) }

    const count = targets && targets.length
    if (!count) { return alert(`해당 파일에서 데이터를 추출해내지 못했습니다.`) }

    const next = { ...this.state.form }
    next.step = 'ready'
    that.setState({ form: next, count, items: targets })
  }

  // @ 개별 데이터 기준으로 처리
  async doAction(item, idx) {
    const { form } = this.state

    // @ 실제 서버에 전송해서 처리해주기
    const payload = {}
    payload.target = item
    payload.idx = idx
    payload.form = { ...form }

    // @ 실제 서버에 요청한 방식대로 처리하는 엔드포인트로 쏴주기
    const url = `/products/admin2/updateRetailStatusAll`
    const result = await api.put(url, payload)
      .catch((e) => {
        console.log(e.message, e.stack)
        return { error: true, message: `${e.message} ${e.stack}` }
      })
    if (result.error) { return { error: true, message: result.message } }    
    return { error: false, message: '정상적으로 처리하였습니다.' }
  }

  // @ 전체 데이터 입력
  async doExecuteCommand() {
    const that = this
    const { form, count, items, pending, progress } = this.state
    if (pending) { return alert('이미 작업이 진행중 입니다. 잠시만 기다려주세요.') }
    if (!count) { return alert('진행할 데이터 수가 적거나 없습니다.') }
    if (!items.length) { return alert('진행할 데이터가 없습니다.') }

    // @ form 검사
    if (!form.retails.length) { return alert('유통사를 반드시 1곳 이상을 설정해주셔야 합니다.') }

    // @ pending 걸고 진행해야 중복사고를 막을 수 있다.
    that.setState({ pending: true })

    // @ 작업 시작 전에 작업물 저장
    await api.post('/logs/admin2/registe-logs', {
      category: 'product-retail', name: '다중 입퇴점 관리',
      jobBefore: { form, items, count },
      jobAfter: {},
      message: '다중 입퇴점 작업 진행 로그 기록'
    })
    .catch((e) => console.log(e.message, e.stack))

    // @ 작업 시작
    const output = await items.reduce((prev, item, idx) => 
      prev.then(async (report) => {
        // @ 액션처리 구간 배치
        const result = await that.doAction(item, idx)
          .catch((e) => {
            console.log(e.message, e.stack)
            return { error: true, message: `${e.message} ${e.stack}` }
          })

        // @ 프로그레스 표현해주기
        const progress = { ...report.progress }
        progress.current = idx + 1
        progress.rate = (progress.current && progress.total) ? progress.current/progress.total : 0
        that.setState({ progress })
        report.progress = progress

        // @ 리포팅 해주기
        if (!result.error) { report.success.push(item) }
        if (result.error) { report.failure.push(item) }

        return report
      }), 
      Promise.resolve({ error: false, message: '정상적으로 처리하였습니다.', success: [], failure: [], progress: { rate: 0, current: 0, total: count } }))
    .catch(e => {
      console.log(e.message, e.stack)
      return { error: true, message: `${e.message} ${e.stack}`, success: [], failure: [] }
    })
    
    // @ 최종 작업완료를 알리는 시기
    that.setState({ pending: false })
    // console.log('output', output)

    return alert(output.message)
  }

  // 랜더링
  render() {
    const { doUploadFile, doExecuteCommand } = this
    const { loading, error, message, form } = this.state
    if (loading) { return null }
    if (error) {
      console.log(`Modal Render Error: ${message}`)
      return null
    }

    // @ 모달 프로퍼티 설정 구간, 닫기 버튼에 대한 컴포넌트 라이프 사이클에 대해서만 상위에서 관장한다.
    const modalProps = {}
    modalProps.isOpen = true
    modalProps.onClose = this.props.onClose ? this.props.onClose : (async () => { console.log(`has not set onClose Event Handler.`) })

    return (
      <Modal {...modalProps}>
        <Form style={{ maxWidth: '1460px', minWidth: '1460px', width: '1460px' }}>
          <Form.Aside>
            <Form.Header style={{ marginBottom: '1rem' }}>
              <strong>다중입점/퇴점관리</strong>
            </Form.Header>
          </Form.Aside>
          <Form.Body>
            
            <div style={{ marginBottom: '1rem' }}>
              <strong>업로드 옵션 결정</strong>
            </div>

            <div style={{ marginBottom: '1rem' }}>
              <select
                value={form.identity}
                onChange={(e) => {
                  const value = e.target.value
                  const next = { ...form }
                  next.identity = value
                  this.setState({ form: next })
                }}
              >
                <option value="bookNo">도서번호를 기준으로 도서상품 식별하기</option>
                <option value="isbn">ISBN 값을 기준 도서상품 식별하기</option>
              </select>
            </div>
            
            {form.step === 'upload'
              ? (
                <Form.Buttons>
                  <button
                    className="important"
                    style={{ width: '260px', marginRight: '0.5rem' }}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      if (!window.confirm('XLSX파일(A열 구분자, B열 메시지)가 준비되어 있어야 합니다. 진행할까요?')) { return }
                      return doUploadFile()
                    }}
                  >
                    업로드
                  </button>
                </Form.Buttons>
              )
              : null}
              
            <details>
              <summary style={{ margin: '1rem', cursor: 'pointer' }}>
                작업설명서
              </summary>
              <div style={{ background: '#f1f1f1', padding: '1rem', marginBottom: '1rem', borderRadius: '0.3618rem' }}>
                1. A열은 구분자값(ISBN 또는 도서번호), B열은 남길 메시지를 작성해주세요.<br/>
                2. 메시지를 작성하지 않았다면 문서에 기본 시스템 멘션이 남겨집니다.<br/>
                3. 엑셀의 1열(머릿말 행)은 비워주세요.
              </div>
              <div style={{ background: '#f1f1f1', padding: '1rem', marginBottom: '1rem', borderRadius: '0.3618rem' }}>
                상태값이 결정되어 업데이트 되면, 해당 도서의 유통상태도 판매중 또는 판매완료로 전환됩니다.<br/>
                판매중의 경우 현재시점에 지정되어야 할 인세액이 반영됩니다. 판매중지시 인세 값은 유지됩니다.<br/>
                유효한 문서가 있다면 종결처리 됩니다. 문서가 없다면 만들어진 이후 종결처리 작업을 추가작업 합니다.<br/>
                문서의 유효는 신청중, 작업중, 진행중 단위의 문서건이며 삭제되지 않은 종결문서건을 의미 합니다.<br/>
                문서승인종결문서의 경우 브런치저자 활동작가로 티커 추가되는 구간을 하게 됩니다.<br/>
                입점완료시 Yes24 유통인경우의 종이도서의 경우 브런치에 통지되는 도서로 재분류 됩니다.<br/>
                판매중단문서의 종결처리인경우 판매중문서(유효)가 소각되며, 반대로 판매중문서의 종결처리이면 판매종료(유효) 문서가 소각 됩니다.<br/>
              </div>
              <div style={{ background: '#f1f1f1', padding: '1rem', marginBottom: '1rem', borderRadius: '0.3618rem' }}>
                기존인세가 이미 박혀있는 상태라면 박혀있는 값도 현재 정책값의 비율에 의해 업데이트 됩니다.<br/>
                문서를 만들지 않고 반영할 수 있지만 문서가 없다면 어떠한 경위 기준으로 인세 또는 판매상태가 변화되었는지 확인/기록 할 수 없게 됩니다.<br/>
                도서의 ISBN 또는 도서번호에 띄어쓰기 또는 일어 띄어쓰기 등 특문이 있는지 확인해주세요. 둘다 숫자여야 합니다.
              </div>
            </details>

            {form.step === 'ready'
              ? (
                <>
                  <div style={{ marginBottom: '1rem' }}>
                    <strong>총 {form.retails.length}개 유통사</strong>
                  </div>
                  <div style={{ marginBottom: '1rem' }}>
                    {[
                      { name: 'yes24', text: 'Yes24' },
                      { name: 'aladin', text: '알라딘' },
                      { name: 'kyobo', text: '교보문고' },
                      { name: 'ypmungo', text: '영풍문고' },
                      { name: 'bookxen', text: '북센' },
                      { name: 'millie', text: '밀리의서재' },
                      { name: 'bookcube', text: '북큐브' },
                      { name: 'ridibooks', text: '리디북스' },
                      { name: 'sconn', text: '스콘' },
                    ]
                    .map((elem, eIdx) => {
                      const isActived = form.retails.includes(elem.name) ? true : false
                      return (
                        <button
                          key={`retail-button-${eIdx}`}
                          className={isActived ? "primary" : ""}
                          type="button"
                          style={{ width: '260px', marginRight: '0.5rem', marginBottom: '0.5rem' }}
                          value={form.identity}
                          onClick={(e) => {
                            const value = elem.name
                            const next = { ...form }
                            if (next.retails.includes(value)) {
                              next.retails = next.retails.filter(retail => value !== retail)
                            } else {
                              next.retails.push(value)
                            }
                            this.setState({ form: next })
                          }}
                        >
                          <span>{elem.text}</span>
                        </button>
                      )
                    })}
                    
                  </div>
                  <div style={{ marginBottom: '1rem' }}>
                    <strong>작업옵션 결정</strong>
                  </div>
      
                  <div style={{ marginBottom: '1rem' }}>
                    <select
                      value={form.status}
                      onChange={(e) => {
                        const value = e.target.value
                        const next = { ...form }
                        next.status = value
                        this.setState({ form: next })
                      }}
                    >
                      <option value="activated">🟢 판매중(입점) 작업</option>
                      <option value="activated-reject">🔴 판매반려(입점반려) 작업</option>
                      <option value="deactivated">🟢 판매중지(퇴점) 작업</option>
                      <option value="deactivated-reject">🔴 판매중지반려(입점반려) 작업</option>
                    </select>
                  </div>
                  <div style={{ marginBottom: '1rem' }}>
                    <select
                      value={form.approvalOption}
                      onChange={(e) => {
                        const value = e.target.value
                        const next = { ...form }
                        next.approvalOption = value
                        this.setState({ form: next })
                      }}
                    >
                      <option value="flexible">⚪ [A] 유효문서 만들기(제출됨 문서 생성, 기존 유효문서 있다면 유지)</option>
                      <option value="strict">🟠 [B] 유효문서(제출됨,신청중 상태) 존재하는 도서만 처리하기</option>
                      <option value="flexible-to-end">🟢 [A+B] 유효문서 없으면 만들면서 적용하기(처리완료 또는 반려상태의 종결상태)</option>
                    </select>
                  </div>
      
                  <div style={{ marginBottom: '1rem' }}>
                    <strong>작업진행 현황</strong>
                  </div>
      
                  <div style={{ marginBottom: '1rem' }}>
                    <div style={{ fontSize: '0.9em', marginBottom: '0.5rem' }}>인식된 데이터 <strong>{_.get(this, 'state.count')}</strong>건</div>
                    <div style={{ fontSize: '1.2618em', marginBottom: '1rem' }}>
                      총 <strong>{_.get(this, 'state.progress.total')}</strong>건 중 <strong>{_.get(this, 'state.progress.current')}</strong>번째
                    </div>
                  </div>
      
                  <Form.Progress>
                    <Form.ProgressBar style={{ width: `${(_.get(this, 'state.progress.rate') || 0) * 100}%` }} />
                  </Form.Progress>

                  {_.get(this, 'state.progress.rate') === 1 ? (<div style={{ marginBottom: '1rem' }}>작업이 완료되었습니다. 🎉</div>) : null}

                  <Form.Buttons>
                    {form.step === 'ready'
                      ? (
                        <button
                          className="important"
                          style={{ width: '260px', marginRight: '0.5rem' }}
                          onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            if (!window.confirm(`총 ${_.get(this, 'state.count')}건의 데이터를 처리할까요?`)) { return }
                            return doExecuteCommand()
                          }}
                        >
                          진행하기
                        </button>
                      )
                      : null}
      
                    <button
                      style={{ width: '260px' }}
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        return modalProps.onClose()
                      }}
                    >
                      닫기
                  </button>
                  </Form.Buttons>
                </>
              )
              : null}

          </Form.Body>
        </Form>
      </Modal>
    )
  }
}

export default ProductRetailManageModalContainer
