import _ from 'lodash'
import moment from 'services/moment'

const parser = {}

const columns = []

columns.push({ header:  '순번', key: 'no', view: (item, idx) => `${idx+1}` })

columns.push({
  header:  '종류', key: 'bookType',
  view: (item) => {
    const type = _.get(item, 'orderType') || {}
    if (['paperBook'].includes(type)) { return '종이도서' }
    if (['electronicBook'].includes(type)) { return '전자도서' }
    return `알수없음`
  }
})

columns.push({
  header:  '도서번호', key: 'bookNo',
  view: (item) => {
    const product = _.get(item, 'extras.product') || {}
    const book = _.get(product, 'content') || {}
    const value = _.get(book, 'bookNo') || ''
    return value
  }
})

columns.push({
  header:  '도서명', key: 'bookTitle',
  view: (item) => {
    const product = _.get(item, 'extras.product') || {}
    const book = _.get(product, 'content') || {}
    const value = `${_.get(book, 'title') || '도서명 없음'} (${_.get(book, 'author') || '저자명 없음'})`
    return value
  }
})

columns.push({
  header:  '수량', key: 'orderAmount',
  view: (item) => {
    const value = _.get(item, 'amount') || 0
    return value
  }
})

columns.push({
  header:  '단가', key: 'eachPrice',
  view: (item) => {
    const value = _.get(item, 'eachPrice') || 0
    return value
  }
})

columns.push({
  header:  '금액', key: 'total',
  view: (item) => {
    const value = _.get(item, 'totalPrice') || 0
    return value
  }
})

columns.push({
  header:  '비고', key: 'blank', view: (item) => ``
})

parser.columns = columns

export default parser
