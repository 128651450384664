import React from 'react'

import api from 'services/api'
import _ from 'lodash'
import moment from 'services/moment'

import ElementList  from './ElementList'
import VoterList  from './VoterList'

import { Form } from './utils'

const { REACT_APP_CLIENT_URL } = process.env

// @ 서베이 관리를 위한 창
class ActivitySurveyDetailContainer extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      loading: true, pending: false, error: false, message: '',
      item: {}, form: { elements: [] }
    }

    this.initialize = this.initialize.bind(this)
    this.doSave = this.doSave.bind(this)
    this.doDelete = this.doDelete.bind(this)

    this.abortController = new AbortController()
  }

  componentDidMount() {
    return this.initialize()
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  async initialize() {
    const { surveyId } = this.props
    const { error, message, item } = await api.get(`/surveys/admin2/${surveyId}/details`, { signal: this.abortController.signal })
      .catch((e) => ({ error: true, message: `데이터를 불러오지 못했습니다.` }))
    if (error) {
      const payload = { loading: false, item, form: {}, error, message }
      await new Promise((r) => this.setState(payload, r))
    }
    
    const form = { ...this.state.form }
    form.classify = item.classify
    form.title = item.title
    form.memo = item.memo
    form.voterLimit = item.voterLimit
    form.selectLimit = item.selectLimit
    form.status = item.status
    form.usedMessage = item.usedMessage ? true : false
    form.startAt = item.startAt ? moment(item.startAt).toDate() : null
    form.endAt = item.endAt ? moment(item.endAt).toDate() : null
    form.elements = item.elements || []

    const payload = { loading: false, item, form, error: false, message: '정상적으로 불러왔습니다.' }
    await new Promise((r) => this.setState(payload, r))
  }

  // @ 서베이 정보 업데이트
  async doSave() {
    const { surveyId } = this.props
    const { pending, form } = this.state
    if (pending) { return alert('진행중 입니다. 잠시만 기다려주세요.') }

    // @ 간단한 폼 검사
    if (!form.title) { return alert('서베이 구분을 위해 명칭을 올바르게 명명하여 기재해주세요.') }
    if (form.voterLimit < 1) { return alert('참여자수가 음수는 지원하지 않습니다.') }
    if (form.selectLimit < 1) { return alert('최소 1개의 선택지를 입력할 수 있도록 해주세요.') }
    if (form.startAt >= form.endAt) { return alert('시작일은 종료일보다 클 수 없습니다.') }

    // @ 최종 질의
    if (!window.confirm(`해당 내용으로 서베이를 업데이트 할까요?`)) { return }
    
    // @ 진행전 막기
    this.setState({ pending: true })

    const payload = { form }
    const output = await api.put(`/surveys/admin2/${surveyId}`, payload)
      .catch(e => {
        console.log(e.message, e.stack)
        return { error: true, message: '서베이 업데이트를 할 수 없었습니다.' }
      })
    alert(output.message)
    
    this.setState({ pending: false })
    if (output.error) { return }

    return this.initialize()
  }

  // @ 서베이 삭제 기능
  async doDelete() {
    const { surveyId } = this.props
    const { pending,  form } = this.state
    if (pending) { return alert('진행중 입니다. 잠시만 기다려주세요.') }

    if (!window.confirm(`해당 서베이를 삭제할까요?`)) { return }

    // @ 진행전 막기
    this.setState({ pending: true })
    const output = await api.delete(`/surveys/admin2/${surveyId}`)
      .catch(e => {
        console.log(e.message, e.stack)
        return { error: true, message: '서베이를 삭제할 수 없었습니다.' }
      })
    alert(output.message)
    
    this.setState({ pending: false })
    if (output.error) { return }

    return this.initialize()
  }

  render() {
    const { initialize, doSave, doDelete } = this

    const { loading, error, message, item, form } = this.state

    if (loading) { return null }
    if (error) { return <div>삭제되거나 없는 서베이 입니다.</div> }
    if (item.deleted) { return <div>삭제된 서베이 입니다.</div> }

    const elementProps = {}
    elementProps.survey = item
    elementProps.form = form
    elementProps.parent = { setState: (...props) => this.setState(...props), initialize }

    const voterProps = {}
    voterProps.surveyId = item._id
    voterProps.survey = item
    voterProps.form = form
    voterProps.parent = { setState: (...props) => this.setState(...props), initialize }

    return (
        <Form>
          <Form.Header>
            서베이 관리
          </Form.Header>
          <Form.Content>
            <ElementList {...elementProps} />
            <VoterList {...voterProps} />
          </Form.Content>

          <Form.Aside>
          
            {/* 서베이명 및 기본정보 설정 */}
            <div className="header">기본정보</div>
            <div className="control">
              <input
                type="text"
                placeholder="서베이 명칭"
                value={form.title || ''}
                onChange={(e) => {
                  const value = e.target.value ? e.target.value.trim() : ''
                  const next = { ...form, title: value }
                  return this.setState({ form: next })
                }}
              />
            </div>
            <div className="control">
              <select
                defaultValue={form.classify || ''}
                disabled
              >
                <option value="book">도서투표</option>
                <option value="user">유저투표</option>
                <option value="etc">기타투표</option>
              </select>
            </div>
            
            <div className="control">
              <select
                value={form.status || ''}
                onChange={(e) => {
                  const value = e.target.value
                  const next = { ...form, status: value }
                  return this.setState({ form: next })
                }}
                disabled={form.status === 'deactivated'}
              >
                <option value="activated">🟢 진행중</option>
                <option value="deactivated">🔴 마감</option>
              </select>
            </div>

            <div className="header">최대참여자수 · 1계정당 투표가능수</div>
            <div className="control">
              <input
                type="number"
                placeholder="최대 참여자수(0 또는 미기재 제한없음)"
                value={form.voterLimit || 0}
                onChange={(e) => {
                  let value = (e.target.value && !_.isNaN(e.target.value * 1))
                    ? e.target.value * 1
                    : 0
                  value = value < 0 ? 0 : value
                  if (!_.isInteger(value)) { value = parseInt(value) }
                  const next = { ...form, voterLimit: value }
                  return this.setState({ form: next })
                }}
              />
            </div>
            <div className="control">
              <input
                type="number"
                placeholder="1투표당 선택수"
                value={form.selectLimit || ''}
                onChange={(e) => {
                  let value = (e.target.value && !_.isNaN(e.target.value * 1))
                    ? e.target.value * 1
                    : 0
                  value = value < 0 ? 0 : value
                  if (!_.isInteger(value)) { value = parseInt(value) }
                  const next = { ...form, selectLimit: value }
                  return this.setState({ form: next })
                }}
              />
            </div>

            <div className="control">
              <select
                value={form.usedMessage ? 'true' : 'false'}
                onChange={(e) => {
                  const value = e.target.value === 'true' ? true : false
                  const next = { ...form, usedMessage: value }
                  return this.setState({ form: next })
                }}
              >
                <option value="true">🟢 주관식 답변 받기</option>
                <option value="false">🔴 주관식 답변 안받기</option>
              </select>
            </div>

            {/* 시작일 설정 */}
            <div className="header">시작일</div>
            <div className="control">
              <input
                type="date"
                pattern="\d{4}-\d{2}-\d{2}"
                value={form.startAt ? moment(form.startAt).format('YYYY-MM-DD') : ''}
                onChange={(e) => {
                  const curAt = new Date()
                  const prevAt = form.startAt
                    ? new Date(form.startAt)
                    : new Date(curAt.getFullYear(), curAt.getMonth(), curAt.getDate(), 12, 0, 0, 0)
                  const prevTime = [prevAt.getHours(), prevAt.getMinutes(), prevAt.getSeconds()]
                  const value = e.target.value || '0000-00-00'
                  const [year, month, day] = value.split('-')
                  const at = new Date(year, month * 1 ? (month * 1 - 1) : null, day, ...prevTime)
                  const next = { ...form, startAt: at.toJSON() }
                  return this.setState({ form: next })
                }}
              />
            </div>
            <div className="control">
              <input
                type="time"
                pattern="\d{2}:\d{2}:\d{2}"
                value={form.startAt ? moment(form.startAt).format('HH:mm:ss') : ''}
                onChange={(e) => {
                  const curAt = new Date()
                  const prevAt = form.startAt
                    ? new Date(form.startAt)
                    : new Date(curAt.getFullYear(), curAt.getMonth(), curAt.getDate(), 12, 0, 0, 0)
                  const prevDay = [prevAt.getFullYear(), prevAt.getMonth(), prevAt.getDate()]
                  const value = e.target.value || '00:00:00'
                  const at = new Date(...prevDay, ...value.split(':'))
                  const next = { ...form, startAt: at.toJSON() }
                  return this.setState({ form: next })
                }}
              />
            </div>
            
            {/* 종료일 설정 */}
            <div className="header">종료일</div>
            <div className="control">
              <input
                type="date"
                pattern="\d{4}-\d{2}-\d{2}"
                value={form.endAt ? moment(form.endAt).format('YYYY-MM-DD') : ''}
                onChange={(e) => {
                  const curAt = new Date()
                  const prevAt = form.endAt
                    ? new Date(form.endAt)
                    : new Date(curAt.getFullYear(), curAt.getMonth(), curAt.getDate(), 12, 0, 0, 0)
                  const prevTime = [prevAt.getHours(), prevAt.getMinutes(), prevAt.getSeconds()]
                  const value = e.target.value || '0000-00-00'
                  const [year, month, day] = value.split('-')
                  const at = new Date(year, month * 1 ? (month * 1 - 1) : null, day, ...prevTime)
                  const next = { ...form, endAt: at.toJSON() }
                  return this.setState({ form: next })
                }}
              />
            </div>
            <div className="control">
              <input
                type="time"
                pattern="\d{2}:\d{2}:\d{2}"
                value={form.endAt ? moment(form.endAt).format('HH:mm:ss') : ''}
                onChange={(e) => {
                  const curAt = new Date()
                  const prevAt = form.endAt
                    ? new Date(form.endAt)
                    : new Date(curAt.getFullYear(), curAt.getMonth(), curAt.getDate(), 12, 0, 0, 0)
                  const prevDay = [prevAt.getFullYear(), prevAt.getMonth(), prevAt.getDate()]
                  const value = e.target.value || '00:00:00'
                  const at = new Date(...prevDay, ...value.split(':'))
                  const next = { ...form, endAt: at.toJSON() }
                  return this.setState({ form: next })
                }}
              />
            </div>
            
            {/* 메모 설정 */}
            <div className="header">메모</div>
            <div className="control">
              <textarea
                placeholder="관리상 메모를 남겨주세요. 일반에 보일 수도 있으니 참고적으로만 작성합시다."
                style={{ height: '90px' }}
                value={form.memo}
                onChange={e => {
                  const next = { ...form, memo: (e.target.value || '').trim() }
                  return this.setState({ form: next })
                }}
              />
            </div>

            <div style={{ padding: '1rem', lineHeight: '1.8em', color: '#666' }}>
              <strong style={{ color: '#111' }}>숙지사항</strong><br />

              <details>
                <summary style={{ cursor: 'pointer' }}>본문에서 적절한 위치에 위젯코드를 삽입해 사용해주세요.</summary>
                <div style={{ padding: '1rem 0' }}>
                  1. <strong style={{ color: '#111' }}>알림통지</strong> : 우승자 선정 처리 시 때 마다 알람이 통지 됩니다. 실수로 통지한 경우 활동 알림에서 삭제해주세요.<br />
                  2. <strong style={{ color: '#111' }}>서베이 제목</strong> : 기본정보의 서베이 명칭은 선정자 알림이 통지가 될 때, 서베이의 제목으로 전달되니 명칭을 의미있게 작성해주세요. 게시물에 삽입될 때는 화면에 제목이 노출되지는 않습니다.<br/>
                  3. <strong style={{ color: '#111' }}>단독URL</strong> : 미리보기에 나오는 URL은 알림 통지하고 알림을 누르면 해당 게시글로 가는 것이 아니라 서베이 단독 페이지(미리보기)로 이동하게 됩니다.<br />
                  4. <strong style={{ color: '#111' }}>위젯코드</strong> : 본문에 나오는 위젯코드를 부크크 레터 본문에 삽입하면 자동변환되면서 서베이로 전환 됩니다. 한개의 부크크 레터 글에 여러개의 서베이를 연동할 순 있습니다. 단 같은 서베이는 여러번 달지 말아주세요.(참여시차 문제 발생)<br/>
                  5. <strong style={{ color: '#111' }}>주관식답변</strong> : 투표를 참여한 뒤 참여소감을 받을 수 있는 창이 뜹니다. 상황에 따라서 사용하시길 바랍니다.<br/>
                  6. <strong style={{ color: '#111' }}>시작일&종료일</strong> : 진행중 상태의 마감일보다 현재시간이 미래이면 자동으로 마감됨처리가 진행 됩니다. 마감됨 처리가 진행될 때 선정자를 고르는 것은 아니고, 관리자가 직접 지정하는 방식으로 운영됩니다.<br/>
                  7. <strong style={{ color: '#111' }}>지급사고방지</strong> : 만약 서베이 참여자 일부에게 선정자를 선정한 뒤 일정한 수익금을 배분하는 경우 엑셀을 다운로드 받아 수여할 계정을 뽑아 수익-수기관리에서 지급(부크크 기준으로 진행시 매월 부크크 정산조건 및 시기 기준으로 지급됨)하시면 됩니다.<br/>
                </div>
              </details>
            </div>

            <div className="buttons">
              <button
                type="button"
                onClick={async (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  return doDelete()
                }}
              >
                삭제
              </button>
              <button
                type="button"
                className="primary"
                onClick={async (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  return doSave()
                }}
              >
                최종저장
              </button>
            </div>

            <div className="buttons">
              <button
                type="button"
                onClick={async (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  try {
                    const text = `{@SurveyWidget:${item._id}}`
                    await navigator.clipboard.writeText(text)
                    alert(`서베이 코드가 복사 되었습니다. 게시물 원하는 위치에 한번만 넣어서 쓰세요.`)
                  } catch(e) {
                    const text = `{@SurveyWidget:${survey._id}}`
                    window.prompt('해당 코드를 복사하세요.', text)
                  }
                }}
              >
                🅱️ 위젯코드
              </button>
              <button
                type="button"
                onClick={async (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  return window.open(`${REACT_APP_CLIENT_URL}/community/letter?widget=survey&surveyId=${item._id}`)
                }}
              >
                👁️ 미리보기
              </button>
            </div>
          </Form.Aside>
        </Form>
    )
  }
}

export default ActivitySurveyDetailContainer
