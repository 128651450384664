// 장르
export const GENRES = {
  g1: { name: 'g1', text: '시·에세이' },
  g2: { name: 'g2', text: '시집', parent: 'g1' },
  g3: { name: 'g3', text: '에세이', parent: 'g1' },
  g4: { name: 'g4', text: '기타도서', parent: 'g1' },

  g5: { name: 'g5', text: '소설' },
  g6: { name: 'g6', text: '일반', parent: 'g5' },
  g7: { name: 'g7', text: '로멘스', parent: 'g5' },
  g8: { name: 'g8', text: '판타지', parent: 'g5' },
  g9: { name: 'g9', text: 'BL', parent: 'g5' },
  g10: { name: 'g10', text: '무협', parent: 'g5' },
  g11: { name: 'g11', text: '추리·스릴러·미스터리', parent: 'g5' },
  g12: { name: 'g12', text: '기타', parent: 'g5' },
  g13: { name: 'g13', text: 'SF소설', parent: 'g5' },

  g14: { name: 'g14', text: '전기·회고록' },
  g15: { name: 'g15', text: '자서전', parent: 'g14' },
  g16: { name: 'g16', text: '기타', parent: 'g14' },
  
  g17: { name: 'g17', text: '경영·경제·자기계발' },
  g18: { name: 'g18', text: '경영', parent: 'g17' },
  g19: { name: 'g19', text: '경제', parent: 'g17' },
  g20: { name: 'g20', text: '기타', parent: 'g17' },
  g36: { name: 'g36', text: '자기계발' },

  g21: { name: 'g21', text: '인문사회' },
  g22: { name: 'g22', text: '인문', parent: 'g21' },
  g23: { name: 'g23', text: '정치·사회', parent: 'g21' },
  g24: { name: 'g24', text: '역사', parent: 'g21' },
  g25: { name: 'g25', text: '종교', parent: 'g21' },
  g26: { name: 'g26', text: '예술·문화·기타', parent: 'g21' },
  g27: { name: 'g27', text: 'SF장르', parent: 'g21' },

  g28: { name: 'g28', text: '기타' },
  g29: { name: 'g29', text: '과학·IT', parent: 'g28' },
  g30: { name: 'g30', text: '어린이·청소년', parent: 'g28' },
  g31: { name: 'g31', text: '진학·진로', parent: 'g28' },
  g32: { name: 'g32', text: '여행', parent: 'g28' },
  g33: { name: 'g33', text: '가정·생활', parent: 'g28' },
  g34: { name: 'g34', text: '교재·참고서', parent: 'g28' },
  
  g35: { name: 'g35', text: '무료·체험판' }
}

// 출간도서의 종이 사이즈 및 정보
export const PAPER_SIZES = {
  p46: { name: 'p46', text: '46판', w: 127.0, h: 188.0, description: '일반도서 - 시, 에세이' },
  pa5: { name: 'pa5', text: 'A5', w: 148.0, h: 210.0, description: '일반도서 - 소설, 에세이' },
  pb5: { name: 'pb5', text: 'B5', w: 182.0, h: 257.0, description: '문제지, 잡지' },
  pa4: { name: 'pa4', text: 'A4', w: 210.0, h: 297.0, description: '문제지, 잡지' }
}

// 커버 재질
export const COVER_MATERIALS = {
  ARM210: { name: 'ARM210', text: '아르떼(감성적인)', description: '아르떼 210g, 무광코팅', used: true, coating: 'matt' }, // A:아르떼 R:감성적인(Romantic) M:무광(Matt)
  AGG210: { name: 'AGG210', text: '아르떼(광택있는)', description: '아르떼 210g, 유광코팅', used: false, coating: 'gloss' }, // A:아르떼 G:광택있는(Glossy) G:유광(Gloss)
  SPM250: { name: 'SPM250', text: '스노우(대중적인)', description: '스노우 250g, 무광코팅', used: true, coating: 'matt' }, // S:스노우 P:대중적인(Popular) M:무광(Matt)
  SGG250: { name: 'SGG250', text: '스노우(광택있는)', description: '스노우 250g, 유광코팅', used: true, coating: 'gloss' }, // S:스노우 G:광택있는(Glossy) G:유광(Gloss)
  RTN270: { name: 'RTN270', text: '르네상스(거친)', description: '르네상스 270g, 코팅없음', used: false, coating: 'none' }, // R:르네상스 T:거친(Tough) N:코팅없음(None)
}

// 표지에 새겨질 출판사 마크 색상
export const PUBLISHER_LOGOS = {
  black: { name: 'black', text: '검정', image: '/images/freeCovers/logo-horizontal-black.png' },
  red: { name: 'red', text: '빨강', image: '/images/freeCovers/logo-horizontal-red.png' },
  blue: { name: 'blue', text: '블루', image: '/images/freeCovers/logo-horizontal-blue.png' },
  white: { name: 'white', text: '화이트', image: '/images/freeCovers/logo-horizontal-white.png' },
}

// 도서 내지 사용 전략
export const COVER_STRATEGES = {
  free: { name: 'free', text: '무료 표지' },
  custom: { name: 'custom', text: '직접 올리기' },
  purchased: { name: 'purchased', text: '구매한 디자인' }
}

// 내지 재질
export const TEXT_MATERIALS = {
  white100g: { name: 'white100g', text: '백색모조지 100g' },
  white80g: { name: 'white80g', text: '백색모조지 80g' },
  yellow100g: { name: 'yellow100g', text: '미색모조지 100g' },
  yellow80g: { name: 'yellow80g', text: '미색모조지 80g' },
  elite80g: { name: 'elite80g', text: '이라이트 80g' },
}

// 내지 색상
export const TEXT_COLORS = {
  black: { name: 'black', text: '흑백', description: '흑백' },
  color: { name: 'color', text: '컬러', description: '컬러' },
}

// 무료 표지 디자인에 대한 스타일
export const FREE_COVER_STYELS = {
  picture: { name: 'picture', text: '사진형', image: '/images/freeCovers/book_design_cover_type1.png' },
  pattern: { name: 'pattern', text: '패턴형', image: '/images/freeCovers/book_design_cover_type2.png' },
  icon: { name: 'icon', text: '아이콘형', image: '/images/freeCovers/book_design_cover_type3.png' },
  part: { name: 'part', text: '3단형', image: '/images/freeCovers/book_design_cover_type4.png' },
  image: { name: 'image', text: '이미지형', image: '/images/freeCovers/book_design_cover_type5.png' },
  normal: { name: 'normal', text: '일반형', image: '/images/freeCovers/book_design_cover_type6.png' }
}

// 무료 표지 디자인에 해당하는 스킨
export const FREE_COVER_DESIGNS = [
  { style: 'icon', design: '21' },
  { style: 'icon', design: '22' },
  { style: 'icon', design: '23' },
  { style: 'icon', design: '24' },
  { style: 'icon', design: '25' },
  { style: 'icon', design: '33' },

  { style: 'image', design: '6' },
  { style: 'image', design: '7' },
  { style: 'image', design: '8' },
  { style: 'image', design: '9' },
  { style: 'image', design: '10' },

  { style: 'normal', design: '1' },
  { style: 'normal', design: '2' },
  { style: 'normal', design: '3' },
  { style: 'normal', design: '4' },
  { style: 'normal', design: '5' },
  { style: 'normal', design: '34' },

  { style: 'part', design: '26' },
  { style: 'part', design: '27' },
  { style: 'part', design: '29' },
  { style: 'part', design: '30' },
  { style: 'part', design: '31' },

  { style: 'pattern', design: '16' },
  { style: 'pattern', design: '17' },
  { style: 'pattern', design: '18' },
  { style: 'pattern', design: '19' },
  { style: 'pattern', design: '20' },
  { style: 'pattern', design: '35' },
  { style: 'pattern', design: '36' },
  { style: 'pattern', design: '37' },

  { style: 'picture', design: '11' },
  { style: 'picture', design: '12' },
  { style: 'picture', design: '13' },
  { style: 'picture', design: '14' },
  { style: 'picture', design: '15' },
  { style: 'picture', design: '32' }
]
