import _ from 'lodash'
import moment from 'services/moment'

const parser = {}

parser.willWriteWorksheet = async (worksheet) => {
  const lastRowIdx = worksheet.rowCount
  Array
    .from({ length: lastRowIdx })
    .forEach((r, rIdx) => {
      worksheet.getRow(rIdx + 1).font = { size: 8 }
      worksheet.getRow(rIdx + 1).height = 25
      worksheet.getRow(rIdx + 1).alignment = { horizontal: 'center', vertical: 'middle' }
    })

  worksheet.getRow(1).font = { size: 12, bold: true }
  worksheet.getRow(1).height = 30
}

parser.columns = [
  { 
    header:  '도서번호', key: 'rIdx',
    columnProps: { width: 15 },
    view: (product) => {
      const book = product.content || {}

      const meta = {}
      meta.bookNo = _.get(book, 'bookNo')
      
      return `${meta.bookNo}`
    }
  },
  { 
    header:  '도서명', key: 'userNo',
    columnProps: { width: 50 },
    view: (product) => {
      const book = product.content || {}

      const meta = {}
      meta.bookTitle = _.get(book, 'title')

      return `${meta.bookTitle}`
    }
  },
  { 
    header:  '', key: 'blank',
    columnProps: { width: 1 },
    view: (product) => {
      return ``
    }
  },
  { 
    header:  '', key: 'blank',
    columnProps: { width: 1 },
    view: (product) => {
      return ``
    }
  },
  { 
    header:  '본인구매', key: 'userEmail',
    columnProps: { width: 15 },
    view: (product) => {
      const book = product.content || {}

      const meta = {}
      meta.bookNo = _.get(book, 'bookNo')
      meta.bookType = _.get(book, 'type')
      meta.purposeName = _.get(book, 'purpose.name')
      meta.bookTextColorName = _.get(book, 'text.color.name')
      meta.usedDiscount = _.get(book, 'usedDiscount')

      meta.defaultPrice = _.get(book, 'defaultPrice')
      meta.basicPrice = _.get(book, 'basicPrice')
      meta.collectionPrice = _.get(book, 'collectionPrice')

      // @ 정가 : 소장가 또는 일반판매가
      meta.bookPrice = ['collection'].includes(meta.purposeName) ? meta.collectionPrice : meta.basicPrice

      if (['paperBook'].includes(meta.bookType)) {
        meta.selfPrice = _.get(book, 'usedDiscount')
          ? (meta.usedDiscount ? meta.defaultPrice : meta.bookPrice)
          : meta.bookPrice
        meta.selfPrice = parseInt(meta.selfPrice * (['color'].includes(meta.bookTextColorName) ? 0.85 : 0.65))
      } else {
        meta.selfPrice = parseInt(book.basicPrice * (['color'].includes(meta.bookTextColorName) ? 0.85 : 0.65))
      }


      return `${meta.selfPrice}`
    }
  },
  { 
    header:  '첫구매', key: 'firstPrice',
    columnProps: { width: 15 },
    view: (product) => {
      const book = product.content || {}

      const meta = {}
      meta.bookType = _.get(book, 'type')
      meta.purposeName = _.get(book, 'purpose.name')
      meta.bookTextColorName = _.get(book, 'text.color.name')
      meta.usedDiscount = _.get(book, 'usedDiscount')

      meta.defaultPrice = _.get(book, 'defaultPrice')
      meta.basicPrice = _.get(book, 'basicPrice')
      meta.collectionPrice = _.get(book, 'collectionPrice')
      
      // @ 정가에 소장가 표현, 2024.12.09
      meta.bookPrice = ['collection'].includes(meta.purposeName) ? meta.collectionPrice : meta.basicPrice

      // @ 소장용은 첫구매가 할인 정보 없음, 정가 컬럼의 값 기반으로 비율 때림
      if (meta.bookType === 'paperBook') {
        meta.firstPrice = ['collection'].includes(meta.purposeName)
          ? ''
          : parseInt(['color'].includes(meta.bookTextColorName) ? meta.bookPrice * 0.8 : meta.bookPrice * 0.6)
      } else {
        meta.firstPrice = parseInt(meta.bookPrice * 0.7)
      }

      return `${meta.firstPrice}`
    }
  },
  { 
    header:  '정가', key: 'bookPrice',
    columnProps: { width: 15 },
    view: (product) => {
      const book = product.content || {}

      const meta = {}
      meta.bookNo = _.get(book, 'bookNo')
      meta.bookType = _.get(book, 'type')
      meta.purposeName = _.get(book, 'purpose.name')
      meta.bookTextColorName = _.get(book, 'text.color.name')

      meta.defaultPrice = _.get(book, 'defaultPrice')
      meta.basicPrice = _.get(book, 'basicPrice')
      meta.collectionPrice = _.get(book, 'collectionPrice')
      
      // @ 정가에 소장가 표현, 2024.12.09
      meta.bookPrice = ['collection'].includes(meta.purposeName) ? meta.collectionPrice : meta.basicPrice

      return `${meta.bookPrice}`
    }
  },
  { 
    header:  '저자명', key: 'bookAuthor',
    columnProps: { width: 30 },
    view: (product) => {
      const book = product.content || {}

      const meta = {}
      meta.bookAuthor = _.get(book, 'author')

      return `${meta.bookAuthor}`
    }
  },
  { 
    header:  '작업내용', key: 'bookContent',
    columnProps: { width: 60 },
    view: (product) => {
      const book = product.content || {}
      // @ 종이도서인경우에만 판형, 표지, 내지, 페이지수를 작업내용에 표기한다.
      if (book.type === 'paperBook') {
        // 46판 / 표지 스노우 250g, 무광코팅, 날개있음 / 내지 백색모조지 100g, 192pg
        const meta = {}
        meta.bookSize = _.get(book, 'bookSize.text') || '정보없음'
        meta.coverMaterial = _.get(book, 'cover.material.description') || '정보없음'
        meta.coverFlap = _.get(book, 'cover.flap') ? '날개있음' : '날개없음'
        meta.textMaterial = _.get(book, 'text.material.text') || '정보없음'
        meta.pageCount = _.get(book, 'text.pageCount') || 0
        return [
          `${meta.bookSize}`,
          `표지 ${meta.coverMaterial}, ${meta.coverFlap}`,
          `내지 ${meta.textMaterial}, ${meta.pageCount}pg`,
        ].join(' / ')
      } else {
        return ``
      }
    }
  }
]

export default parser
