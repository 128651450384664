import styled from 'styled-components'
import { font, palette } from 'styled-theme'

/* @ 폼 구성 */
export const Form = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; justify-content: space-between;
`

Form.Header = styled.header`
  position: relative; box-sizing: border-box;
  padding: 2rem 1rem; font-weight: 900;
  font-size: 1.43em; font-family: ${font('primary')};
  max-width: 260px; min-width: 260px; width: 260px;
`

Form.Content = styled.main`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; min-width: 640px; max-width: 100%;
  padding: 1rem 1rem;
`

Form.Aside = styled.aside`
  position: relative; box-sizing: border-box;
  max-width: 460px; min-width: 460px; width: 460px;
  padding: 2rem 1rem;
  & > div.header {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 1rem; font-weight: 700; color: #111;
  }
  & > div.control {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 1rem;
    & > input {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 0.35rem;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.618rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & > select {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 0.35rem;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.618rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & > textarea {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 0.35rem;
      font-family: ${font('primary')};
      line-height: 1.55rem; border: 1px solid #e1e2e3;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
  }
  & > div.buttons {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0.5rem;
    display: flex; justify-content: space-between;
    & > button {
      position: relative; box-sizing: border-box;
      width: 100%; border: 0; outline: none; cursor: pointer;
      padding: 0.5rem 1rem; font-weight: 700; margin: 0.5rem;
      font-family: ${font('primary')};
      font-size: 1.02em; border-radius: 0.35rem;
      background: white; color: #111; transition: 0.3s all;
      &:hover { background: #f1f1f1; }
      &.primary {
        background: #111; color: white;
        &:hover { background: #222; }
      }
    }
  }
`
