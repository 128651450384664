import _ from 'lodash'
import moment from 'services/moment'

const parser = {}

parser.columns = [
  {
    header:  '도서번호',
    key: 'bookNo',
    view: (item) => _.get(item, 'content.bookNo')
  },
  {
    header:  '도서명',
    key: 'bookTitle',
    view: (item) => _.get(item, 'content.title')
  },
  {
    header:  '분류',
    key: 'bookGenre',
    view: (item) => _.get(item, 'content.genre.text')
  },
  {
    header:  '저자명',
    key: 'bookAuthor',
    view: (item) => _.get(item, 'content.author')
  },
  {
    header:  '출판사명',
    key: 'bookPublisher',
    view: (item) => _.get(item, 'content.publisher')
  },
  {
    header:  'ISBN',
    key: 'bookIsbn',
    view: (item) => _.get(item, 'content.isbn')
  },
  {
    header:  '책소개',
    key: 'bookDetail',
    view: (item) => _.get(item, 'content.detail')
  },
  {
    header:  '목차',
    key: 'bookIndex',
    view: (item) => _.get(item, 'content.index')
  },
  {
    header:  '작가소개',
    key: 'bookIntroduce',
    view: (item) => _.get(item, 'content.introduce')
  },
  {
    header:  '출판사서평',
    key: 'bookPublisherComment',
    view: (item) => _.get(item, 'content.publisherComment')
  },
  {
    header:  '정가',
    key: 'bookBasicPrice',
    view: (item) => _.get(item, 'content.basicPrice')
  },
  {
    header:  '판형정보',
    key: 'bookSize',
    view: (item) => {
      if (_.get(item, 'content.type') === 'electronicBook') { return `전자책` }
      return _.get(item, 'content.bookSize.text') || `기타`
    }
  },
  {
    header:  '성인물',
    key: 'bookAgeLimit',
    view: (item) => {
      return (_.get(item, 'content.ageLimit') && _.get(item, 'content.ageLimit') === 'adult')
        ? `Y`
        : ''
    }
  },
  {
    header:  '출판일',
    key: 'bookPublishedAt',
    view: (item) => {
      return _.get(item, 'content.publishedAt')
        ? moment(_.get(item, 'content.publishedAt')).format('YYYYMMDD')
        : ''
    }
  },
  {
    header:  '제본방식',
    key: 'bookCoverFlap',
    view: (item) => _.get(item, 'content.cover.flap') ? `날개있음` : `날개없음`
  },
  {
    header:  '코팅종류',
    key: 'bookCoating',
    view: (item) => {
      if (['gloss'].includes(_.get(item, 'content.cover.material.coating'))) { return `유광코팅` }
      return `무광코팅`
    }
  },
  {
    header:  '표지재질',
    key: 'bookCoverMaterial',
    view: (item) => {
      if (_.get(item, 'content.type') === 'electronicBook') { return `해당없음` }
      return _.get(item, 'content.cover.material.text')
    }
  },
  {
    header:  '내지재질',
    key: 'bookTextMaterial',
    view: (item) => {
      if (_.get(item, 'content.type') === 'electronicBook') { return `해당없음` }
      return _.get(item, 'content.text.material.text')
    }
  },
  {
    header:  '칼라/흑백',
    key: 'bookTextColor',
    view: (item) => {
      // 전자책은 내지 모두 컬러기 때문에 그대로 가져다 쓴다.
      return _.get(item, 'content.text.color.text')
    }
  },
  {
    header:  '페이지수',
    key: 'bookTextPageCount',
    view: (item) => {
      // 페이지수 구성 완료
      return _.get(item, 'content.text.pageCount')
        ? Number(_.get(item, 'content.text.pageCount'))
        : 0
    }
  },
  {
    header:  '부분칼라적용',
    key: 'bookTextSomeColor',
    view: (item) => ''
  },
  {
    header:  'KC마크여부',
    key: 'kcCerted',
    view: (item) => {
      return _.get(item, 'content.kcCerted')
        ? `Y`
        : `N`
    }
  },
  {
    header:  '교보ISBN',
    key: 'kyoboIsbn',
    view: (item) => {
      // 반드시 최신정보를 items에 붙여서 불러와줘야 실행이 가능함.
      const retailIsbns = _.get(item, 'content.retailIsbns') || []
      const retailIsbn = retailIsbns.find(retailIsbn => retailIsbn.name === 'kyobo')
      return retailIsbn && retailIsbn.name
        ? retailIsbn.isbn
        : ''
    }
  },
  {
    header:  '외부규격',
    key: 'bookExtraSize',
    view: (item) => {
      return _.get(item, 'content.extraSize')
        ? 'Y'
        : 'N'
    }
  },
  {
    header:  '유통사수',
    key: 'retails',
    view: (item) => { return _.get(item, 'retails.length') || 0 }
  },
  {
    header:  '외부유통판매중(1곳이상 조건의 부크크, 기타제외)',
    key: 'retails',
    view: (item) => {
      if ((_.get(item, 'retails') || []).includes('kyobo')) { return 'O' }
      if ((_.get(item, 'retails') || []).includes('yes24')) { return 'O' }
      if ((_.get(item, 'retails') || []).includes('aladin')) { return 'O' }
      if ((_.get(item, 'retails') || []).includes('bookxen')) { return 'O' }
      if ((_.get(item, 'retails') || []).includes('ypmungo')) { return 'O' }
      if ((_.get(item, 'retails') || []).includes('millie')) { return 'O' }
      if ((_.get(item, 'retails') || []).includes('ridibooks')) { return 'O' }
      if ((_.get(item, 'retails') || []).includes('bookcube')) { return 'O' }
      if ((_.get(item, 'retails') || []).includes('sconn')) { return 'O' }
      return 'X'
    }
  },
  {
    header:  '부크크',
    key: 'retails_bookk',
    view: (item) => { return (_.get(item, 'retails') || []).includes('bookk') ? 'Y' : 'N' }
  },
  {
    header:  '국립중앙도서관',
    key: 'retails_nlibrary',
    view: (item) => { return (_.get(item, 'retails') || []).includes('nlibrary') ? 'Y' : 'N' }
  },
  {
    header:  '교보문고',
    key: 'retails_kyobo',
    view: (item) => { return (_.get(item, 'retails') || []).includes('kyobo') ? 'Y' : 'N' }
  },
  {
    header:  'Yes24',
    key: 'retails_yes24',
    view: (item) => { return (_.get(item, 'retails') || []).includes('yes24') ? 'Y' : 'N' }
  },
  {
    header:  '알라딘',
    key: 'retails_aladin',
    view: (item) => { return (_.get(item, 'retails') || []).includes('aladin') ? 'Y' : 'N' }
  },
  {
    header:  '북센',
    key: 'retails_bookxen',
    view: (item) => { return (_.get(item, 'retails') || []).includes('bookxen') ? 'Y' : 'N' }
  },
  {
    header:  '영풍문고',
    key: 'retails_ypmungo',
    view: (item) => { return (_.get(item, 'retails') || []).includes('ypmungo') ? 'Y' : 'N' }
  },
  {
    header:  '밀리의서재',
    key: 'retails_millie',
    view: (item) => { return (_.get(item, 'retails') || []).includes('millie') ? 'Y' : 'N' }
  },
  {
    header:  '리디북스',
    key: 'retails_ridibooks',
    view: (item) => { return (_.get(item, 'retails') || []).includes('ridibooks') ? 'Y' : 'N' }
  },
  {
    header:  '리디북스',
    key: 'retails_bookcube',
    view: (item) => { return (_.get(item, 'retails') || []).includes('bookcube') ? 'Y' : 'N' }
  },
  {
    header:  '스콘',
    key: 'retails_sconn',
    view: (item) => { return (_.get(item, 'retails') || []).includes('sconn') ? 'Y' : 'N' }
  },
  {
    header:  '기타',
    key: 'retails_others',
    view: (item) => { return (_.get(item, 'retails') || []).includes('others') ? 'Y' : 'N' }
  },
  {
    header:  '도서생성일',
    key: 'createdAt',
    view: (item) => {
      const createdAt = _.get(item, 'createdAt')
      return createdAt
        ? moment(createdAt).format('YYYYMMDD')
        : ''
    }
  }
]

export default parser



