import styled from 'styled-components'
import { font, palette } from 'styled-theme'

export const Container = styled.div`
  position: relative; box-sizing: border-box;
  & > header {
    position: relative; box-sizing: border-box;
    padding: 1rem 0rem;
    display: flex;
    justify-content: space-between;
    & > div.lead {
      position: relative; box-sizing: border-box;
      font-size: 1.23em; font-family: ${font('primary')};
      & > strong { font-weight: 700; font-size: 0.9em; }
    }
    & > div.btns {
      position: relative; box-sizing: border-box;
      & > a {
        color: #111; transition: all 0.3s;
        &:hover { color: #666; }
      }
    }
  }
`

export const Search = styled.div`
  position: relative; box-sizing: border-box;
  padding: 1rem 0;
  & input[type=text],
  & input[type=number],
  & input[type=time],
  & input[type=date] {
    position: relative; box-sizing: border-box;
    font-size: 1em; border-radius: 3px;
    width: 100%; border: 1px solid #e1e2e3; outline: none;
    font-family: ${font('primary')};
    padding: 1rem 1.25rem; transition: 0.3s all;
    &:hover { background: #fafafa; border: 1px solid #929394 }
  }
`
