// 수동모달
import React from 'react'

import styled, { css } from 'styled-components'
import { font, palette } from 'styled-theme'

import api from 'services/api'
import qs from 'query-string'
import _ from 'lodash'

import moment from 'services/moment'
import { comma } from 'services/utils'
import { Importer, Exporter } from 'services/transform'
import { lettersToNumber } from 'services/utils'

import Modal from 'components/utils/Modal'

const Message = styled.details`
  position: relative; box-sizing: border-box;
  border-radius: 3px; background: ${palette('muted', 18)};
  color: ${palette('muted', 1)};
  margin: 1rem;
  & > summary {
    display: block;
    position: relative; box-sizing: border-box; padding: 1rem;
    border-radius: 3px; background: ${palette('muted', 18)};
    color: ${palette('muted', 1)}; cursor: pointer; font-size: 0.9em;
  }
  & > div {
    display: block;
    position: relative; box-sizing: border-box; padding: 1rem;
    border-radius: 3px; background: ${palette('muted', 18)};
    color: ${palette('muted', 1)}; font-size: 0.9em;
    & > div { position: relative; box-sizing: border-box; padding: 0.25rem;  font-size: 0.9em; }
  }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  padding: 0.5rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
    text-align: right;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  padding: 1rem;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

const controlStyles = css`
  & div.header {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0.5rem;
  & small { color: ${palette('muted', 8)}; }
  }
  & div.control {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0.5rem;
    display: flex;
    & > * { flex: 1 1 100%; }
    & input[type=text],
    & input[type=number],
    & input[type=time],
    & input[type=date] {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & select {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & textarea {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      font-family: ${font('primary')};
      line-height: 1.55rem; border: 1px solid #e1e2e3;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & button {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      font-family: ${font('primary')};
      line-height: 1.55rem; border: 1px solid #e1e2e3;
      width: 100%; outline: none;
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      background: #fafafa; border: 1px solid #929394; cursor: pointer;
      &:hover { background: #111; border: 1px solid #111; color: white; }
    }
  }
`

const buttonStyles = css`
  & div.buttons {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0; margin: 0 -0.5rem;
    display: flex; justify-content: space-between;
    & > button {
      position: relative; box-sizing: border-box;
      width: 100%; border: 0; outline: none; cursor: pointer;
      padding: 0.75rem 1rem; font-weight: 700; margin: 0.5rem;
      font-family: ${font('primary')};
      font-size: 1.02em; border-radius: 3px;
      background: ${palette('darkblue', 15)}; color: #fff; transition: 0.3s all;
      &:hover { background: ${palette('darkblue', 4)}; color: #fff; }
      &.primary {
        background: ${palette('darkblue', 5)};
        &:hover { background: ${palette('darkblue', 5)}; }
      }
    }
  }
`

// 폼 사이즈 구성
const Form = styled.div`
  position: relative; box-sizing: border-box;
  transition: all 0.3s;
 ${controlStyles}
 ${buttonStyles}
`

Form.Header = styled.header`
  position: relative; box-sizing: border-box;
  padding: 2rem 1rem; font-weight: 900;
  font-size: 1.43em; font-family: ${font('primary')};
`

Form.Body = styled.div`
  position: relative; box-sizing: border-box;
  padding: 1rem 1rem;
`

// 상위 컴포넌트가 생명주기를 관장하는 수동적 모달
class CreatePayUploadModalContainer extends React.Component {
  constructor(props) {
    super(props)

    this.initializedSetup = {}

    // 기본 형틀
    this.initializedItem = {}
    
    this.initializedState = {
      loading: true,
      setup: JSON.parse(JSON.stringify(this.initializedSetup)),

      payType: 'paperBook',
      passRetail: false,
      item: null,
      parcelCreatedMethod: 'mixed',
      supplyStatusAtPayUploadResolved: 'ready',
      profitCreateAtPayUploadResolved: 'notUsed',
      disabledProfit: 'notUsed',

      errors: [],
      panel: {
        totalCount: 0,// 엑셀파일상 담긴 데이터수
        successReadedCount: 0, // 정상 데이터수
        errorReadedCount: 0, // 불량 데이터수
        enabledPayCount: 0, // 주소지별이든, 종별이든 결제내역건(주문내역 묶음)이 생성되는 개수
        totalAmount: 0, // 총물량수
        totalPrice: 0 // 공급가액
      },
      payload: null,
    }

    this.state = JSON.parse(JSON.stringify(this.initializedState))

    this.initialize = this.initialize.bind(this)
    this.loadSetup = this.loadSetup.bind(this)

    this.doSelectFile = this.doSelectFile.bind(this)
    this.checkProduct = this.checkProduct.bind(this)
    this.groupByAddress = this.groupByAddress.bind(this)
    this.groupByProduct = this.groupByProduct.bind(this)
    this.doCreatePayUpload = this.doCreatePayUpload.bind(this)
    this.downloadExcelSample = this.downloadExcelSample.bind(this)

    this.abortController = new AbortController()
  }

  componentDidMount() {
    return this.initialize()
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  async initialize() {
    const setup = await this.loadSetup()
    await new Promise((r) => this.setState({ loading: false, setup, item: setup.bookk }, r))
  }

  // 서버 측에서 셋업 데이터를 가져오기 (api 서버의 resources/retails.json)
  async loadSetup() {
    return await api.get(`/payUploads/admin2/retails`, { signal: this.abortController.signal })
      .then((data) => {
        if (!data) { return JSON.parse(JSON.stringify(this.initializedSetup)) }
        return data
      })
      .catch(e => JSON.parse(JSON.stringify(this.initializedSetup)))
  }


  // 매출내역 파일 선택하고 가공하는 기능
  async doSelectFile() {
    const that = this
    const { item: retail, payType, parcelCreatedMethod } = this.state
    if (!retail || !retail.name) { return alert(`외부유통을 반드시 선택해주셔야합니다.`) }
    if (!payType) { return alert(`매출내역 형태를 선택해주세요.`) }
    if (['paperBook'].includes(payType) && ['mixed'].includes(parcelCreatedMethod)) {
      return alert(`발송내역 처리 방식을 선택해주세요.`)
    }

    // 폼셋 불러오기
    const format = ['paperBook', 'electronicBook'].includes(payType) ? `book` : payType // 종이도서, 전자도서의 정책 데이터를 불러오기
    const formset = _.get(retail, `formsets.${format}SalesRetail`) || { start: 0, identity: 'productId', columns: {} }
    const start = formset.start
    const columnValues = formset.columns ? Object.values(formset.columns) : []
    if (!columnValues.length) { return alert(`유통사 관리에서 반드시 매출내역 업로드 양식 규격을 맞추어주세요.`) }
    
    // 폼셋 불러온 규칙성 대로 엑셀파일을 읽어내기
    const parser = { name: `${retail.text} 매출내역 업로드 내역`, columns: {} }
    parser.columns = columnValues
      .reduce((columns, { column, data }) => {
        columns[column] = { name: data, text: data, parse: (cell) => { return cell ? `${cell}`.trim() : `` }  }
        return columns
      }, {})

    const result = await new Importer({ parser }).open()
      .then(o => o.read(o.importedFile, start))
      .catch(e => ({ items: [] }))
    const items = result.items

    console.log(result)
    
    if (!items.length) { return alert(`해당 파일에서 데이터를 추출해내지 못했습니다.`) }

    return await this.transformPayloadByItems(items)
  }

  // 실구분자값을 보유한 데이터만 체크한다.
  async checkProduct(retail, payType, uq = null, identity = 'productId', passRetail = false) {
    const query = { retail, payType, identity, passRetail }
    const output = await api.get(`/payUploads/admin2/checkProduct/${uq}?${qs.stringify(query)}`).catch(e => null)
    return output
  }

  // @ 종이도서 주소지 기반으로 종합
  async groupByAddress(retail = {}, payType = 'paperBook', items = [], passRetail = false) {
    const result = {}
    result.error = false
    result.totalAmount = 0
    result.totalPrice = 0
    
    // 비동기로 데이터마다의 상품 값을 불러온다.
    const format = ['paperBook', 'electronicBook'].includes(payType) ? `book` : payType
    const formset = _.get(retail, `formsets.${format}SalesRetail`) || { start: 0, identity: 'productId', columns: {} }
    const combined = await items.reduce((prev, item) =>
      prev.then(async (combined) => {
        item.productId = null
        item.productPrice = 0
        item.productType = ''
        item.productProduce = ''
        item.productName = ''

        // 해당 매칭키값 기준으로 상품상태값을 가져온다.
        if (item.identity) {
          const result = await this
            .checkProduct(retail.name, payType, item.identity, formset.identity, passRetail)
            .catch(e => {
              const result = {}
              result.error = true
              result.message = e.message || ''
              return result
            })
          if (!result || result.error) {
            item._error = result.error || false
            item._message = result.message || ''
            combined.push(item)
            return combined
          }
          if (result) {
            item._error = result.error || false
            item._message = result.message || ''
            item.productId = result.productId
            item.productType = result.productType
            item.stock = result.stock
            item.productProduce = result.productProduce
            item.productPrice = (result.productPrice * 1) ? result.productPrice * 1 : 0 
            item.productName = result.productName
          } else {
            item._error = true
            item._message = result && result.message ? result.message : `상품정보 없음`

            combined.push(item)
            return combined
          }
        }

        // 혼합되어서 들어왔는지 검사한다.
        if (![item.productType].includes(payType)) {
          item._error = true
          item._message = `다른 유형의 상품(${item.productType}/${payType})`
          combined.push(item)
          return combined
        }

        // 자체제작 상품이 재고내역을 미보유 한경우
        if (['paperBook'].includes(payType) && !_.get(item, 'stock.id') && ['inhouse'].includes(item.productProduce)) {
          item._error = true
          item._message = `재고내역 미보유 상품`
          combined.push(item)
          return combined
        }

        combined.push(item)
        
        return combined
      }),
      Promise.resolve([]))

    // 데이터 이상유무 검사
    const datas = combined.map(item => {
      item._error = item._error || false
      item._message = item._message || ``

      // 배송정보가 완전히 일치한 정보만 묶어야하기에
      const _pattern = [_.get(item, 'receiver'), _.get(item, 'mobile'), _.get(item, 'zip'), _.get(item, 'address1'), _.get(item, 'address2'), _.get(item, 'charge'), _.get(item, 'message')].filter(k => k)
      item._pattern = _pattern.join('') // 슈퍼키 조합을 만들어버리기

      if (_pattern.length === 0) {
        item._error = true
        item._message = `배송지 인자 오류`
      }

      const isExistBlankAddress = [_.get(item, 'receiver'), _.get(item, 'mobile'), _.get(item, 'zip'), _.get(item, 'address1'), _.get(item, 'address2'), _.get(item, 'charge')].map(v => v ? `${v}`.trim() : '').includes('')
      if (isExistBlankAddress) {
        item._error = true
        item._message = item._message ? `${item._message}, 필수 배송정보 누락` : `필수 배송정보 누락`
      }

      if (!item.identity) {
        item._error = true
        item._message = item._message ? `${item._message}, 매칭키 누락` : `매칭키 누락`
      }

      // 발주량 정보를 가져온다.
      const quantity = _.get(item, 'quantity')
        ? _.get(item, 'quantity') * 1
        : false
      const hasQuantity = _.isNumber(quantity) && !_.isNaN(quantity) && _.isInteger(quantity) // 숫자이면서 널값이 아니면서 정수형인지
      if (!hasQuantity) {
        item._error = true
        if (!_.isNumber(quantity)) { item._message = item._message ? `${item._message}, 발주량 기재오류` : `발주량 기재오류` }
        else if (_.isNaN(quantity)) { item._message = item._message ? `${item._message}, 발주량 숫자아님` : `발주량 숫자아님` }
        else if (!_.isInteger(quantity)) { item._message = item._message ? `${item._message}, 발주량 숫자아님` : `발주량 정수가 아님` }
        else { item._message = item._message ? `${item._message}, 발주량 오류` : `발주량 오류` }
      } else {
        result.totalAmount = quantity + result.totalAmount
        if (item.productPrice) { result.totalPrice = result.totalPrice + item.productPrice * quantity }
      }     

      return item
    })

    // 슈퍼키 기준으로 묶음정보로 변환하기
    const rows = datas.reduce((dArr, data) => {
      const idx = dArr.findIndex(d => d._pattern && data._pattern && d._pattern === data._pattern)

      // 이미 만들어져 있는경우
      if (idx >= 0) {
        dArr[idx].datas.push(data)
        dArr[idx].error = data.error || dArr[idx].error
      // 새로운 정보를 만들어야하는 경우
      } else {
        const obj = { _pattern: data._pattern, error: data._error, datas: [data] }
        dArr.push(obj)
      }
      return dArr
    }, [])

    result.success = datas.filter(o => !o._error)
    result.failure = datas.filter(o => o._error)
    if (result.failure.length) { result.error = true }
    result.rows = rows
    
    return result
  }

  // @ 종이도서 상품번호 기준으로 종합
  async groupByProduct(retail = {}, payType = 'paperBook', items = [], passRetail = false) {
    const result = {}
    result.error = false
    result.totalAmount = 0
    result.totalPrice = 0
    
    // 비동기로 데이터마다의 상품 값을 불러온다.
    const format = ['paperBook', 'electronicBook'].includes(payType) ? `book` : payType
    const formset = _.get(retail, `formsets.${format}SalesRetail`) || { start: 0, identity: 'productId', columns: {} }
    const combined = await items.reduce((prev, item) =>
      prev.then(async (combined) => {
        item.productId = null
        item.productPrice = 0
        item.productType = ''
        item.productProduce = ''

        // 해당 매칭키값 기준으로 상품상태값을 가져온다.
        if (item.identity) {
          const result = await this
            .checkProduct(retail.name, payType, item.identity, formset.identity, passRetail)
            .catch(e => {
              const result = {}
              result.error = true
              result.message = e.message || ''
              return result
            })
          if (!result || result.error) {
            item._error = result.error || false
            item._message = result.message || ''
            combined.push(item)
            return combined
          }

          if (result) {
            item._error = result.error || false
            item._message = result.message || ''
            item.productId = result.productId
            item.productType = result.productType
            item.productProduce = result.productProduce
            item.stock = result.stock
            item.productPrice = (result.productPrice * 1) ? result.productPrice * 1 : 0 
            item.productName = result.productName
          } else {
            item._error = true
            item._message = result && result.message ? result.message : `상품정보 없음`
            combined.push(item)
            return combined
          }
        }
        
        // 혼합되어서 들어왔는지 검사한다.
        if (![item.productType].includes(payType)) {
          item._error = true
          item._message = `다른 유형의 상품(${item.productType}/${payType})`
          combined.push(item)
          return combined
        }

        // 자체제작 상품이 재고내역을 미보유 한경우
        if (['paperBook'].includes(payType) && !_.get(item, 'stock.id') && ['inhouse'].includes(item.productProduce)) {
          item._error = true
          item._message = `재고내역 미보유 상품`
          combined.push(item)
          return combined
        }

        combined.push(item)
        return combined
      }),
      Promise.resolve([]))
    
    // 데이터 이상유무 검사
    const datas = combined.map(item => {
      item._error = item._error || false
      item._message = item._message || ``

      // @ 종별 기준으로 묶기 위한 패턴 묶기 + 매칭키 검사
      const _pattern = _.get(item, 'productId') || _.get(item, 'identity') 
      item._pattern = _pattern
      if (!_pattern) {
        item._error = true
        item._message = item._message ? `${item._message}, 매칭키 누락` : `매칭키 누락`
      }

      // 발주량 정보를 가져온다.
      const quantity = _.get(item, 'quantity')
        ? _.get(item, 'quantity') * 1
        : false
      const hasQuantity = _.isNumber(quantity) && !_.isNaN(quantity) && _.isInteger(quantity) // 숫자이면서 널값이 아니면서 정수형인지
      if (!hasQuantity) {
        item._error = true
        if (!_.isNumber(quantity)) { item._message = item._message ? `${item._message}, 발주량 기재오류` : `발주량 기재오류` }
        else if (_.isNaN(quantity)) { item._message = item._message ? `${item._message}, 발주량 숫자아님` : `발주량 숫자아님` }
        else if (!_.isInteger(quantity)) { item._message = item._message ? `${item._message}, 발주량 숫자아님` : `발주량 정수가 아님` }
        else { item._message = item._message ? `${item._message}, 발주량 오류` : `발주량 오류` }
      } else {
        result.totalAmount = quantity + result.totalAmount
        if (item.productPrice) { result.totalPrice = result.totalPrice + item.productPrice * quantity }
      }

      return item
    })

    // 슈퍼키 기준으로 묶음정보로 변환하기
    const rows = datas.reduce((dArr, data, dIdx) => {
      const idx = dArr.findIndex(d => d._pattern && data._pattern && d._pattern === data._pattern)

      // 이미 만들어져 있는경우
      if (idx >= 0) {
        dArr[idx].datas.push(data)
        dArr[idx].error = data.error || dArr[idx].error
      // 새로운 정보를 만들어야하는 경우
      } else {
        const obj = { _pattern: data._pattern, error: data._error, datas: [data] }
        dArr.push(obj)
      }
      return dArr
    }, [])

    result.success = datas.filter(o => !o._error)
    result.failure = datas.filter(o => o._error)
    if (result.failure.length) { result.error = true }
    result.rows = rows
    
    return result
  }

  // @ 전자도서 그대로 종합하고 유효성만 검사하는 방식
  async groupBy(retail = {}, payType = 'electronicBook', items = [], passRetail = false) {
    const result = {}
    result.error = false
    result.totalAmount = 0
    result.totalPrice = 0
    
    // 비동기로 데이터마다의 상품 값을 불러온다.
    const format = ['paperBook', 'electronicBook'].includes(payType) ? `book` : payType
    const formset = _.get(retail, `formsets.${format}SalesRetail`) || { start: 0, identity: 'productId', columns: {} }
    const combined = await items.reduce((prev, item) =>
      prev.then(async (combined) => {
        item.productId = null
        item.productPrice = 0
        item.productType = ''
        item.productProduce = ''

        // 해당 매칭키값 기준으로 상품상태값을 가져온다.
        if (item.identity) {
          const result = await this
            .checkProduct(retail.name, payType, item.identity, formset.identity, passRetail)
            .catch(e => {
              const result = {}
              result.error = true
              result.message = e.message || ''
              return result
            })
          if (!result || result.error) {
            item._error = result.error || false
            item._message = result.message || ''
            combined.push(item)
            return combined
          }

          if (result) {
            item._error = result.error || false
            item._message = result.message || ''
            item.productId = result.productId
            item.productType = result.productType
            item.productProduce = result.productProduce
            item.stock = result.stock
            item.productPrice = (result.productPrice * 1) ? result.productPrice * 1 : 0 
            item.productName = result.productName
          } else {
            item._error = true
            item._message = result && result.message ? result.message : `상품정보 없음`
            combined.push(item)
            return combined
          }
        }
        
        // 혼합되어서 들어왔는지 검사한다.
        if (![item.productType].includes(payType)) {
          item._error = true
          item._message = `다른 유형의 상품(${item.productType}/${payType})`
          combined.push(item)
          return combined
        }

        combined.push(item)
        return combined
      }),
      Promise.resolve([]))
    
    // 데이터 이상유무 검사
    const datas = combined.map(item => {
      item._error = item._error || false
      item._message = item._message || ``

      // 배송정보가 완전히 일치한 정보만 묶어야하기에
      const _pattern = _.get(item, 'productId') || _.get(item, 'identity') 
      item._pattern = _pattern
      if (!_pattern) {
        item._error = true
        item._message = item._message ? `${item._message}, 매칭키 누락` : `매칭키 누락`
      }

      // 발주량 정보를 가져온다.
      const quantity = _.get(item, 'quantity')
        ? _.get(item, 'quantity') * 1
        : false
      const hasQuantity = _.isNumber(quantity) && !_.isNaN(quantity) && _.isInteger(quantity) // 숫자이면서 널값이 아니면서 정수형인지
      if (!hasQuantity) {
        item._error = true
        if (!_.isNumber(quantity)) { item._message = item._message ? `${item._message}, 발주량 기재오류` : `발주량 기재오류` }
        else if (_.isNaN(quantity)) { item._message = item._message ? `${item._message}, 발주량 숫자아님` : `발주량 숫자아님` }
        else if (!_.isInteger(quantity)) { item._message = item._message ? `${item._message}, 발주량 숫자아님` : `발주량 정수가 아님` }
        else { item._message = item._message ? `${item._message}, 발주량 오류` : `발주량 오류` }
      } else {
        result.totalAmount = quantity + result.totalAmount
        if (item.productPrice) { result.totalPrice = result.totalPrice + item.productPrice * quantity }
      }

      return item
    })

    // 슈퍼키 기준으로 묶음정보로 변환하기
    const rows = datas.reduce((dArr, data, dIdx) => {
        const obj = { _pattern: data._pattern, error: data._error, datas: [data] }
        dArr.push(obj)
        return dArr
      }, [])

    result.success = datas.filter(o => !o._error)
    result.failure = datas.filter(o => o._error)
    if (result.failure.length) { result.error = true }
    result.rows = rows
    
    return result
  }

  // 불러온 데이터 기반으로 방식에 따라 처리해주기
  async transformPayloadByItems(items = []) {
    const that = this
    const { item: retail, payType, parcelCreatedMethod, passRetail } = this.state
    if (!retail || !retail.name) { return null }
       
    // 정리된 페이로드 값으로 전달해주기
    let payload = { error: false, rows: [] }
    let panel = { ...this.state.panel }
    let errors = []
    
    // 방식에 따라 그룹화를 먼저하기
    let result = null
    if (payType === 'paperBook' && ['address'].includes(parcelCreatedMethod)) {
      result = await this.groupByAddress(retail, payType, items, passRetail)
    } else if (payType === 'paperBook') {
      result = await this.groupByProduct(retail, payType, items, passRetail)
    } else if (payType === 'electronicBook') {
      result = await this.groupBy(retail, payType, items, passRetail)
    }

    // 에러가 있다면 여기서 먼저 처리해주기
    payload.error = result.error
    payload.rows = result.rows
    panel.totalCount = _.get(items, 'length') || 0 // 첫줄 또는 시작라인부터의 데이터를 의미
    panel.successReadedCount = _.get(result, 'success.length') || 0
    panel.errorReadedCount = _.get(result, 'failure.length') || 0
    panel.enabledPayCount = _.get(result, 'rows.length') || 0
    panel.totalAmount = _.get(result, 'totalAmount') || 0
    panel.totalPrice = _.get(result, 'totalPrice') || 0
    errors = (result.failure && result.failure.length)
      ? result.failure.map(o => ({ type: 'line',  identity: o._rIndex, message: o._message }))
      : []

    return await new Promise(r => that.setState({ payload, panel, errors }, () => r(payload)))
  }

  // 매출내역 파일 업로드 기능
  async doCreatePayUpload() {
    const { onClose } = this.props
    const {
      item: retail, payType,
      parcelCreatedMethod,
      supplyStatusAtPayUploadResolved,
      profitCreateAtPayUploadResolved,
      disabledProfit,
      payload, errors
    } = this.state

    const errorCount = errors.length || 0
    if (errors.length) { return alert(`현재 ${comma(errorCount)}건의 부정확 데이터가 존재합니다. 해당 매출내역 업로드 건은 진행 할 수 없습니다. (부정확한 데이터 사유를 체크하세요)`) }
    if (!window.confirm(`해당 내용으로 매출내역 업로드를 발행할까요? 최종 반영 버튼은 상세내역에서 진행할 수 있습니다. (반영전에는 자유롭게 삭제가 가능합니다.)`)) { return }

    // 넘어온 데이터를 서버측으로 보내기 위해서 payload를 가공한다.
    const rows = (payload.rows || []).map((row) => {
      const obj = {}

      const datas = (row.datas || [])
        .map(data => {
          const o = {}
          o.identity = data.identity
          o.productId = data.productId
          o.quantity = data.quantity * 1
          o.productPrice = data.productPrice * 1
          o.productName = data.productName
          o.productType = data.productType
          o.productProduce = data.productProduce

          // @ 외부수익금 정책을 따르는 경우
          o.externalContractName = data.externalContractName || ''
          o.externalContractProfitAmount = (data.externalContractProfitAmount || 0) * 1

          // @ 종이도서 만약 종별 데이터라면
          if (payType === 'paperBook' && ['product', 'none'].includes(parcelCreatedMethod)) {
            o.receiver = _.get(data, 'receiver') || '주식회사 부크크'
            o.mobile = _.get(data, 'mobile') || '16708316'
            o.zip = _.get(data, 'zip') || '08589'
            o.address1 = _.get(data, 'address1') || '서울 금천구 가산디지털1로 119'
            o.address2 = _.get(data, 'address2') || 'SK트윈테크타워 A동 305-7호'
            o.charge = _.get(data, 'charge') || 'pre'
            o.message = _.get(data, 'message') || '일괄발송(박스형, 발주없음형)'

          // @ 종이도서 주소지를 받았어야했는데 받지 못한 경우
          } else if (payType === 'paperBook') {
            o.receiver = _.get(data, 'receiver') || ''
            o.mobile = _.get(data, 'mobile') || ''
            o.zip = _.get(data, 'zip') || ''
            o.address1 = _.get(data, 'address1') || ''
            o.address2 = _.get(data, 'address2') || ''
            o.charge = _.get(data, 'charge') || 'pre'
            o.message = _.get(data, 'message') || ''
          }
          
          return o
        })
        obj.datas = datas

      // 배송지 취합방식이라면 수취인정보 배정하기
      const data = datas[0] || {}

      obj.delivery = {}
      obj.delivery.receiver = _.get(data, 'receiver') || ''
      obj.delivery.mobile = _.get(data, 'mobile') || ''
      obj.delivery.zip = _.get(data, 'zip') || ''
      obj.delivery.address1 = _.get(data, 'address1') || ''
      obj.delivery.address2 = _.get(data, 'address2') || ''
      obj.delivery.charge = _.get(data, 'charge') || 'pre'
      obj.delivery.message = _.get(data, 'message') || ''

      return obj
    })

    const data = {}
    data.retail = retail
    data.payType = payType

    data.parcelCreatedMethod = parcelCreatedMethod
    data.supplyStatusAtPayUploadResolved = supplyStatusAtPayUploadResolved
    data.profitCreateAtPayUploadResolved = profitCreateAtPayUploadResolved
    data.disabledProfit = disabledProfit

    data.payload = rows
    const result =  await api
      .post(`/payUploads/admin2`, data)
      .catch(e => null)
    if (!result || result.error) { return alert(`매출업로드 업로드에 문제가 발생했습니다.`) }
    
    alert(`매출내역을 성공적으로 업로드하였습니다.`)
    return onClose()
  }

  // 해당 양식으로 다운로드 받는 기능
  async downloadExcelSample() {
    const { item: retail } = this.state
    if (!retail) { return alert(`유통사를 반드시 선택해야 양식을 다운로드 받을 수 있습니다.`) }

    const formset = _.get(retail.formsets, 'bookSalesRetail') || {}
    const columns = formset.columns ? Object.values(formset.columns) : []

    // @ 맵핑 데이터 키값
    const dataTypes = {}

    // @ 기본적으로 갖춰야하는 동일 선상의 기능
    dataTypes.identity = { name: 'identity', text: '매칭키' }
    dataTypes.quantity = { name: 'quantity', text: '발주량' }

    // @ 종이도서의 내역을 불러오는 기능
    // if (['paperBook'].includes(this.state.payType) && this.state.payType) {
    dataTypes.receiver = { name: 'receiver', text: '받는이' }
    dataTypes.mobile = { name: 'mobile', text: '받는이 연락처' }
    dataTypes.zip = { name: 'zip', text: '우편번호' }
    dataTypes.address1 = { name: 'address1', text: '동주소' }
    dataTypes.address2 = { name: 'address2', text: '상세주소' }
    dataTypes.message = { name: 'message', text: '배송요청 메시지' }
    dataTypes.charge = { name: 'charge', text: '선불후불(착불) 여부' }
    dataTypes.trackingNumber = { name: 'trackingNumber', text: '운송장번호' }
    // }

    // @ 전자도서의 유통내역을 불러오는 기능
    dataTypes.externalContractName = { name: 'externalContractName', text: '특약명(유통정책에 등록된 키값 또는 "수기" / 수기는 정책에 똑같이 있어도 무시되며, 수기외는 수익금명시 안해도 자동계산)' }
    dataTypes.externalContractProfitAmount = { name: 'externalContractProfitAmount', text: '특약명 "수기" 입력시 강제 배정할 수익금(정수)' }
  
    const parser = {}
    parser.columns = columns
      .map((cell) => {
        const meta = {}
        meta.text = dataTypes[cell.data] ? dataTypes[cell.data].text : `알수없음`
        meta.id = lettersToNumber(cell.column)
        return  { key: meta.id, header: `${meta.text}(${cell.column})`, view: () => { return `` } }
      })
    parser.mode = 'numeric'
    return await new Exporter({ parser, items: [] })
      .download(`${retail.name} 엑셀 업로드 예시양식`)
      .catch(e => { alert(e.message) })
  }
  
  // 랜더링
  render() {
    const { initializedState } = this
    const { onClose } = this.props
    const { 
      loading, setup, payType,
      
      supplyStatusAtPayUploadResolved,
      parcelCreatedMethod,
      profitCreateAtPayUploadResolved,
      disabledProfit,
      passRetail,
      
      item, errors, panel, payload
    } = this.state
    if (loading) { return null }

    // 모달 프로퍼티 설정 구간, 닫기 버튼에 대한 컴포넌트 라이프 사이클에 대해서만 상위에서 관장한다.
    const modalProps = {}
    modalProps.isOpen = true
    modalProps.onClose = onClose ? onClose : (async () => {})

    // 에러 데이터 목록이 있는지 체크하고, 해당 내역 기준으로 점검
    // errors.push({ type: 'line', identity: 1, message: 'ISBN번호 미기재' })
    // errors.push({ type: 'condition', identity: 15515585785, message: '해당 상품은 이미 판매종료가 된 상품' })
    const errorCount = errors ? errors.length : 0

    // 매출내역 업로드를 할 수 있는 유통사 리스트
    const retails = Object.values(setup || {})
    retails.sort((a, b) => a.sortIdx > b.sortIdx ? 1 : -1)

    // 업로드된 데이터를 파싱하여 payload 데이터로 전환하고나서 보여지는 패널 데이터
    const meta = {}
    meta.uses = item.uses || []

    // 발송처리 구분
    meta.usedProfit = item.usedProfit
    meta.enabledSelectedParcelCreateMethod = ['paperBook'].includes(payType) && ['mixed'].includes(_.get(item, 'parcelCreatedMethod'))
    meta.enabledSelectedSupplyStatus = !['none'].includes(parcelCreatedMethod)
    meta.enabledSelectedProfitCreated = ['used'].includes(meta.usedProfit) && ['sent'].includes(supplyStatusAtPayUploadResolved)

    meta.totalCount = panel.totalCount // 엑셀파일상 담긴 데이터수
    meta.successReadedCount = panel.successReadedCount // 정상 데이터수
    meta.errorReadedCount = panel.errorReadedCount // 불량 데이터수
    meta.enabledPayCount = panel.enabledPayCount // 주소지별이든, 종별이든 결제내역건(주문내역 묶음)이 생성되는 개수
    meta.totalAmount = panel.totalAmount // 총 물량수
    meta.totalPrice = panel.totalPrice // 공급가액
    
    return (
      <Modal {...modalProps}>
        <Form style={{ maxWidth: '430px', minWidth: '430px', width: '430px' }}>
          <Form.Header>매출내역 업로드</Form.Header>
          <Form.Body>

            <div className="control">
              <select
                value={item && item.name || ''}
                style={{ fontWeight: 900 }}
                onChange={e => {
                  if (!e.target.value) { return }
                  const name = e.target.value
                  const next = {}
                  next.item = setup[name] || {}

                  next.supplyStatusAtPayUploadResolved = _.get(next, 'item.supplyStatusAtPayUploadResolved') // 현재 설정팩과 별개로 작동방식을 선택한 경우를 대비
                  next.parcelCreatedMethod = _.get(next, 'item.parcelCreatedMethod') // 현재 설정팩과 별개로 작동방식을 선택한 경우를 대비
                  next.usedProfit = _.get(next, 'item.usedProfit') // 현재 설정팩과 별개로 작동방식을 선택한 경우를 대비
                  next.profitCreateAtPayUploadResolved = _.get(next, 'item.profitCreateAtPayUploadResolved') // 현재 설정팩과 별개로 작동방식을 선택한 경우를 대비
                  next.disabledProfit = ['notUsed'].includes(next.usedProfit)  ? 'used' : 'notUsed'
                  if (['notUsed'].includes(next.usedProfit)) {
                    next.profitCreateAtPayUploadResolved = 'notUsed'
                  }

                  next.payload = null
                  next.payType = _.get(next, 'item.uses').includes('paperBook')
                    ? 'paperBook'
                    : ''
                  next.errors = []
                  next.panel = JSON.parse(JSON.stringify(initializedState.panel))
                  return this.setState(next)
                }}
              >
                <option value={''}>유통사 선택하기</option>

                {retails.map((retail, rIdx) => {
                  const meta = {}

                  // 종별인지 배송지별인지 구분해주기
                  meta.parcelCreatedMethod = null
                  if (['product'].includes(retail.parcelCreatedMethod)) { meta.parcelCreatedMethod = '종별' }
                  if (['address'].includes(retail.parcelCreatedMethod)) { meta.parcelCreatedMethod = '배송지별' }

                  meta.usedProfit = `수익금 사용안함`
                  if (['used'].includes(retail.usedProfit)) { meta.usedProfit = `수익금 사용` }

                  meta.detail = meta.parcelCreatedMethod
                    ? [meta.parcelCreatedMethod, meta.usedProfit]
                    : [meta.usedProfit]

                  return <option key={rIdx} value={retail.name}>{retail.text}({meta.detail.join(', ')})</option>
                })}
              </select>
            </div>
    
            {(item && item.name)
              ? (
                <div style={{ padding: '1rem' }}>
                  <a
                    href="#downloadExcelSample"
                    style={{ textDecoration: 'none', color: '#666' }}
                    onClick={async (e) => {
                      return [e.stopPropagation(), e.preventDefault(), await this.downloadExcelSample()]
                    }}
                  >
                    📥 양식 다운로드
                  </a>
                </div>
              )
              : null}

            <div className="control">
              <select
                value={payType}
                onChange={e => {
                  if  (!e.target.value)  { return }
                  const next = {}
                  next.payType = e.target.value
                  if (['paperBook'].includes(e.target.value)) {
                    next.parcelCreatedMethod = _.get(next, 'item.parcelCreatedMethod')
                    next.supplyStatusAtPayUploadResolved = _.get(next, 'item.supplyStatusAtPayUploadResolved')
                    next.profitCreateAtPayUploadResolved = _.get(next, 'item.profitCreateAtPayUploadResolved')
                  }
                  if (['electronicBook'].includes(e.target.value)) {
                    next.parcelCreatedMethod = 'none'
                    next.supplyStatusAtPayUploadResolved = 'sent'
                    next.profitCreateAtPayUploadResolved = 'used'
                  }
                  next.disabledProfit = ['notUsed'].includes(item.usedProfit)  ? 'used' : 'notUsed'
                  next.payload = null
                  next.errors = []
                  next.panel = JSON.parse(JSON.stringify(initializedState.panel))
                  this.setState(next)
                }}
              >
                <option value={''}>매출내역 구분</option>
                {meta.uses.includes('paperBook') ? <option value={'paperBook'}>종이도서</option> : null}
                {meta.uses.includes('electronicBook') ? <option value={'electronicBook'}>전자도서</option> : null}
              </select>

              {['paperBook'].includes(payType)
                ? (
                  <select
                    style={{ marginLeft: '0.5rem' }}
                    disabled={!meta.enabledSelectedParcelCreateMethod}
                    value={parcelCreatedMethod}
                    onChange={e => {
                      if  (!e.target.value)  { return }
                      const next = {}
                      next.parcelCreatedMethod = e.target.value
                      next.disabledProfit = ['notUsed'].includes(item.usedProfit)  ? 'used' : 'notUsed'
                      if (['none'].includes(next.parcelCreatedMethod)) {
                        next.supplyStatusAtPayUploadResolved = 'sent'
                      }
                      next.payload = null
                      next.errors = []
                      next.panel = JSON.parse(JSON.stringify(initializedState.panel))
                      this.setState(next)
                    }}
                  >
                    <option value={''}>발송방식</option>
                    <option value={'product'}>종별 발송</option>
                    <option value={'address'}>배송지별 발송</option>
                    <option value={'none'}>발송 사용안함</option>
                  </select>
                )
                : null}
            </div>

            {['paperBook'].includes(payType)
                ? (
                  <div className="control">
                    <select
                      disabled={!meta.enabledSelectedSupplyStatus}
                      value={supplyStatusAtPayUploadResolved}
                      onChange={e => {
                        if  (!e.target.value)  { return }
                        const next = {}
                        next.supplyStatusAtPayUploadResolved = e.target.value
                        next.disabledProfit = ['notUsed'].includes(item.usedProfit)  ? 'used' : 'notUsed'
                        if (['sent'].includes(next.supplyStatusAtPayUploadResolved)) {
                          next.profitCreateAtPayUploadResolved = 'used'
                        } else {
                          next.profitCreateAtPayUploadResolved = 'notUsed'
                        }
                        next.payload = null
                        next.errors = []
                        next.panel = JSON.parse(JSON.stringify(initializedState.panel))
                        this.setState(next)
                      }}
                    >
                      <option value={''}>주문내역 상태처리 구분</option>
                      <option value={'ready'}>⬜ 거래중(발송준비중, 발주있음) 처리</option>
                      <option value={'sent'}>🟩 거래완료(발송완료, 발주없음) 처리</option>
                    </select>
                  </div>
                ) : null}

            {!(['used'].includes(meta.usedProfit) && ['sent'].includes(supplyStatusAtPayUploadResolved)) || payType === 'electronicBook'
              ? (
                <div className="control">
                  <select
                    disabled={!['used'].includes(item.usedProfit)}
                    value={disabledProfit}
                    onChange={e => {
                      if  (!e.target.value)  { return }
                      const next = {}
                      next.disabledProfit = e.target.value
                      next.payload = null
                      next.errors = []
                      next.panel = JSON.parse(JSON.stringify(initializedState.panel))
                      this.setState(next)
                    }}
                  >
                    <option value={''}>수익금 사용여부</option>
                    <option value={'used'}>🟥 수익금 사용금지(모든 수익금 반영안함)</option>
                    <option value={'notUsed'}>🟦 수익금 사용됨(기본방식)</option>
                  </select>
                </div>
              )
              : null}

            {(['used'].includes(meta.usedProfit) && ['sent'].includes(supplyStatusAtPayUploadResolved)) && payType === 'paperBook'
              ? (
                <div className="control">
                  <select
                    disabled={!meta.enabledSelectedProfitCreated}
                    value={profitCreateAtPayUploadResolved}
                    onChange={e => {
                      if  (!e.target.value)  { return }
                      const next = {}
                      next.profitCreateAtPayUploadResolved = e.target.value
                      next.disabledProfit = ['notUsed'].includes(item.usedProfit)  ? 'used' : 'notUsed'
                      next.payload = null
                      next.errors = []
                      next.panel = JSON.parse(JSON.stringify(initializedState.panel))
                      this.setState(next)
                    }}
                  >
                    <option value={''}>매출업로드 반영 직후 수익금</option>
                    <option value={'used'}>🟩 반영시 수익금배정</option>
                    <option value={'notUsed'}>⬜ 출고처리시 배정</option>
                  </select>
                </div>
              )
              : null}

            <div className="control">
              <select
                value={passRetail ? 'true' : 'false'}
                onChange={(e) => {
                  const next = { passRetail: e.target.value === 'true' ? true : false }
                  this.setState(next)                  
                }}
              >
                <option value={"true"}>🟥 유통사 부정확데이터 검증기능 비활성</option>
                <option value={"false"}>🟩 유통사 부정확데이터 검증기능 활성</option>
              </select>
            </div>

            {errorCount
              ? (
                <Message>
                  <summary>
                    <strong style={{ marginRight: '0.35rem' }}>총 {comma(errorCount)}건</strong>
                    <span>부정확데이터 사유보기</span>
                  </summary>
                  <div>
                    {errors.filter(e => ['line'].includes(e.type)).map((error, eIdx) => {
                      return (
                        <div key={`e${eIdx}`}>
                          <span style={{ marginRight: '0.35rem' }}>🔴</span>
                          <strong style={{ marginRight: '0.35rem' }}>엑셀 {error.identity}번째줄</strong>
                          <span>{error.message}</span>
                        </div>
                      )
                    })}
                    {errors.filter(e => ['condition'].includes(e.type)).map((error, eIdx) => {
                      return (
                        <div key={`e${eIdx}`}>
                          <span style={{ marginRight: '0.35rem' }}>⚫</span>
                          <strong style={{ marginRight: '0.35rem' }}>{error.identity}</strong>
                          <span>{error.message}</span>
                        </div>
                      )
                    })}
                  </div>
                </Message>
              )
              : null}

            <Meta.Group style={{ marginBottom: '1.5rem' }}>
              <Meta>
                <header>
                  <small>정상 데이터</small>
                </header>
                <section>
                  <strong style={{ marginRight: '0.25rem' }}>{comma(meta.successReadedCount)}</strong>
                  <small>개</small>
                </section>
              </Meta>
              <Meta>
                <header>
                  <small>부정확 데이터</small>
                </header>
                <section>
                  {meta.errorReadedCount ? <strong style={{ color: 'red', marginRight: '0.25rem' }}>{comma(meta.errorReadedCount)}</strong> : null}
                  {!meta.errorReadedCount ? <strong style={{ marginRight: '0.25rem' }}>0</strong> : null}
                  <small>건</small>
                </section>
              </Meta>
              <Meta>
                <header>
                  <small>총 데이터수</small>
                </header>
                <section>
                  <strong style={{ marginRight: '0.25rem' }}>{comma(meta.totalCount)}</strong>
                  <small>건</small>
                </section>
              </Meta>
              {['address'].includes(parcelCreatedMethod)
                ? (
                  <Meta>
                    <header>
                      <small>총 주소지수</small>
                    </header>
                    <section>
                      <strong style={{ marginRight: '0.25rem' }}>{comma(meta.enabledPayCount)}</strong>
                      <small>건</small>
                    </section>
                  </Meta>
                )
                : null}
              {['product', 'none'].includes(parcelCreatedMethod)
                ? (
                  <Meta>
                    <header>
                      <small>총 상품종수</small>
                    </header>
                    <section>
                      <strong style={{ marginRight: '0.25rem' }}>{comma(meta.enabledPayCount)}</strong>
                      <small>건</small>
                    </section>
                  </Meta>
                )
                : null}
              <Meta>
                <header>
                  <small>합계 물량수</small>
                </header>
                <section>
                  <strong style={{ marginRight: '0.25rem' }}>{comma(meta.totalAmount)}</strong>
                  <small>개</small>
                </section>
              </Meta>
              <Meta>
                <header>
                  <small>합계 금액</small>
                </header>
                <section>
                  <strong style={{ marginRight: '0.25rem' }}>{comma(meta.totalPrice)}</strong>
                  <small>원</small>
                </section>
              </Meta>
            </Meta.Group>

            <div className="buttons" style={{ marginTop: '-1rem' }}>
              <button
                type="button"
                onClick={async (e) => {
                  return [e.stopPropagation(), e.preventDefault(), this.doSelectFile()]
                }}
              >
                파일선택
              </button>
              <button
                type="button"
                className="primary"
                onClick={async (e) => {
                  return [e.stopPropagation(), e.preventDefault(), this.doCreatePayUpload()]
                }}
              >
                등록
              </button>
            </div>
          </Form.Body>
        </Form>
      </Modal>
    )
  }
}

export default CreatePayUploadModalContainer