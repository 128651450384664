import React from 'react'

import api from 'services/api'
import _ from 'lodash'

import moment from 'services/moment'
import { Importer, Exporter } from 'services/transform'

import AddElementModal from './AddElementModal/index.js'
import EditElementModal from './EditElementModal/index.js'
import DataSet from 'components/utils/DataSet'

import { Container } from './utils.js'

function ElementList ({ ...props }) {
  const { survey = {}, form = {}, parent = {} } = props
  // console.log({ survey, form, parent })

  const [selecteds, setSelecteds] = React.useState([])

  // @ 선택기능 제어장치
  const sKey = 'identity', sTargets = survey.elements || []
  const isSelectedAll = () => sTargets.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item[sKey] === item[sKey]) ? true : false

  // @ 전체 선택
  const checkAll = () => {
    if (!sTargets.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item[sKey]), cIdxs = sTargets.map(item => item[sKey])
    const next = [...prev.filter(item => !cIdxs.includes(item[sKey])), ...sTargets.filter(item => !pIdxs.includes(item[sKey]))]
    return setSelecteds(next)
  }

  // @ 선택한 한가지 데이터
  const doCheck = (item) => {
    const prev = selecteds, next = []
    if (prev.find(_item => _item[sKey] === item[sKey])) {
      next.push(...prev.filter(_item => _item[sKey] !== item[sKey]))
    } else {
      next.push(...prev, item)
    }
    return setSelecteds(next)
  }

  const elements = _.sortBy(form.elements || [], 'listOrder').reverse()
  const elementCount = elements.length

  const [modal, setModal] = React.useState(null)

  // 컬럼 구성
  const columns = []
  columns.push({
    key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
    style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  })
  columns.push({ key: 'NoColumn', header: '순서', style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ElementColumn', header: '항목명', style: { minWidth: '230px', maxWidth: '230px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'StatusColumn', header: '항목상태', style: { minWidth: '100px', maxWidth: '100px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'BlockedColumn', header: '선택가부', style: { minWidth: '100px', maxWidth: '100px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'DescriptionColumn', header: '항목정보', style: { minWidth: '260px', maxWidth: '260px', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ProgressColumn', header: '진행상황', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'WinColumn', header: '우승', style: { minWidth: '100px', maxWidth: '100px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ControlColumn', header: '관리', style: { minWidth: '120px', maxWidth: '120px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })

  const actions = {}

  // @ 요소를 새롭게 추가하는 모달 열기
  actions.doOpenAddElementModal = async () => {
    try {
      setModal({
        name: 'AddElementModal', survey, form, parent,
        onClose: async () => {
          setModal(null)
          // if (parent.initialize) { return await parent.initialize() }
        }
      })
    } catch(e) {
      console.log(e.message, e.stack)
      console.log('오류가 발생하였습니다.')
    }
  }

  // @ 요소의 정보를 업데이트 하는 기능
  actions.doOpenEditElementModal = async (element = {}, eIdx = 0) => {
    try {
      setModal({
        name: 'EditElementModal', survey, form, parent, element, eIdx,
        onClose: async () => {
          setModal(null)
          // if (parent.initialize) { return await parent.initialize() }
        }
      })
    } catch(e) {
      console.log(e.message, e.stack)
      console.log('오류가 발생하였습니다.')
    }
  }

  // @ 요소를 삭제하는 기능
  actions.doRemoveElement = async (item = {}, eIdx = 0) => {
    if (!window.confirm('해당요소를 삭제할까요? 저장시 해당 항목을 투표한 내역이 있다면 소각되며, 현황이 변화됩니다. 진행할까요?')) { return }
    const { setState } = parent
    const next = [...(form.elements || [])].filter((element, eIdx) => eIdx !== eIdx)
    setState({ form: { ...form, elements: next } })
  }

  // @ 현황/결과 리포트 엑셀 다운로드
  actions.doDownloadResultReport = async () => {
    const curAt = new Date(), curMt = moment(curAt)
    if (!window.confirm(`최종저장 기준으로 다운로드 받게 됩니다. 아직 최종저장을 하지 않았다면 취소를 누르세요. 진행할까요?`)) { return }
    
    // @ 파서 셋팅
    const parser = { name: '진행상황 및 결과 다운로드' }
    
    // @ 파서의 컬럼 셋팅
    const columns = []
    columns.push({
      header: '항목ID', key: 'identity',
      view: (item) => { return _.get(item, 'identity') }
    })
    columns.push({
      header: '항목명', key: 'name',
      view: (item) => { return _.get(item, 'name') }
    })
    columns.push({
      header: '상태', key: 'status',
      view: (item) => {
        if (_.get(item, 'status') === 'enabled') { return '사용' }
        if (_.get(item, 'status') === 'disabled') { return '비사용' }
        return '알수없음'
      }
    })
    columns.push({
      header: '선택가부', key: 'blocked',
      view: (item) => {
        if (_.get(item, 'blocked')) { return '불가' }
        return '가능'
      }
    })
    columns.push({
      header: '우승', key: 'win',
      view: (item) => {
        if (_.get(item, 'win')) { return '우승' }
        return ''
      }
    })
    columns.push({
      header: '선정날짜', key: 'win',
      view: (item) => {
        return _.get(item, 'winAt') ? moment(_.get(item, 'winAt')).format('YYYY-MM-DD') : ''
      }
    })
    columns.push({
      header: '참여수', key: 'voterCount',
      view: (item) => {
        return item.voterCount || 0
      }
    })
    columns.push({
      header: '총참여수', key: 'totalVoterCount',
      view: (item) => {
        return survey.voterLimit || 0
      }
    })
    columns.push({
      header: '비율', key: 'rate',
      view: (item) => {
        return `${((item.rate || 0) * 100).toFixed(0)}%`
      }
    })
  
    if (survey.classify === 'book') {
      columns.push({
        header: '상품ID', key: 'productId',
        view: (item) => { return _.get(item, 'productId') }
      })
      
      columns.push({
        header: '도서구분', key: 'bookType',
        view: (item) => {
          return _.get(item, 'extras._product.productType') === 'paperBook' ? '종이도서' : '전자도서'
        }
      })
      
      columns.push({
        header: '도서용도', key: 'bookPurpose',
        view: (item) => {
          return _.get(item, 'extras._product.content.purpose.text')
        }
      })
      
      columns.push({
        header: '도서번호', key: 'bookNo',
        view: (item) => { return _.get(item, 'extras._product.content.bookNo') }
      })
      
      columns.push({
        header: '도서명', key: 'bookTitle',
        view: (item) => { return _.get(item, 'extras._product.content.title') }
      })
      
      columns.push({
        header: '저자명', key: 'bookAuthor',
        view: (item) => { return _.get(item, 'extras._product.content.author') }
      })
    }
    
    if (survey.classify === 'book' || survey.classify === 'user') {
      columns.push({
        header: '유저ID', key: 'userId',
        view: (item) => { return _.get(item, 'userId') }
      })
      columns.push({
        header: '계정명', key: 'userAccountId',
        view: (item) => { return _.get(item, 'extras._user.accountId') }
      })
      columns.push({
        header: '계정닉네임', key: 'userNickName',
        view: (item) => { return _.get(item, 'extras._user.nickName') }
      })
      columns.push({
        header: '본명', key: 'userName',
        view: (item) => { return _.get(item, 'extras._user.name') }
      })
      columns.push({
        header: '이메일', key: 'email',
        view: (item) => { return _.get(item, 'extras._user.email') }
      })
      columns.push({
        header: '연락처', key: 'mobile',
        view: (item) => { return _.get(item, 'extras._user.mobile') }
      })
    }

    parser.columns = columns
    
    // @ 순열정렬
    const items = _.sortBy((survey.elements || []), 'listOrder').reverse()

    // @ 엑셀 다운로드 진행
    return await new Exporter({ parser, items })
      .download(`${survey.title ? survey.title : '서베이 명칭 없음'} ${parser.name} - ${curMt.format('YYYYMMDDHHmmss')}`)
      .catch(e => { alert(e.message) })
  }

  // @ 우승자 선정기능
  actions.doSetWinner = async (targets = [], status = 'grant') => {
    if (!targets.length) { return alert('대상이 없습니다. 체크 박스를 선택해주세요.') }
    if (!window.confirm('참여자 우승자 선정시 알림 해당 유저 연동이 되어 있다면  알림 메시지가 통보됩니다. 실수 통보시 활동 알림에서 메시지를 삭제해주세요.')) { return }
    const payload = { form: {} }
    payload.form.targets = targets
    payload.form.status = status
    const result = await api.put(`/surveys/admin2/${survey._id}/set-element-winners`, payload)
      .catch((e) => {
        console.log(e.message, e.stack)
        return { error: true, message: '우승자 플래그를 업데이트하던 도중 문제가 발생하였습니다.' }
      })
    alert(result.message)
    if (parent.initialize) { return await parent.initialize() }
  }

  return (
    <Container>
      {modal && (modal.name === 'AddElementModal') ? <AddElementModal {...modal} /> : null}
      {modal && (modal.name === 'EditElementModal') ? <EditElementModal {...modal} /> : null}

      <header>
        <div className="lead">
          투표항목 <strong>총 {elementCount}개</strong>
        </div>
        <div className="btns">
          <a
            href="#현황결과다운로드"
            style={{ marginRight: '1rem' }}
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()
              return await actions.doDownloadResultReport()
            }}
          >
            📥 진행상황/결과 XLSX
          </a>
          <a
            href="#서베이선택항목추가"
            style={{ marginRight: '1rem' }}
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()
              return await actions.doOpenAddElementModal()
            }}
          >
            🖌️ 항목추가
          </a>
          <a
            href="#우승선정"
            style={{ marginRight: '1rem' }}
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()
              return await actions.doSetWinner(selecteds, 'grant')
            }}
          >
            🏆 우승선정
          </a>
          <a
            href="#선정취소"
            style={{ marginRight: '1rem' }}
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()
              return await actions.doSetWinner(selecteds, 'refuse')
            }}
          >
            🪃 선정취소
          </a>
        </div>
      </header>

      <div style={{ overflowX: 'auto' }}>
        <DataSet>
          <DataSet.Header>
            <DataSet.Item>
              {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
            </DataSet.Item>
          </DataSet.Header>
          <DataSet.Body>
            {!elements.length
              ? (
                <DataSet.NotFound>
                  <header>🏖️ 서베이 항목이 존재하지 않습니다.</header>
                  <section>항목이 없는 경우 서베이의 선택지가 없기 때문에 서베이를 표현할 수 없습니다.</section>
                </DataSet.NotFound>
              )
              : null}
            {elements.map((element, eIdx) => (
              <DataSet.Item key={`elements-${eIdx}`}>
                {columns.map((column, columIdx) => {
                  // const columnProps = { column, columIdx, item, loadItems }

                  const meta = {}
                  meta.idx = eIdx + 1
                  meta.name = element.name || '명칭없음'
                  meta.description = element.description || '명칭없음'
                  meta.status = element.status || 'disabled'
                  meta.blocked = element.blocked ? true : false
                  meta.voterLimit = survey.voterLimit || 0

                  // @ classify에 따라서 부여된 확장정보
                  meta.book = element.book || null
                  meta.user = element.user || null
                  meta.isWin = element.win || null
                  meta.winMt = element.winAt ? moment(element.winAt) : null

                  meta.voterCount = element.voterCount || 0
                  meta.rate = ((element.rate || 0) * 100).toFixed(0)

                  return (
                    <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                      {(key => {
                        switch (key) {
                          case 'checkbox':
                            return (
                              <>
                                <input
                                  type="checkbox"
                                  checked={isExistsSelected(element)} readOnly
                                  onClick={e => [e.stopPropagation(), doCheck(element)]}
                                />
                              </>
                            )
                          case 'NoColumn':
                            return (<div>{meta.idx}</div>)

                          case 'ElementColumn':
                            return (
                              <div><strong>{meta.name}</strong></div>
                            )

                          case 'StatusColumn':
                            return (
                              <div>
                                {meta.status === 'enabled' ? <strong>🟢 사용</strong> : null}
                                {meta.status === 'disabled' ? <strong>🔴 비사용</strong> : null}
                              </div>
                            )

                          case 'BlockedColumn':
                            return (
                              <div>
                                {!meta.blocked ? <strong>🟢 가능</strong> : null}
                                {meta.blocked ? <strong>🔴 불가</strong> : null}
                              </div>
                            )

                          case 'DescriptionColumn':
                            return (
                              <div>
                                {(survey.classify === 'book')
                                  ? (
                                    <a
                                      href={`/products/books/${_.get(meta, 'book.productId')}`}
                                      style={{ marginRight: '0.5rem' }}
                                      target="_blank"
                                    >
                                      {_.get(meta, 'book.bookTitle') || '도서명정보 없음'}
                                    </a>
                                  )
                                  : null}
                                {(survey.classify === 'book' || survey.classify === 'user')
                                  ? (
                                    <a
                                      href={`/users/${_.get(meta, 'user.userId')}`}
                                      style={{ marginRight: '0.5rem' }}
                                      target="_blank"
                                    >
                                      {_.get(meta, 'user.nickName') || '닉네임 없음'}
                                    </a>
                                  )
                                  : null}
                                {(survey.classify === 'etc') ? <span>{meta.description || '설명 미기재'}</span> : null}
                              </div>
                            )
                          case 'ProgressColumn':
                            return (
                              <div>
                                <strong>{meta.rate}%</strong> (<strong>{meta.voterCount}</strong>/{meta.voterLimit}표)
                              </div>
                            )
                          case 'WinColumn':
                            return (
                              <div title={meta.winMt ? meta.winMt.format('YYYY-MM-DD') : ''}>
                                {meta.isWin ? '🏆' : '-'}
                              </div>
                            )
                          case 'ControlColumn':
                            return (
                              <div>
                                <button
                                  type="button"
                                  className="button"
                                  style={{ marginRight: '0.5rem' }}
                                  onClick={async (e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    return await actions.doOpenEditElementModal(element, eIdx)
                                  }}
                                >
                                  관리
                                </button>
                              </div>
                            )
                          default:
                        }
                      })(column.key)}
                    </DataSet.Col>
                  )
                })}
              </DataSet.Item>
            ))}
          </DataSet.Body>
        </DataSet>
      </div>

      <div style={{ padding: '1rem 0', textAlign: 'right' }}>
         🎏 반드시 <strong style={{ color: 'red', textDecoration: 'underline' }}>최종저장</strong> 하세요.
      </div>

    </Container>
  )
}

export default ElementList
