import React from 'react'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import api from 'services/api'
import _ from 'lodash'
import moment from 'services/moment'

import Modal from 'components/utils/Modal'

// @ 모달 디자인
const Form = styled.div`
  position: relative; box-sizing: border-box;
  max-width: 460px; min-width: 460px; width: 460px;
`

Form.Header = styled.header`
  position: relative; box-sizing: border-box;
  padding: 2rem 1rem; font-weight: 900;
  font-size: 1.43em; font-family: ${font('primary')};
`

Form.Content = styled.main`
  position: relative; box-sizing: border-box;
  & > div.header {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 1rem; font-weight: 700; color: #111;
  }
  & > div.control {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 1rem;
    & > input {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 0.35rem;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.618rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & > select {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 0.35rem;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.618rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & > textarea {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 0.35rem;
      font-family: ${font('primary')};
      line-height: 1.55rem; border: 1px solid #e1e2e3;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
  }
  & > div.buttons {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0.5rem;
    display: flex; justify-content: space-between;
    & > button {
      position: relative; box-sizing: border-box;
      width: 100%; border: 0; outline: none; cursor: pointer;
      padding: 0.5rem 1rem; font-weight: 700; margin: 0.5rem;
      font-family: ${font('primary')};
      font-size: 1.02em; border-radius: 0.35rem;
      background: white; color: #111; transition: 0.3s all;
      &:hover { background: #f1f1f1; }
      &.primary {
        background: #111; color: white;
        &:hover { background: #222; }
      }
    }
  }
`

// @ 서베이 생성을 위한 모달
class CreateSurveyModalContainer extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      loading: true, pending: false, error: false, message: '',
      form: {
        classify: 'book', title: '', memo: '',
        voterLimit: 100000,
        selectLimit: 1,
        status: 'activated', usedMessage: false,
        startAt: moment().startOf('day').toDate(),
        endAt: moment().add(30, 'days').endOf('day').toDate()
      }
    }

    this.initialize = this.initialize.bind(this)
    this.doSave = this.doSave.bind(this)

    this.abortController = new AbortController()
  }

  componentDidMount() {
    return this.initialize()
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  async initialize() {
    await new Promise((r) => this.setState({ loading: false }, r))
  }

  // @ 서버에 새로운 서베이를 등록하는 기능
  async doSave() {
    const { onClose } = this.props
    const { pending, form } = this.state
    if (pending) { return alert('진행중 입니다. 잠시만 기다려주세요.') }

    // @ 간단한 폼 검사
    if (!form.title) { return alert('서베이 구분을 위해 명칭을 올바르게 명명하여 기재해주세요.') }
    if (form.voterLimit < 1) { return alert('참여자수가 음수는 지원하지 않습니다.') }
    if (form.voterLimit < 30) { return alert('최소 참여자수는 30명 입니다. 너무 적은수는 아닌지 체크해보세요.') }
    if (form.selectLimit < 1) { return alert('최소 1개의 선택지를 입력할 수 있도록 해주세요.') }
    // if (form.selectLimit !== 3 && form.classify === 'book') { return alert('도서 주제의 서베이는 3개의 선택지가 기본 입니다.') }
    if (form.startAt >= form.endAt) { return alert('시작일은 종료일보다 클 수 없습니다.') }

    // @ 최종 질의
    if (!window.confirm(`해당 내용으로 새 서베이를 만들까요?`)) { return }
    
    // @ 진행전 막기
    this.setState({ pending: true })

    const payload = { form }
    const output = await api.post('/surveys/admin2/create-survey', payload)
      .catch(e => {
        console.log(e.message, e.stack)
        return { error: true, message: '서베이를 만들 수 없었습니다.' }
      })
    alert(output.message)
    
    this.setState({ pending: false })
    if (output.error) { return }

    return onClose()
  }

  render() {
    const { doSave } = this
    const { onClose } = this.props

    const { loading, error, message, form } = this.state
    if (loading) { return null }
    if (error) {
      console.log(message)
      return null
    }

    // @ 모달 프로퍼티에 대한 설정
    const modalProps = {}
    modalProps.isOpen = true
    modalProps.onClose = onClose ? onClose : (async () => {})

    return (
      <Modal {...modalProps}>
        <Form>
          <Form.Header>새 서베이 만들기</Form.Header>
          <Form.Content>

            {/* 서베이명 및 기본정보 설정 */}
            <div className="header">기본정보</div>
            <div className="control">
              <select
                value={form.classify || ''}
                onChange={(e) => {
                  const value = e.target.value
                  const next = { ...form, classify: value }
                  return this.setState({ form: next })
                }}
              >
                <option value="book">도서투표</option>
                <option value="user">유저투표</option>
                <option value="etc">기타</option>
              </select>
            </div>
            <div className="control">
              <input
                type="text"
                placeholder="서베이 명칭"
                value={form.title || ''}
                onChange={(e) => {
                  const value = e.target.value
                  const next = { ...form, title: value }
                  return this.setState({ form: next })
                }}
                onBlur={(e) => {
                  const next = { ...form, title: form.title.trim() }
                  return this.setState({ form: next })
                }}
              />
            </div>

            <div className="header">최대참여자수 · 1계정당 투표가능수</div>
            <div className="control">
              <input
                type="number"
                placeholder="최대 참여자수(0 또는 미기재 제한없음)"
                value={form.voterLimit || 0}
                onChange={(e) => {
                  let value = (e.target.value && !_.isNaN(e.target.value * 1))
                    ? e.target.value * 1
                    : 0
                  value = value < 0 ? 0 : value
                  if (!_.isInteger(value)) { value = parseInt(value) }
                  const next = { ...form, voterLimit: value }
                  return this.setState({ form: next })
                }}
              />
            </div>
            <div className="control">
              <input
                type="number"
                placeholder="1투표당 선택수"
                value={form.selectLimit || ''}
                onChange={(e) => {
                  let value = (e.target.value && !_.isNaN(e.target.value * 1))
                    ? e.target.value * 1
                    : 0
                  value = value < 0 ? 0 : value
                  if (!_.isInteger(value)) { value = parseInt(value) }
                  const next = { ...form, selectLimit: value }
                  return this.setState({ form: next })
                }}
              />
            </div>
            <div className="control">
              <select
                value={form.usedMessage ? 'true' : 'false'}
                onChange={(e) => {
                  const value = e.target.value === 'true' ? true : false
                  const next = { ...form, usedMessage: value }
                  return this.setState({ form: next })
                }}
              >
                <option value="true">🟢 주관식 답변 받기</option>
                <option value="false">🔴 주관식 답변 안받기</option>
              </select>
            </div>

            {/* 시작일 설정 */}
            <div className="header">시작일</div>
            <div className="control">
              <input
                type="date"
                pattern="\d{4}-\d{2}-\d{2}"
                value={form.startAt ? moment(form.startAt).format('YYYY-MM-DD') : ''}
                onChange={(e) => {
                  const curAt = new Date()
                  const prevAt = form.startAt
                    ? new Date(form.startAt)
                    : new Date(curAt.getFullYear(), curAt.getMonth(), curAt.getDate(), 12, 0, 0, 0)
                  const prevTime = [prevAt.getHours(), prevAt.getMinutes(), prevAt.getSeconds()]
                  const value = e.target.value || '0000-00-00'
                  const [year, month, day] = value.split('-')
                  const at = new Date(year, month * 1 ? (month * 1 - 1) : null, day, ...prevTime)
                  const next = { ...form, startAt: at.toJSON() }
                  return this.setState({ form: next })
                }}
              />
            </div>
            <div className="control">
              <input
                type="time"
                pattern="\d{2}:\d{2}:\d{2}"
                value={form.startAt ? moment(form.startAt).format('HH:mm:ss') : ''}
                onChange={(e) => {
                  const curAt = new Date()
                  const prevAt = form.startAt
                    ? new Date(form.startAt)
                    : new Date(curAt.getFullYear(), curAt.getMonth(), curAt.getDate(), 12, 0, 0, 0)
                  const prevDay = [prevAt.getFullYear(), prevAt.getMonth(), prevAt.getDate()]
                  const value = e.target.value || '00:00:00'
                  const at = new Date(...prevDay, ...value.split(':'))
                  const next = { ...form, startAt: at.toJSON() }
                  return this.setState({ form: next })
                }}
              />
            </div>
            
            {/* 종료일 설정 */}
            <div className="header">종료일</div>
            <div className="control">
              <input
                type="date"
                pattern="\d{4}-\d{2}-\d{2}"
                value={form.endAt ? moment(form.endAt).format('YYYY-MM-DD') : ''}
                onChange={(e) => {
                  const curAt = new Date()
                  const prevAt = form.endAt
                    ? new Date(form.endAt)
                    : new Date(curAt.getFullYear(), curAt.getMonth(), curAt.getDate(), 12, 0, 0, 0)
                  const prevTime = [prevAt.getHours(), prevAt.getMinutes(), prevAt.getSeconds()]
                  const value = e.target.value || '0000-00-00'
                  const [year, month, day] = value.split('-')
                  const at = new Date(year, month * 1 ? (month * 1 - 1) : null, day, ...prevTime)
                  const next = { ...form, endAt: at.toJSON() }
                  return this.setState({ form: next })
                }}
              />
            </div>
            <div className="control">
              <input
                type="time"
                pattern="\d{2}:\d{2}:\d{2}"
                value={form.endAt ? moment(form.endAt).format('HH:mm:ss') : ''}
                onChange={(e) => {
                  const curAt = new Date()
                  const prevAt = form.endAt
                    ? new Date(form.endAt)
                    : new Date(curAt.getFullYear(), curAt.getMonth(), curAt.getDate(), 12, 0, 0, 0)
                  const prevDay = [prevAt.getFullYear(), prevAt.getMonth(), prevAt.getDate()]
                  const value = e.target.value || '00:00:00'
                  const at = new Date(...prevDay, ...value.split(':'))
                  const next = { ...form, endAt: at.toJSON() }
                  return this.setState({ form: next })
                }}
              />
            </div>
            
            
            {/* 메모 설정 */}
            <div className="header">메모</div>
            <div className="control">
              <textarea
                placeholder="파일교체일이 특정 연휴 또는 단축업무 등에 대한 문구를 적어주세요."
                style={{ height: '90px' }}
                value={form.memo}
                onChange={e => {
                  const next = { ...form, memo: (e.target.value || '') }
                  return this.setState({ form: next })
                }}
                onBlur={(e) => {
                  const next = { ...form, memo: form.memo.trim() }
                  return this.setState({ form: next })
                }}
              />
            </div>
            <div className="buttons">
              <button
                type="button"
                onClick={async (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  return modalProps.onClose()
                }}
              >
                닫기
              </button>
              <button
                type="button"
                className="primary"
                onClick={async (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  return doSave()
                }}
              >
                생성
              </button>
            </div>
          </Form.Content>
        </Form>
      </Modal>
    )
  }
}

export default CreateSurveyModalContainer
