import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%;  max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

class RetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = { datas: [] }

    this.initialize = this.initialize.bind(this)
  }

  async initialize() {
    // const output = await api.get(`/approvals/admin2/${this.props.nav}?${qs.stringify(query)}`, { signal: this.abortController.signal })
    //   .then(({ count, rows }) => ({ rows, count, error: false }))
    //   .catch(e => ({ rows: [], count: 0, error: e.message }))
    this.setState({ datas: [] })
  }
  
  render() {
    const { item } = this.props

    const meta = {}

    // 판매상태, 입점사수, 향후유통, 판매량
    meta.isSales = ['activated'].includes(_.get(item, 'status'))
    meta.retailCount = _.get(item, 'retails.length')
    meta.usedAutoRetail = _.get(item, 'content.usedAutoRetail')
    meta.salesCount = _.get(item, 'analytics.sellCount') || 0

    return (
      <Column>

        <Meta.Group>
          <Meta>
            <header>
              <small>진열상태</small>
            </header>
            <section>
              <strong style={{ marginRight: '0.25rem' }}>{meta.isSales ? `🌏 공개됨` : `🚫 비공개`}</strong>
            </section>
          </Meta>
          <Meta>
            <header>
              <small>입점수</small>
            </header>
            <section>
              <strong style={{ marginRight: '0.25rem' }}>{meta.retailCount ? comma(meta.retailCount) : 0}</strong>
              <small>곳</small>
            </section>
            <section>
              {item.retailBlocked ? <small style={{ color: 'red', fontWeight: 900 }}>🔴 유통차단</small> : null}
              {!item.retailBlocked ? <small>🟢 유통허용</small> : null}
            </section>
          </Meta>
          <Meta>
            <header>
              <small>향후유통</small>
            </header>
            <section>
              <strong style={{ marginRight: '0.25rem' }}>{meta.usedAutoRetail ? `사용` : `비사용`}</strong>
            </section>
          </Meta>
          <Meta>
            <header>
              <small>판매량</small>
            </header>
            <section>
              <strong style={{ marginRight: '0.25rem' }}>{meta.salesCount  ? comma(meta.salesCount) : 0}</strong>
              <small>건</small>
            </section>
          </Meta>
        </Meta.Group>

      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

RetailColumnContainer.propTypes = {
  item: PropTypes.object,
}

RetailColumnContainer.defaultProps = {
  item: {}
}

export default RetailColumnContainer
