import React from 'react'
import PropTypes from 'prop-types'

import moment from 'services/moment'
import qs from 'query-string'
import api from 'services/api'
import _ from 'lodash'
import { comma } from 'services/utils'

import { Importer, Exporter } from 'services/transform'
import legalExportParser from './export.legal'

import Dropdown from './Dropdown'
import Sorts from './Sorts'

import { Header, Task, Items, DataSet, Lnr, Pager, Caret, Copiable, ProgressModal } from './utils'


const List = ({
  user, location, history, match, error,
  title, nav, type,
  keywordsOption, keywords, filters, sorts, items, selecteds,
  total, page, limit, blockLimit,
  startAt, endAt,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems, getItems
}) => {
  const commonProps = { user, location, history, match }

  const isSelectedAll = () => items.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // 전체 선택
  const checkAll = () => {
    if (!items.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item.id), cIdxs = items.map(item => item.id)
    const next = [...prev.filter(item => !cIdxs.includes(item.id)), ...items.filter(item => !pIdxs.includes(item.id))]
    return handle({ selecteds: next })
  }

  // 선택한 한가지 데이터
  const doCheck = (item) => {
    const prev = selecteds, next = []
    if (prev.find(_item => _item.id === item.id)) {
      next.push(...prev.filter(_item => _item.id !== item.id))
    } else {
      next.push(...prev, item)
    }
    return handle({ selecteds: next })
  }

  // 컬럼 구성
  const columns = []
  columns.push({
    key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
    style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  })
  columns.push({ key: 'BookNoColumn', header: '도서번호', style: { minWidth: '110px', maxWidth: '110px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ProductColumn', header: '도서정보', style: { flex: '1 1 auto', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'PriceColumn', header: '소비자가격', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'SellStatusColumn', header: '판매상태', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'LegalDepositStatusColumn', header: '납본상태', style: { minWidth: '150px', maxWidth: '150px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'PayStatusColumn', header: '납본주문서', style: { minWidth: '120px', maxWidth: '120px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'PublishedAtColumn', header: '출판일자', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'IsbnColumn', header: '부크크 ISBN', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'KyoboIsbnColumn', header: '교보문고ISBN', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ControlColumn', header: '관리', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  
  // 설정부 모달에 대한 관리
  const [modal, setModal] = React.useState(false)

  // ProgressModal 컴포넌트를 활용하기 위한 Hooks
  const [progressModal, setProgressModal] = React.useState(false)

  // 상품 타입에 대한 설정
  const productTypes = {}
  productTypes.paperBook = { name: 'paperBook', text: '종이도서' }
  productTypes.electronicBook = { name: 'electronicBook', text: '전자도서' }

  const [form, setForm] = React.useState({})

  // 로우 작동 액션들
  const actions = {}
  
  // 선택한 회원, 현재 페이지 엑셀다운로드 기능 : 2023.01.28 튜닝
  // await actions.downloadExcel(selecteds)
  actions.downloadExcel = async (items = selecteds) => {
    if (!items || items.length === 0) { return alert(`데이터가 없습니다.`) }

    const curAt = new Date(), curMt = moment(curAt)
    return await new Exporter({ parser: legalExportParser, items })
      .download(`납본도서내역 ${items.length}건 (${curMt.format('YYYY년 MM월 DD일 HH시 mm분')})`)
  }

  // 납본주문서 만들기 기능
  actions.doCreateLegalDepositPay = async (item = {}, opts = {}) => {
    if (!opts.returnal && !window.confirm(`해당 내역을 납본 주문할까요?`)) { return }
    const form = {}
    const result = await api.post(`/products/admin2/${item.id}/legalDepositPay`, { from: item, form }).catch(e => null)
    if (!result || result.error) {
      if (!opts.returnal){ return alert((result && result.message) ? `${result.message}` : `오류가 발생하였습니다.`) }
    }
    if (!opts.returnal){ 
      alert(`처리하였습니다.`)
      return initialize()
    }
    return result
  }

  // 선택내역 납본주문 만들기 기능
  actions.doCreateLegalDepositPayBySelecteds = async (items = selecteds) => {
    const checked = await api.get(`/products/admin2/checkLegalDepositSystem`).catch(e => null)
    if (!checked || checked.error) { return alert((checked && checked.message) ? `${checked.message}` : `오류가 발생하였습니다.`) }

    if (!items.length) { return alert(`선택한 상품이 존재하지 않습니다.`) }

    // 해당도서중에 전자도서가 있는지 체크한다.
    const electroincBookItems = items.filter(item => ['electronicBook'].includes(_.get(item, 'productType')))
    if (electroincBookItems.length) { return alert(`선택된 내역중에 전자도서가 있습니다. 전자도서 제외하고 선택해주세요. (${electroincBookItems.length}건)`) }

    // 해당 내역중 납본완료내역이 이미 있다면 오류 뱉어준다.
    const resolvedItems = items.filter(item => ['resolved'].includes(_.get(item, 'content.legalDeposit.status')))
    if (resolvedItems.length) { return alert(`선택된 내역중에 이미 납본완료된건이 존재합니다.납본이 완료된 건은 체크를 풀어주세요.(${resolvedItems.length}건)`) }

    // 해당 내역중 납본완료내역이 이미 있다면 오류 뱉어준다.
    const hasNotIsbnItems = items.filter(item => !_.get(item, 'content.isbn'))
    if (hasNotIsbnItems.length) { return alert(`선택된 내역중에 ISBN이 존재하지 않는 도서가 있습니다.(${hasNotIsbnItems.length}건)`) }

    if (!window.confirm(`해당 상품(총 ${items.length}건)을 납본주문(2부 씩 배정) 처리할까요?`)) { return }

    const result = await items.reduce((prev, item, idx) => prev.then(async (report) => {
      const result = await actions.doCreateLegalDepositPay(item, { returnal: true }).catch(e => null)
      if (result && result.error) {
        report.failure.push({ error: true, message: (result && result.message) ? `${result.message}` : `오류가 발생하였습니다.` })
        return report
      }
      report.success.push({ error: false, message: (result && result.message) ? `${result.message}` : `정상적으로 처리되었습니다.` })
      return report
    }), Promise.resolve({ success: [], failure: [] }))

    if (result.failure.length) { alert(`성공 ${result.success.length}건, 실패 ${result.failure.length}건이 발생하였습니다.`) }
    if (!result.failure.length) { alert(`${result.success.length}건이 성공적으로 처리하였습니다.`) }
    return initialize()
  }

  // ISBN정보 변경기능을 의미한다.
  actions.updateProductByLegal = async (item = {}) => {
    if (!window.confirm(`상태 및 ISBN정보값을 변경하시겠나요?`)) { return }

    const isbn = _.get(item, 'content.isbn')
    const kyoboIsbn = (_.get(item, 'content.retailIsbns') || []).find(o => o.name === 'kyobo') || {}
    const kyoboIsbnNumber = _.get(kyoboIsbn, 'isbn') || ''
    if (!kyoboIsbnNumber && !window.confirm(`교보 ISBN이 비어있습니다 괜찮을까요?`)) { return }
    
    const legalDepositStatus = _.get(item, 'content.legalDeposit.status') || null
    if (!legalDepositStatus) { return alert(`납본상태를 반드시 한가지를 선택해주세요.`) }

    const form = {}
    form.isbn = isbn || ''
    form.kyoboIsbn = kyoboIsbnNumber || ''
    form.legalDepositStatus = legalDepositStatus
    form.publishedAt = _.get(item, 'content.publishedAt') || ''

    const result = await api
      .put(`/products/admin2/${item.id}/updateProductByLegal`, { form })
      .catch(e => null)
    if (result && result.message) { return alert(`${result.message}`) }
    return !result.error
      ? await initialize()
      : result
  }

  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}
      
      <Task><strong>{comma(total)}개</strong>의 ISBN출판용 도서 상품이 검색되었습니다. 검색기간은 납본일자를 기준으로 검색하며, 출판사(코드 bookk)의 ISBN용도 도서기반으로 합니다.</Task>
      <Header>
        <Header.Search>
          <div className="tools">
            {Object.keys(keywordsOptions).map(k => keywordsOptions[k]).map((o, index) =>
              <a
                key={`KeywordOption_${index}`}
                href={`#${o.name}`}
                className={o.name === keywordsOption ? "active" : ""}
                onClick={e => [e.preventDefault(), e.stopPropagation(), handle({ page: 1, keywordsOption: o.name }, () => initialize())]}
              >
                {o.text}
              </a>
            )}
          </div>
          <div className="container">
            <input type="text"
              placeholder={title || '검색어를 입력해주세요.'} value={keywords}
              onChange={e => handle({ 'keywords': e.target.value })}
              onKeyUp={e => e.key === 'Enter' ? handle({ page: 1 }, () => initialize()) : null}
            />
          </div>
          <div className="selector">
            <input
              type="date"
              value={moment(startAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const startAt = new Date(year, month - 1, date), startMt = moment(startAt).startOf('day')
                const prevEndAt = new Date(endAt), prevEndMt = moment(prevEndAt).endOf('day')
                return startMt.diff(prevEndMt) > 0
                  ? handle({ 'startAt': prevEndMt.startOf('day').toDate(), 'endAt': startMt.endOf('day').toDate() }, () => { initialize() })
                  : handle('startAt', startMt.toDate(), () => { initialize() })
              }}
            />
            <input
              type="date"
              value={moment(endAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const prevStartAt = new Date(startAt), prevStartMt = moment(prevStartAt).startOf('day')
                const endAt = new Date(year, month - 1, date), endMt = moment(endAt).endOf('day')
                return prevStartMt.diff(endMt) > 0
                  ? handle({ 'startAt': endMt.startOf('day').toDate(), 'endAt': prevStartMt.endOf('day').toDate() }, () => { initialize() })
                  : handle('endAt', endMt.toDate(), () => { initialize() })
              }}
            />
          </div>
        </Header.Search>
        <Header.Options>
          <a href="#doDownloadExcel" onClick={e => [e.stopPropagation(), e.preventDefault(), actions.downloadExcel()]}>선택엑셀 다운로드</a>
          <a href="#doCreateLegalDepositPayBySelecteds" onClick={e => [e.stopPropagation(), e.preventDefault(), actions.doCreateLegalDepositPayBySelecteds()]}>선택내역 납본주문</a>
        </Header.Options>
      </Header>

      <Items.Tools>
        {Object.keys(filterOptions)
          .filter(key => filterOptions[key] && filterOptions[key].type === 'tool')
          .map(key => {
          const filterOption = filterOptions[key]
          return (
            <Dropdown
              key={filterOption.name}
              header={filterOption.text}
              items={filterOption.conditions}
              current={filters[filterOption.name]}
              onActive={item => {
                if (filterOption.multiple) {
                  let value = filters[filterOption.name] ? filters[filterOption.name].split(',') : []
                  if (value.includes(item.name)) {
                    value = value.filter(v => item.name !== v)
                    if (!value.length) { value = ['all'] }
                  } else {
                    if (item.name === 'all') {
                      value = ['all']
                    } else {
                      value.push(item.name)
                      value = value.filter(v => v !== 'all')
                    }
                  }
                  return handle({ page: 1, filters: { ...filters, [filterOption.name]: value.join(',') } }, () => initialize())
                }
                return handle({ page: 1, filters: { ...filters, [filterOption.name]: item.name } }, () => initialize())
              }}
              usedAll
              {...commonProps}
            />
          )
        })}
        {Object.keys(sortOptions).length ? (
          <Sorts
            header={'정렬'}
            items={Object.keys(sortOptions).map(key => sortOptions[key])}
            current={sorts}
            onActive={(sort) => {
              if (!sorts || !sorts.length) { return handle({ page: 1, sorts: [sort.name] }) }
              const prev = [...sorts]
              const keys = [sort.name, `-${sort.name}`]
              if (!prev.includes(keys[0]) && !prev.includes(keys[1])) {
                prev.push(keys[0])
                return handle({ sorts: prev })
              }
              const next = prev.map((sortKey) => {
                if (!keys.includes(sortKey)) { return sortKey }
                return keys[0] === sortKey ? keys[1] : keys[0]
              })
              handle({ sorts: next }, () => initialize())
            }}
            onCancel={(sort) => {
              if (!sorts || !sorts.length || sorts.length === 1) { return }
              const keys = [sort.name, `-${sort.name}`]
              const next = [...sorts].filter((sortKey) => !keys.includes(sortKey))
              handle({ sorts: next }, () => initialize())
            }}
            {...commonProps}
            style={{ maxWidth: '120px' }}
          />
        ) : null}
      </Items.Tools>

      {items.length ? (
        <Items.Body>

          <DataSet>
            <DataSet.Header>
              <DataSet.Item>
                {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
              </DataSet.Item>
            </DataSet.Header>
            <DataSet.Body>
              {items.map((item, itemIdx) => (
                <DataSet.Item key={item.id}>
                  {columns.map((column, columIdx) => {
                    const columnProps = { column, columIdx, item, loadItems }

                    const extras = item.extras || {}

                    const meta = {}
                    meta.productId = _.get(item, 'id') || null
                    meta.productType = _.get(item, 'productType')
                      ? productTypes[_.get(item, 'productType')]
                      : productTypes.etc

                    meta.userId = _.get(item, 'extras.user.id')
                    meta.userAccountId = _.get(item, 'extras.user.accountId')
                    meta.userNo = _.get(item, 'extras.user.userNo')
                    meta.productName = _.get(item, 'name')
                    meta.bookNo = _.get(item, 'content.bookNo')
                    meta.bookAuthor = _.get(item, 'content.author')
                    meta.bookPurpose = _.get(item, 'content.purpose')
                      ? _.get(item, 'content.purpose')
                      : { text: '알수없음' }
                    meta.productPrice = 0
                    if (['paperBook', 'electronicBook'].includes(_.get(item, 'productType'))) { meta.productPrice = _.get(item, 'content.basicPrice') || 0 }

                    meta.publishedMt = _.get(item, 'content.publishedAt')
                      ? moment(_.get(item, 'content.publishedAt')).format('YYYY-MM-DD')
                      : ''

                    meta.isbn = _.get(item, 'content.isbn') || ''
                    meta.retailIsbns = (_.get(item, 'content.retailIsbns') || [])
                    meta.bookkIsbnIdx = meta.retailIsbns.findIndex(o => o.name  === 'bookk')
                    meta.bookkIsbn = meta.retailIsbns.find(o => o.name  === 'bookk') || { name: 'bookk', isbn: '' }
                    meta.kyoboIsbnIdx = meta.retailIsbns.findIndex(o => o.name  === 'kyobo')
                    meta.kyoboIsbn = meta.retailIsbns.find(o => o.name  === 'kyobo') || { name: 'kyobo', isbn: '' }
                    meta.legalDepositPay = _.get(extras, 'legalDepositPay')
                    meta.legalDepositStatus = _.get(item, 'content.legalDeposit.status') || 'required'

                    return (
                      <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                        {(key => {
                          switch (key) {
                            case 'checkbox':
                              return (
                                <>
                                  <input type="checkbox" checked={isExistsSelected(item)} readOnly onClick={e => [e.stopPropagation(), doCheck(item)]} />
                                </>
                              )
                            case 'BookNoColumn':
                              return (
                                <div style={{ width: '100%', minWIdth: '100%', maxWidth: '100%', textAlign: 'center' }}>
                                  <div><strong>{meta.bookNo || '-'}</strong></div>
                                  <div><small>{_.get(meta, 'productType.text')}</small></div>
                                </div>
                              )
                            case 'ProductColumn':
                              return (
                                <div
                                  onClick={(e) => {
                                    const id = _.get(item, 'id')
                                    const productType = _.get(item, 'productType')
                                    return window.open(`/products/${productType}/${id}`)
                                  }}
                                  style={{ cursor: 'pointer', maxWidth: '350px', whiteSpace: 'normal', wordBreak: 'break-all' }}
                                >
                                  {_.get(item, 'content.purpose.name') === 'collection'
                                    ? <div>[{_.get(meta, 'bookPurpose.text')}] <strong>{meta.productName}</strong></div>
                                    : null}
                                  {_.get(item, 'content.purpose.name') !== 'collection'
                                    ? <div><strong>{meta.productName}</strong></div>
                                    : null}
                                  <div><small>{meta.bookAuthor || '-'}</small></div>
                                </div>
                              )
                            case 'PriceColumn':
                              return (
                                <div>
                                  <strong>{comma(meta.productPrice)}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>원</small>
                                </div>
                              )
                            case 'SellStatusColumn':
                              return (
                                <div>
                                  {_.get(item, 'retails.length') ? <strong>🟢 정상 판매중</strong> : null}
                                  {!_.get(item, 'retails.length') ? <strong>🔴 판매중지</strong> : null}
                                </div>
                              )
                            case 'LegalDepositStatusColumn':
                              return (
                                <div>
                                  <select
                                    value={meta.legalDepositStatus}
                                    onChange={e => {
                                      const next = {}
                                      next[`items[${itemIdx}].content.legalDeposit.status`] = e.target.value
                                      handle(next)
                                    }}
                                  >
                                    <option value="required">납본필요</option>
                                    <option value="resolved">납본완료</option>
                                    <option value="rejected">납본취소</option>
                                  </select>
                                </div>
                              )
                            case 'PayStatusColumn':
                              return (
                                <div
                                  onClick={(e) => {
                                    const productType = _.get(item, 'productType')
                                    const id = _.get(meta, 'legalDepositPay.id')
                                    if (!id) { return alert(`납본주문서 PayID가 존재하지 않습니다.`) }
                                    return window.open(`/pays/${productType}/${id}`)
                                  }}
                                  style={{ cursor: 'pointer', maxWidth: 'fit-content', whiteSpace: 'normal' }}
                                >
                                  {_.get(meta, 'legalDepositPay.id') ? <strong>🟢 있음</strong> : null}
                                  {!_.get(meta, 'legalDepositPay.id')
                                    ? (
                                      <strong>
                                        {['resolved'].includes(meta.legalDepositStatus)
                                          ? `⚪ 비해당`
                                          : `🔴 없음`}
                                      </strong>
                                    )
                                    : null}
                                </div>
                              )
                            case 'PublishedAtColumn':
                              return (
                                <div>
                                  <input
                                    type="date"
                                    pattern="YYYY-MM-DD"
                                    value={meta.publishedMt}
                                    onChange={e => {
                                      // Date로 전환해주기
                                      let timeValue = (e.target.value && `${e.target.value}`.toString().length === '0000-00-00'.length)
                                        ? e.target.value.split('-')
                                        : moment(new Date()).format('YYYY-MM-DD').split('-')
                                      let value = new Date(timeValue[0] * 1, timeValue[1] * 1 - 1, timeValue[2] * 1, 0, 0, 0, 0)
                                      const next = {}
                                      next[`items[${itemIdx}].content.publishedAt`] = value
                                      handle(next)
                                    }}
                                  />
                                </div>
                              )
                            case 'IsbnColumn':
                              return (
                                <div>
                                  <input
                                    type="text"
                                    style={{ textAlign: 'center' }}
                                    value={`${meta.isbn}`}
                                    onChange={e => {
                                      const n = `${e.target.value}`.replace(/[^0-9]/g, '')

                                      const next = {}
                                      next[`items[${itemIdx}].content.isbn`] = n
                                      const pIdx = meta.bookkIsbnIdx
                                      if (pIdx >= 0) {
                                        next[`items[${itemIdx}].content.retailIsbns[${pIdx}]`] = { ...meta.bookkIsbn, isbn: n }
                                      } else {
                                        const replaced = meta.retailIsbns
                                        replaced.push({ ...meta.bookkIsbn, isbn: n })
                                        next[`items[${itemIdx}].content.retailIsbns`] = replaced
                                      }
                                      
                                      return handle(next)
                                    }}
                                  />
                                </div>
                              )
                            case 'KyoboIsbnColumn':
                              return (
                                <div>
                                  <input
                                    type="text"
                                    style={{ textAlign: 'center' }}
                                    value={_.get(meta, 'kyoboIsbn.isbn') ? _.get(meta, 'kyoboIsbn.isbn') : ''}
                                    onChange={e => {
                                      const n = `${e.target.value}`.replace(/[^0-9]/g, '')
                                      const next = {}
                                      const pIdx = meta.kyoboIsbnIdx
                                      if (pIdx >= 0) {
                                        next[`items[${itemIdx}].content.retailIsbns[${pIdx}]`] = { ...meta.kyoboIsbn, isbn: n }
                                      } else {
                                        const replaced = meta.retailIsbns
                                        replaced.push({ ...meta.kyoboIsbn, isbn: n })
                                        next[`items[${itemIdx}].content.retailIsbns`] = replaced
                                      }
                                      return handle(next)
                                    }}
                                  />
                                </div>
                              )
                            case 'ControlColumn':
                              return (
                                <div>
                                  <button
                                    type="button"
                                    className="button"
                                    onClick={async (e) => {
                                      return [e.stopPropagation(), e.preventDefault(), await actions.updateProductByLegal(item)]
                                    }}
                                  >
                                    반영
                                  </button>
                                </div>
                              )

                            default:
                          }
                        })(column.key)}
                      </DataSet.Col>
                    )
                  })}
                </DataSet.Item>
              ))}
            </DataSet.Body>
          </DataSet>

        </Items.Body>
      ) : null}
      
      {!items.length ? (
        <Items.NotFound>
          <header>데이터가 존재하지 않습니다.</header>
          <section>도서종류 또는 상태값을 확인해주세요.</section>
        </Items.NotFound>
      ) : null}

      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize())} />
      </Items.Footer>

    </Items>
  )
}

List.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),

  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  startAt: PropTypes.any,
  endAt: PropTypes.any,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  user: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  items: [],
  selecteds: [],

  page: 1,
  limit: 30,
  blockLimit: 5,

  startAt: null,
  endAt: null,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '납본/ISBN 현황',
  nav: 'legal'
}

export default List
