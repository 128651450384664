const tree = {

  user: {
    name: 'user', icon: 'user', text: '계정', to: '/users', patterns: ['/users'],
  },

  temporaries: {
    name: 'temporaries', icon: 'bookmark', text: '임시', patterns: ['/temporaries'],
    items: [
      { to: '/temporaries/paperBook', text: '종이도서' },
      { to: '/temporaries/electronicBook', text: '전자도서' },
      { to: '/temporaries/solution', text: '작가서비스' }
    ]
  },

  product: {
    name: 'product', icon: 'bookmark', text: '상품', patterns: ['/products'],
    items: [
      { to: '/products/book', text: '도서전체' },
      { to: '/products/paperBook', text: '종이도서' },
      { to: '/products/electronicBook', text: '전자도서' },
      { to: '/products/solution', text: '작가서비스' },
      { to: '/products/legal', text: '납본/ISBN 📘' }
    ]
  },

  billings: {
    name: 'billings', icon: 'card', text: '빌링', patterns: ['/billings'],
    items: [
      { to: '/billings/all', text: '전체' },
      { to: '/billings/activated', text: '정상 사용중' },
      { to: '/billings/deactivated', text: '사용종료' }
    ]
  },

  pays: {
    name: 'pays', icon: 'bag', text: '주문', patterns: ['/pays'],
    items: [
      { to: '/pays/upload', text: '매출업로드 📤' },
      { to: '/pays/book', text: '도서전체 📔' },
      { to: '/pays/paperBook', text: '종이도서' },
      { to: '/pays/electronicBook', text: '전자도서' },
      { to: '/pays/solution', text: '작가서비스' },
      { to: '/pays/etcSolutionWork', text: '추가작업비' },
      { to: '/pays/etcApprovalBookFileChange', text: '파일교체비' },
      { to: '/pays/etcApprovalBookSubmit', text: '가격변경비' },
      { to: '/pays/etcBookStockStorage', text: '재고서비스' },
      { to: '/pays/etcEtc', text: '개인결제' },
      { to: '/pays/quote', text: '견적서 📜' }
    ]
  },

  profit: {
    name: 'profit', icon: 'vault', text: '수익', patterns: ['/profits'],
    items: [
      { to: '/profits/users?filters_status=enabled', text: '지급가능' }, 
      { to: '/profits/withdraws?filters_status=pending', text: '지급대기' },
      { to: '/profits/withdraws?filters_status=resolved', text: '지급완료' },
      { to: '/profits/deposits', text: '수기관리' }
    ]
  },

  invoice: {
    name: 'invoice', icon: 'mouse-both', text: '발주', patterns: ['/invoices'],
    items: [
      { to: '/invoices/stock', text: '재고현황' },
      { to: '/invoices/ready', text: '입금확인' },
      { to: '/invoices/request', text: '신규발주' },
      { to: '/invoices/working', text: '기존발주' },
      { to: '/invoices/finished', text: '수령완료' }
    ]
  },

  // factory: {
  //   name: 'factory', icon: 'factory', text: '생산', to: '/factories', patterns: ['/factories'],
  // },

  parcel: {
    name: 'parcel', icon: 'truck', text: '발송', patterns: ['/parcels'],
    items: [
      { to: '/parcels/ready', text: '발송체크' },
      { to: '/parcels/packing', text: '도서 포장목록' },
      { to: '/parcels/packing?filters_retail=nlibrary', text: '납본 포장목록' },
      {
        to: '/packings', text: '포장작업📱',
        onClick: (e, item) => {
          return window.open(`/packings/bookk`, "", "status=no, toolbar=no, menubar=no, location=no")
        }
      },
      { to: '/parcels/sent', text: '발송완료' },
      { to: '/parcels/cancel', text: '발송취소' }
    ]
  },

  review: {
    name: 'review', icon: 'spell-check', text: '심사', patterns: ['/reviews'],
    items: [
      { to: '/reviews/bookSubmit', text: '도서최초등록' },
      { to: '/reviews/bookRetail', text: '외부유통판매' },
      { to: '/reviews/bookStopRetail', text: '외부유통중지' },
      { to: '/reviews/expertApply', text: '전문가등록' },
      { to: '/reviews/solutionSubmit', text: '작가서비스등록' },
    ]
  },

  manage: {
    name: 'manage', icon: 'hearing', text: '운영', patterns: ['/manages'],
    items: [
      { to: '/manages/bookFileChange?filters_status=all', text: '파일교체' },
      { to: '/manages/bookModify', text: '도서정보' },
      { to: '/manages/refund', text: '수기환불' },
      { to: '/manages/payReview', text: '후기인증' },
      { to: '/manages/brunchEvent', text: '브런치이벤트' },
      { to: '/manages/paperSample', text: '샘플신청' },
      { to: '/manages/report', text: '신고접수' },
    ]
  },

  activity: {
    name: 'activities', icon: 'pencil-ruler', text: '활동', patterns: ['/activities'],
    items: [
      { to: '/activities/carts', text: '장바구니' },
      { to: '/activities/surveys', text: '서베이' },
      { to: '/activities/notifications', text: '알림' },
      { to: '/activities/solutionOrders', text: '작가서비스 대화' },
      { to: '/activities/feeds', text: '게시판 전체글' },
      { to: '/activities/feedComments', text: '전체 댓글' },
    ]
  },

  resource: {
    name: 'resource', icon: 'cog', text: '설정', patterns: ['/resources'],
    direction: 'bottom',
    items: [
      { to: '/resources/freeBookCoverDesigns', text: '무료표지' },
      { to: '/resources/bookStoreGenres', text: '서점 장르' },
      { to: '/resources/bookStoreCorners', text: '서점 기획전' },
      { to: '/resources/solutionStoreCategories', text: '작가샵 카테고리' },
      { to: '/resources/solutionStoreCorners', text: '작가샵 기획전' },
      { to: '/resources/homeCorners', text: '공식 홈' },
      { to: '/resources/popups', text: '팝업창 💡' },
      { to: '/resources/primaryFiles', text: '자료실 파일' },
      {
        to: '/resources', text: '아이콘 목록 📂',
        onClick: (e) => {
          return window.open(`/fonts/Linearicons/references`, "", "status=no, toolbar=no, menubar=no, location=no")
        }
      }
    ]
  },
  
  backup: {
    name: 'backup', icon: 'cog', text: '백업', to: '/backups', patterns: ['/backups']
  },
}

export default tree
