import React from 'react'
import { Provider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import UploadProvider from 'components/utils/UploadProvider'
import theme from 'components/themes'
import 'animate.css'
import store from 'store'

// Page Components
import NotFoundPage from 'components/pages/NotFoundPage'

import SigninPage from 'components/pages/SigninPage'
import GenericPage from 'components/pages/GenericPage'

import ManagePage from 'components/pages/ManagePage'
import ReviewPage from 'components/pages/ReviewPage'
import TemoporaryPage from 'components/pages/TemporaryPage'
import ProductPage from 'components/pages/ProductPage'
import UserPage from 'components/pages/UserPage'

import IndexPage from 'components/pages/IndexPage'
import BillingPage from 'components/pages/BillingPage'
import PayPage from 'components/pages/PayPage'
import ProfitPage from 'components/pages/ProfitPage'
import InvoicePage from 'components/pages/InvoicePage'
import FactoryPage from 'components/pages/FactoryPage'
import ParcelPage from 'components/pages/ParcelPage'
import PackingPage from 'components/pages/PackingPage'

import ActivityPage from 'components/pages/ActivityPage'
import ResourcePage from 'components/pages/ResourcePage'
import BackupPage from 'components/pages/BackupPage'

export const GlobalStyled = createGlobalStyle`
  body {
    margin: 0; font-size: 16px; letter-spacing: -0.035rem; line-height: 20px;
    font-family: 'Noto Nano KR', 'Nanum Gothic', '맑은 고딕', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;
  }

  input, textarea, select {
    font-size: 16px; letter-spacing: -0.035rem; line-height: 20px;
    font-family: 'Noto Nano KR', 'Nanum Gothic', '맑은 고딕', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;
  }

  details summary::-webkit-details-marker { display: none }
`

const fromTemplate = Component => ({
  paramsNav: props => {
    const { params: { nav } } = props.match
    return <Component nav={nav} {...props} />
  },
})

const routes = [
  { path: '/signin', component: props => fromTemplate(SigninPage).paramsNav(props) },
  { path: '/pages/:nav?', component: props => fromTemplate(GenericPage).paramsNav(props) },

  { path: '/reviews/:nav?', component: props => fromTemplate(ReviewPage).paramsNav(props) },
  { path: '/manages/:nav?', component: props => fromTemplate(ManagePage).paramsNav(props) },
  { path: '/products/:nav?', component: props => fromTemplate(ProductPage).paramsNav(props) },
  { path: '/temporaries/:nav?', component: props => fromTemplate(TemoporaryPage).paramsNav(props) },
  { path: '/users/:nav?', component: props => fromTemplate(UserPage).paramsNav(props) },

  { path: '/billings/:nav?', component: props => fromTemplate(BillingPage).paramsNav(props) },
  { path: '/pays/:nav?', component: props => fromTemplate(PayPage).paramsNav(props) },
  { path: '/profits/:nav?', component: props => fromTemplate(ProfitPage).paramsNav(props) },
  { path: '/invoices/:nav?', component: props => fromTemplate(InvoicePage).paramsNav(props) },
  { path: '/factories/:nav?', component: props => fromTemplate(FactoryPage).paramsNav(props) },
  { path: '/parcels/:nav?', component: props => fromTemplate(ParcelPage).paramsNav(props) },
  { path: '/packings/:nav?', component: props => fromTemplate(PackingPage).paramsNav(props) },

  { path: '/activities/:nav?', component: props => fromTemplate(ActivityPage).paramsNav(props) },
  { path: '/resources/:nav?', component: props => fromTemplate(ResourcePage).paramsNav(props) },
  { path: '/backups/:nav?', component: props => fromTemplate(BackupPage).paramsNav(props) },

]

// esbuild 먹었는지
const helmetContext = {}

const clearAppCaches = (c) => {
  if ('caches' in window) {
    caches.keys().then(function(cacheNames) {
      cacheNames.forEach(function(cacheName) {
        caches.delete(cacheName)
      })
    })
  }

  // 사이트 데이터 삭제를 위해 Service Worker 등록 해제
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      registrations.forEach(function(registration) {
        registration.unregister()
      })
    })
  }

  window.localStorage.clear()
  window.sessionStorage.clear()
  window.document.cookie = ""
}

const updateApp = (c) => {
  clearAppCaches(c)
  window.localStorage.setItem('bookkAdminBuildAt', c)
}

const equalBookkAdminBuildAt = (c) => {
  const bookkAdminBuildAt = window.localStorage.getItem('bookkAdminBuildAt')
  if (!bookkAdminBuildAt) { return false }

  console.log('[기존버전, 새로운버전 빌드번호]')
  console.table([{ bookkAdminPrevVersion: bookkAdminBuildAt, bookkAdminCurrentVersion: `${c}` }])
  
  return `${bookkAdminBuildAt}` === `${c}`
}

function App() {
  // esbuild 처리화 되면, 해당 내용을 전달하는 과정 만들기
  const { REACT_APP_BUILD_AT } = process.env
  const bookkAdminBuildAt = REACT_APP_BUILD_AT
  if (bookkAdminBuildAt && !equalBookkAdminBuildAt(bookkAdminBuildAt)) {
    updateApp(bookkAdminBuildAt)
    setTimeout(() => window.location.reload(), 300)
    return null
  }

  return (
    <Provider store={store}>
      <HelmetProvider context={helmetContext}>
        <ThemeProvider theme={theme}>
          <UploadProvider>
            <Router>
              <Switch>
                {routes.map((route, key) => <Route key={key} {...route} />)}
                <Route path={`/`} exact component={props => <IndexPage {...props} />} />
                <Route component={props => <NotFoundPage {...props} />} />
              </Switch>
            </Router>
          </UploadProvider>
          <GlobalStyled />
        </ThemeProvider>
      </HelmetProvider>
    </Provider>
  )
}

export default App
