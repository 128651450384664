import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  font-family: ${font('primary')};

  & > div.bookType { position: relative; box-sizing: border-box; }
  & > div.bookTitle { position: relative; box-sizing: border-box; padding-bottom: 0.35rem; }
  & > div.bookAuthor { position: relative; box-sizing: border-box; padding-bottom: 0.35rem; }
  & > div.bookPrice { position: relative; box-sizing: border-box; font-size: 0.85em; }

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

// 도서정보를 표현하는 컴포넌트 컬럼, Approval Structure 기반으로 짜여짐
// 과거에 제출했던 정보를 기반으로 하는 문서임
// 표현될 기획 내용 : 저자명. 종이책전자책 구분, 소비자가격, 출판목적
class BookColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = { datas: [] }

    this.initialize = this.initialize.bind(this)
  }

  async initialize() {
    // const output = await api.get(`/approvals/admin2/${this.props.nav}?${qs.stringify(query)}`, { signal: this.abortController.signal })
    //   .then(({ count, rows }) => ({ rows, count, error: false }))
    //   .catch(e => ({ rows: [], count: 0, error: e.message }))
    this.setState({ datas: [] })
  }
  
  render() {
    const { item } = this.props

    const meta = {
      bookTitle: _.get(item, 'content.title') || '도서명 미기재',
      bookAuthor: _.get(item, 'content.author') || '저자명 미기재',
      bookTypeAlias: _.get(item, 'content.type') === 'paperBook'
        ? '종이도서'
        : (_.get(item, 'content.type') === 'electronicBook' ? '전자도서' : '기타'),
      bookPurpose: _.get(item, 'content.purpose.text') || '출판목적 불분명',
      adult: _.get(item, 'content.ageLimit') === 'all' ? '' : '🔞',
      isbn: _.get(item, 'isbn'),
      retailIsbns: _.get(item, 'retailIsbns') || [],
      memo: _.get(item, 'memo') || ''
    }
    
    // 링크 이동을 위한 값 배정
    meta.productId = _.get(item, 'id') || null
    meta.userId = _.get(item, 'user') || null

    // 해당 도서 가격에 대한 추적(출판목적, 도서종류)
    meta.bookPrice = 0
    if (['external', 'internal'].includes(_.get(item, 'content.purpose.name'))) {
      meta.bookPrice = _.get(item, 'content.type') === 'paperBook'
        ? _.get(item, 'content.basicPrice') * 1
        : _.get(item, 'content.defaultPrice') * 1
    }
    if (_.get(item, 'content.purpose.name') === 'collection') {
      meta.bookPrice = _.get(item, 'content.collectionPrice') * 1
    }
    meta.bookPriceWithComma = meta.bookPrice ? comma(meta.bookPrice) : 0

    return (
      <Column>
        <div className="bookType">
          <small style={{ marginRight: '0.15rem' }}>{meta.bookTypeAlias}</small>
          <small><strong>{meta.isbn ? meta.isbn : meta.bookPurpose}</strong></small>
        </div>
        <div className="bookTitle">
          <a
            href={`/products/book/${meta.productId}`}
            target="_blank"
            onClick={e => {
              if (!meta.productId) {
                return [e.stopPropagation(), e.preventDefault(), alert(`아직 상품이 존재하지 않거나, 상품이 만들어지지 않은 문서입니다.`)]
              }
              return
            }}
          >
            <strong>{meta.adult} {meta.bookTitle}</strong>
          </a>
        </div>
        <div className="bookAuthor">
          <a
            href={`/users/${meta.userId}`}
            target="_blank"
            onClick={e => {
              if (!meta.userId) {
                return [e.stopPropagation(), e.preventDefault(), alert(`유저정보개 배정되지 못한 문서입니다. 시스템 관리자에게 문의해주세요.`)]
              }
              return
            }}
          >
            <small>{meta.bookAuthor}</small>
          </a>
        </div>

        <div className="bookPrice">
          {meta.bookPrice > 0
            ? (
              <>
                <strong style={{ marginRight: '0.15rem' }}>{meta.bookPriceWithComma}</strong>
                <small>원</small>
              </>
            )
            : (
              <>
                <strong>무료</strong>
              </>
            )}
        </div>

        {meta.memo
          ? (
            <div style={{ fontSize: '0.9em', padding: '0.35rem 0.75rem', borderRadius: '0.35rem', margin: '0.5rem 0', background: '#f7f7f7' }}>
              {meta.memo}
            </div>
          )
          : null}
        
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

BookColumnContainer.propTypes = {
  item: PropTypes.object,
}

BookColumnContainer.defaultProps = {
  item: {}
}

export default BookColumnContainer
