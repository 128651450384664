
import styled, { css } from 'styled-components'
import { font, palette } from 'styled-theme'


const controlStyles = css`
  & div.header {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0.5rem;
  & small { color: ${palette('muted', 8)}; }
  }
  & div.label {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0.5rem;
  }
  & div.control {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0.5rem;
    display: flex;
    & > * { flex: 1 1 100%; }
    & input[type=text],
    & input[type=number],
    & input[type=time],
    & input[type=date] {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & select {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & textarea {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      font-family: ${font('primary')};
      line-height: 1.55rem; border: 1px solid #e1e2e3;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & button {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      font-family: ${font('primary')};
      line-height: 1.55rem; border: 1px solid #e1e2e3;
      width: 100%; outline: none;
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      background: #fafafa; border: 1px solid #929394; cursor: pointer;
      &:hover { background: #111; border: 1px solid #111; color: white; }
    }
  }
`

const buttonStyles = css`
  & div.buttons {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0; margin: 0 -0.5rem;
    display: flex; justify-content: space-between;
    & > a,
    & > button {
      position: relative; box-sizing: border-box;
      width: 100%; border: 0; outline: none; cursor: pointer;
      padding: 0.75rem 1rem; font-weight: 700; margin: 0.5rem;
      text-align: center; text-decoration: none;
      font-family: ${font('primary')};
      font-size: 1.02em; border-radius: 3px;
      background: white; color: #232323; transition: 0.3s all;
      &:hover { background: #e1e2e3; color: #121212; }
      &.primary {
        background: ${palette('darkblue', 5)}; color: white;
        &:hover { opacity: 0.8; }
      }
    }
  }
`

// 폼 사이즈 구성
export const Form = styled.div`
  position: relative; box-sizing: border-box;
  transition: all 0.3s;
  display: flex;
 ${controlStyles}
 ${buttonStyles}
`

Form.Header = styled.header`
  position: relative; box-sizing: border-box;
  padding: 2rem 1rem; 
  & > div.lead {
    position: relative; box-sizing: border-box;
    font-weight: 900; line-height: 1.618em;
    min-width: 260px; max-width: 260px;
    font-size: 1.43em; font-family: ${font('primary')};
  }
`

Form.Aside = styled.aside`
  position: relative; box-sizing: border-box;
  padding: 2rem 1rem; font-family: ${font('primary')};
  min-width: 260px; max-width: 260px;
  & > div.thumbnail {
    position: relative; box-sizing: border-box;
    display: flex; align-items: center; justify-content: center; padding: 1rem 0;
    & > img { position: relative; box-sizing: border-box; display: block; width: 100%; }
  }
`

Form.Body = styled.main`
  position: relative; box-sizing: border-box;
  padding: 1rem 1rem;
  flex: 1 1 100%;
`
