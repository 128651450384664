import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import moment from 'services/moment'
import api from 'services/api'
import styled from 'styled-components'
import { font, palette } from 'styled-theme'
import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a strong { color: ${palette('primary', 157)} !important; transition: all 0.3s; }
  & a:hover { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

class PassColumnContainer extends React.Component {
  constructor(props) {
    super(props)

    this.abortController = new AbortController()

    this.state = {}
  }

  componentDidMount() { }
  
  render() {
    const { item = {}, loadItems = async () => {} } = this.props
    const extras = _.get(item, 'extras') || {}

    const meta = {}
    meta.textPass = _.get(item, 'text.textPass') ? true : false

    const actions = {}
    actions.doUpdateTextPass = async (toggle = false) => {
      const payload = {}
      payload.form = { item, toggle }
      const result = await api.put('/temporaries/admin2/updateTextPass', payload)
        .catch((e) => {
          console.log(e.message, e.stack)
          return { error: true, message: '서버에 요청을 보냈으나 오류가 발생하였습니다.' }
        })
      alert(result.message)
      if (result.error) { return }
      if (loadItems) { return loadItems() }
      return window.location.reload()
    }

    return (
      <Column>

        <Meta.Group>
          <Meta style={{ minWidth: '100%', maxWidth: '100%' }}>
            <header>
              <small>내지파일 업로드</small>
            </header>
            <section
              onClick={async (e) => {
                e.stopPropagation()
                e.preventDefault()
                return actions.doUpdateTextPass(!meta.textPass)
              }}
              style={{ cursor: 'pointer', border: '1px solid #ccc', background: '#f1f1f1', borderRadius: '0.3618rem', padding: '0.4618rem' }}
            >
              {meta.textPass
                ? <strong>🟢 검사면제</strong>
                : <strong>⚪ 필수</strong>}
            </section>
          </Meta>
        </Meta.Group>

      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

PassColumnContainer.propTypes = {
  item: PropTypes.object,
}

PassColumnContainer.defaultProps = {
  item: {}
}

export default PassColumnContainer
