import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { fromAdmin } from 'store/selectors'

import moment from 'services/moment'
import api from 'services/api'
import qs from 'query-string'
import _ from 'lodash'

import Lounge from 'components/utils/Lounge'
import List from './List'

const constructors = {}

// @ 서베이 관리 기능
constructors.surveys = (that = {}) => {
  // 와치 처리
  that.watchs = that.watchs.map((watch) => {
    if (watch.prop === 'filters') { watch.defaultProp = { not: 'all' } }
    if (watch.prop === 'sorts') { watch.defaultProp = ['-createdAt'] }
    return watch
  })

  // @ 종류
  that.filterOptions.classify = { name: 'classify', text: '서베이 종류', path: 'classify', state: 'filters.classify', item: 'classify', conditions: [], type: 'tool' }
  that.filterOptions.classify.conditions.push({ name: 'book', text: '도서선택' })
  that.filterOptions.classify.conditions.push({ name: 'etc', text: '기타' })

  // @ 진행 단계
  that.filterOptions.status = { name: 'status', text: '진행단계', path: 'status', state: 'filters.status', item: 'status', conditions: [], type: 'tool' }
  that.filterOptions.status.conditions.push({ name: 'activated', text: '진행중' })
  that.filterOptions.status.conditions.push({ name: 'deactivated', text: '진행완료' })

  // @ 기간
  that.filterOptions.timed = { name: 'timed', text: '기간구분', path: 'timed', state: 'filters.timed', item: 'timed', conditions: [], type: 'tool' }
  that.filterOptions.timed.conditions.push({ name: 'reservated', text: '예약됨' })
  that.filterOptions.timed.conditions.push({ name: 'lived', text: '진행중' })
  that.filterOptions.timed.conditions.push({ name: 'dead', text: '마감됨' })
  
  // @ 기간
  that.filterOptions.limited = { name: 'limited', text: '참여현황', path: 'limited', state: 'filters.limited', item: 'limited', conditions: [], type: 'tool' }
  that.filterOptions.limited.conditions.push({ name: 'enabled', text: '여유/참여가능' })
  that.filterOptions.limited.conditions.push({ name: 'disabled', text: '모두/참여완료' })

  // @ 소트 셋팅
  that.sortOptions.createdAt = { name: 'createdAt', text: '생성시간', multiple: true, asc: { text: '오름차순' }, desc: { text: '내림차순' } }

  // @ 커워드 셋팅
  that.keywordsOptions.all = { name: 'all', text: '전체' }
  that.keywordsOptions._id = { name: 'surveyId', text: '서베이ID' }
  that.keywordsOptions.title = { name: 'surveyTitle', text: '서베이명' }
  that.keywordsOptions.accountId = { name: 'accountId', text: '계정명' }
}

class ActivitySurveyListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.filterOptions = {}
    this.sortOptions = {}
    this.keywordsOptions = {}
    this.watchs = [
      { prop: 'keywordsOption', state: 'keywordsOption' },
      { prop: 'keywords', state: 'keywords' },
      { prop: 'page', state: 'page' },
      { prop: 'limit', state: 'limit' },
      { prop: 'blockLimit', state: 'blockLimit' },
      { prop: 'startAt', state: 'startAt' },
      { prop: 'endAt', state: 'endAt' },
      { prop: 'filters', state: 'filters' },
      { prop: 'sorts', state: 'sorts' },
    ]

    this.navs = ['surveys']
    if (this.navs.includes(props.nav) && constructors[props.nav]) { constructors[props.nav](this) }

    // 핸들러 값 처리
    this.initialState = this.initialState.bind(this)
    this.initialize = this.initialize.bind(this)
    this.loadItems = this.loadItems.bind(this)
    this.handle = this.handle.bind(this)

    this.state = this.initialState(props)
  }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  componentDidUpdate(prevProps) {
    const condition = this.watchs.some(({ prop }) => {
      const prev = _.get(prevProps, prop), next = _.get(this.props, prop)
      return typeof prev === 'object' || typeof next === 'object' ? JSON.stringify(prev) !== JSON.stringify(next) : prev !== next
    })
    if (condition) {
      return this.setState(this.initialState(this.props), () => this.initialize())
    }
  }

  initialState(props) {
    const next = { loading: true, error: false, items: [], total: 0, datas: [], selecteds: [] }
    this.watchs.forEach(watch => {
      if (watch.state) { _.set(next, watch.state, props[watch.prop] || watch.defaultProp) }
    })
    return next
  }

  async initialize() {
    await new Promise((r) => this.setState({ loading: true, selecteds: [] }, r))
    if (!this.navs.includes(this.props.nav) || !constructors[this.props.nav]) { return this.setState({ loading: false, error: true }) }
    await this.loadItems()
  }

  // 현재 페이지 데이터를 불러오는 기능
  async loadItems(settings = {}, options = { returnal: false }) {
    const { filters, sorts, keywordsOption, keywords, page, limit, startAt, endAt } = this.state

    const query = {}
    query.usedCount = true
    query.startAt = startAt
    query.endAt = endAt
    query.page = page
    query.limit = limit
    query.sort = sorts.join(',')

    // @ extras 확장인자 배치
    query.flags = ['currentProduct'].join(',')

    //@ 현재 섹션 값을 배치
    const section = this.props.nav
    if (section === 'surveys') {
      query.classify = filters.classify
      query.status = filters.status
      query.timed = filters.timed
      query.limited = filters.limited
    }
    
    if (keywordsOption) { query.keywordsOption = keywordsOption }
    if (keywords) { query.keywords = keywords }

    Object.keys(settings).forEach((key) => { query[key] = settings[key] })

    // @ 섹션 값을 전달하면서 데이터를 불러오기
    const output = await api.get(`/surveys/admin2/${section}?${qs.stringify(query)}`, { signal: this.abortController.signal })
      .then(({ count, rows }) => ({ rows, count, error: false }))
      .catch(e => ({ rows: [], count: 0, error: e.message }))

    const { count, rows, error } = output
    if (options.returnal) { return output }
    return this.setState({ items: rows.map((row, _index) => ({ ...row, _index })), total: count, loading: false, error })
  }

  handle(...values) {
    const next = { ...this.state }
    if (typeof values[0] === 'string') {
      _.set(next, values[0], values[1])
      return this.setState(next, () => values[2] ? values[2](null, next) : null)
    }
    if (typeof values[0] === 'object' && !values[0].length) { Object.keys(values[0]).forEach(key => _.set(next, key, values[0][key])) }
    if (typeof values[0] === 'object' && values[0].length) { values[0].forEach(e => Object.keys(e).forEach(key => _.set(next, key, e[key]))) }
    return this.setState(next, () => values[1] ? values[1](null, next) : null)
  }

  render() {
    const { loading, error } = this.state
    if (loading) { return <Lounge /> }

    const { initialize, handle, loadItems } = this
    const { admin, location, history, match, title, nav } = this.props
    const {
      keywordsOption, keywords, filters, sorts, total, items, page, limit, blockLimit, selecteds,
      startAt, endAt
    } = this.state
    const listProps = {
      admin, location, history, match, title, nav, error,
      keywordsOption, keywords, filters, sorts, items, selecteds,
      total, page, limit, blockLimit,
      startAt, endAt,
      initialize, handle, loadItems,
      filterOptions: this.filterOptions,
      sortOptions: this.sortOptions,
      keywordsOptions: this.keywordsOptions
    }

    return <List {...listProps} />
  }
}

ActivitySurveyListContainer.propTypes = {
  admin: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,
  
  title: PropTypes.string,
  nav: PropTypes.string,

  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),

  startAt: PropTypes.instanceOf(Date),
  endAt: PropTypes.instanceOf(Date),
}

ActivitySurveyListContainer.defaultProps = {
  admin: {},
  location: {},
  history: {},
  match: {},
  keywordsOption: 'all',
  keywords: '',
  page: 1,
  limit: 10,
  blockLimit: 5,
  
  title: '서베이 관리',
  nav: 'surveys',

  filters: null,
  sorts: null,

  startAt: moment().startOf('month').add(-10, 'years').toDate(),
  endAt: moment().endOf('day').toDate(),
}

const mapStateToProps = (state) => ({
  admin: fromAdmin.getInfo(state)
})

export default withRouter(connect(mapStateToProps, null)(ActivitySurveyListContainer))
